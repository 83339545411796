import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Components
import { LoadingCards } from 'components';
import { ProfileOnBoarding } from './OnBoarding';
import { Transcript } from './Transcript';
import { RecentCme } from './RecentCme';

// Store
import {
  userProfileFullNameSelector,
  userProfileHasCompletedWizardSelector,
  userProfileSelector,
} from 'store/profile/selectors';

// Core
import { IUserProfile } from 'core';

// Styles
import styles from './Profile.module.scss';

export const Profile = (): ReactElement => {
  // Selectors
  const userProfile: IUserProfile = useSelector(userProfileSelector);
  const fullName: string = useSelector(userProfileFullNameSelector);
  const hasCompletedWizard: boolean = useSelector(userProfileHasCompletedWizardSelector);

  // Show this while loading.
  if (!userProfile) return <LoadingCards />;

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Welcome {fullName}!
        <div className={styles.caption}>
          To receive the most accurate data about your CME, please complete or confirm the following.
        </div>
      </h1>
      {!hasCompletedWizard && <ProfileOnBoarding />}
      <Transcript />
      <RecentCme />
    </div>
  );
};
