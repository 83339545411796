import { ThunkAction } from 'redux-thunk';
import { Action, AnyAction } from 'redux';

// Store + Core
import { IStaticToastOptions, PARSAction } from 'core';
import { AppState } from 'store';

// Types
import { TOAST_CLOSE, TOAST_OPEN } from './types';

// action creators
export const toastOpenAction = (toastOptions: IStaticToastOptions): AnyAction => {
  return { payload: toastOptions, type: TOAST_OPEN };
};
export const toastCloseAction = (): Action => {
  return { type: TOAST_CLOSE };
};

// thunk actions
export const popToast = (toastOptions: IStaticToastOptions): ThunkAction<void, AppState, null, PARSAction> => (
  dispatch,
) => {
  dispatch(toastOpenAction(toastOptions));
};

export const eatToast = (): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  dispatch(toastCloseAction());
};
