import classnames from 'classnames';
import { ComponentType, ReactElement } from 'react';
import { Link as BaseLink, LinkProps } from 'react-router-dom';

// Core.
import { LinkVariant } from 'core';

// Styles.
import styles from './Link.module.scss';

export interface ILink extends LinkProps {
  disabled?: boolean;
  icon?: ComponentType<{ className: string }>;
  isIconPrepended?: boolean;
  variant?: LinkVariant | LinkVariant[];
}

export const Link = (props: ILink): ReactElement => {
  const { children, className, disabled, icon: Icon, isIconPrepended = false, variant, ...linkProps } = props;
  const variantArr = (Array.isArray(variant) ? variant : [variant]).filter(Boolean);
  const linkClassName = classnames(
    styles.element,
    {
      [styles.primary]: variantArr.includes(LinkVariant.Primary),
      [styles.secondary]: variantArr.includes(LinkVariant.Secondary),
      [styles.tertiary]: variantArr.includes(LinkVariant.Tertiary),
      [styles.disabled]: disabled,
    },
    className,
  );

  return (
    <BaseLink {...linkProps} className={linkClassName}>
      {Icon && isIconPrepended && <Icon className={styles['custom-icon-back']} />}
      {children}
      {Icon && !isIconPrepended && <Icon className={styles['custom-icon']} />}
    </BaseLink>
  );
};
