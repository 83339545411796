import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import get from 'lodash/get';
import { ReactElement, useCallback } from 'react';

// Styles.
import styles from './FormikRadioButton.module.scss';

// Types.
import { FormikRadioButtonProps } from '../types';

export const FormikRadioButton = ({ className, formikKey, label, value }: FormikRadioButtonProps): ReactElement => {
  const { setFieldValue, values } = useFormikContext();
  const [field] = useField(formikKey);
  const formikValue = get(values, formikKey);
  const handleChange = useCallback(
    (e) => {
      if (['true', 'false'].includes(e.target.value)) {
        setFieldValue(formikKey, e.target.value === 'true');
      } else {
        setFieldValue(formikKey, e.target.value);
      }
    },
    [formikKey, setFieldValue],
  );

  return (
    <label className={classNames(styles.container, className)}>
      <input
        {...field}
        checked={value === formikValue}
        name={formikKey}
        onChange={handleChange}
        type="radio"
        value={String(value)}
      />
      <span className={styles.checkmark} />
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
};
