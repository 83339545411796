import { Box, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import max from 'lodash/max';
import { Fragment, ReactElement, useMemo } from 'react';

// Core.
import { ILearnerCredit } from 'core';

interface IBoardCreditsProps {
  abbreviation: string;
  credits: ILearnerCredit[];
}

// Icon styles
const useStyles = makeStyles({
  colorPrimary: {
    fill: '#82bc00',
  },
});

export const BoardCredits = (props: IBoardCreditsProps): ReactElement => {
  const { abbreviation, credits = [] } = props;
  const isAccepted: boolean = useMemo(() => credits.some(({ isAccepted }) => isAccepted), [credits]);
  const totalCredits: number = useMemo(() => max(credits.map(({ totalCredits }) => totalCredits)), [credits]);
  const units: string = useMemo(() => credits[0]?.units || 'Points', [credits]);
  const classes = useStyles();

  return (
    <>
      <Grid container item justify="flex-end" xs={1}>
        {isAccepted ? <CheckIcon className={classes.colorPrimary} /> : ' '}
      </Grid>
      <Grid item xs={11}>
        <strong>
          {totalCredits} Total {abbreviation} {units}
        </strong>
      </Grid>
      {credits.map(
        ({ acceptedCredits: amount, name }: ILearnerCredit, index: number): ReactElement => (
          <Fragment key={index}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Box ml={2}>
                {amount || 0} {name}
              </Box>
            </Grid>
          </Fragment>
        ),
      )}
    </>
  );
};
