import { ReactElement } from 'react';
import styles from '../AccountSettings.module.scss';

interface IProps {
  output?: string;
  conditions?: Array<boolean | number | string>;
}

export const ContentOutput = ({ output, conditions }: IProps): ReactElement => {
  const isConditionsMet = output && conditions?.every((val: boolean | number | string): boolean => !!val);
  return (
    <p className={styles.content}>
      {isConditionsMet ? <span>{output}</span> : <span className={styles['not-provided']}>Not Provided</span>}
    </p>
  );
};
