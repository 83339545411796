import Icon from '@material-ui/core/Icon';
import { ExpandMoreRounded, ExpandLessRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement, useState, useRef } from 'react';

// Components
import { Button } from 'components';

// Hooks.
import { useOnClickOutside } from 'hooks';

// Styles
import styles from './ContextualMenu.module.scss';

export interface IContextualMenuOptionProps {
  name: string;
  onSelectAction?(): void;
  icon?: string;
  selected?: boolean;
}

export interface IContextualMenuProps {
  label?: string;
  options?: IContextualMenuOptionProps[];
}

export const ContextualMenu = (props: IContextualMenuProps): ReactElement => {
  const { label, options } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef(null);

  useOnClickOutside(node, () => setMenuOpen(false));

  return (
    <div ref={node} className={styles['contextual-dropdown']}>
      <div className={styles['contextual-dropdown-selection']}>
        <span className={styles['contextual-label']}>{label}</span>
        <Button onClick={(): void => setMenuOpen(!menuOpen)}>
          {options?.find((o: IContextualMenuOptionProps): boolean => o.selected)?.name}
          {menuOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
        </Button>
      </div>
      <div className={classNames(styles['contextual-dropdown-menu'], { [styles.active]: menuOpen })}>
        <ul>
          {options?.map(
            (option: IContextualMenuOptionProps, idx: number): ReactElement => {
              const { icon, name, onSelectAction, selected } = option;
              return (
                <li
                  key={idx}
                  className={classNames(styles['menu-item'], { [styles.selected]: selected })}
                  onClick={(): void => {
                    onSelectAction?.();
                    setMenuOpen(false);
                  }}
                >
                  {icon && <Icon>{icon}</Icon>}
                  {name}
                </li>
              );
            },
          )}
        </ul>
      </div>
    </div>
  );
};
