import { useCallback, useState } from 'react';

// Hooks.
import { EventArgs, GtmEvents, useEvent, useAuth } from 'hooks';

type LoadEventFunction = (overrideArgs?: EventArgs, force?: boolean) => void;

export const useLoadEvent = (args: EventArgs): LoadEventFunction => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [, { isAuthenticated }] = useAuth();
  const fireEvent = useEvent();

  const { PageName, ...rest } = args;

  return useCallback(
    (overrideArgs?: EventArgs, force?: boolean) => {
      if (!isLoaded || force) {
        setIsLoaded(true);

        // Wait 150ms to ensure react-helmet has time
        // to update page metadata before firing.
        setTimeout(() => {
          fireEvent({
            Event: 'pageLoad',
            LoginStatus: isAuthenticated,
            Page: window.location.href,
            PageName: PageName || document.title,
            event: GtmEvents.PageLoad,
            ...rest,
            ...overrideArgs,
          });
        }, 150);
      }
    },
    [PageName, isAuthenticated, isLoaded, rest, fireEvent],
  );
};
