import { CloseRounded, MenuRounded, ChevronRightRounded, PersonRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement, useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Assets.
import { ReactComponent as AccmeLogo } from 'globals/images/accme-logo-mobile.svg';

// Components.
import { Button } from 'components';

// Hooks.
import { useAuth, useAuthLogin, useAuthLogout, useClickEvent } from 'hooks';

// Styles.
import styles from './MobileHeader.module.scss';

export const MobileHeader = (): ReactElement => {
  const fireEvent = useClickEvent({ Event: 'navigation', EventCategory: 'Navigation' });
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [account, { isAuthenticated }] = useAuth();
  const login = useAuthLogin();
  const logout = useAuthLogout();
  const { pathname } = useLocation();
  const matchProfilePage: boolean = pathname.indexOf('profile') > -1;

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  return (
    <header>
      <div className={styles.masthead}>
        <Button className={styles.hamburger} title="menu button" type="button" onClick={toggleMenu}>
          {menuOpen ? <CloseRounded /> : <MenuRounded />}
        </Button>
        <div className={styles.logo}>
          <Link to="/">
            <AccmeLogo className={styles.logoImage} />
          </Link>
        </div>
      </div>
      <nav className={classNames(styles['menu-container'], { [styles.open]: menuOpen })}>
        <ul className={styles.menu}>
          <li>
            {/* Buttons as links in the event that CME Finder will have Submenus */}
            <Button
              className={styles['menu-btn']}
              component={Link}
              onClick={() => {
                fireEvent({ EventAction: 'Header', EventLabel: '/activity/search' });
                toggleMenu();
              }}
              title="Find Accredited CME"
              to="/activity/search"
            >
              <span>Find Accredited CME</span>
              <ChevronRightRounded />
            </Button>
          </li>
          {isAuthenticated ? (
            <>
              {!matchProfilePage && (
                <li>
                  <Button
                    className={styles['menu-btn']}
                    component={Link}
                    onClick={() => {
                      fireEvent({ EventAction: 'Header', EventLabel: '/profile/transcripts' });
                      toggleMenu();
                    }}
                    title="View My Transcripts"
                    to="/profile/transcripts"
                  >
                    <span>View My Transcripts</span>
                    <ChevronRightRounded />
                  </Button>
                </li>
              )}
              <li>
                <Button
                  className={styles['menu-btn']}
                  onClick={() => {
                    fireEvent({ EventAction: 'Header', EventLabel: 'Log Out' });
                    logout();
                  }}
                  title="Log Out"
                  type="button"
                >
                  <span>Log Out</span>
                  <ChevronRightRounded />
                </Button>
              </li>
              <li>
                <Button
                  className={styles['menu-btn']}
                  component={Link}
                  onClick={() => {
                    fireEvent({ EventAction: 'Header', EventLabel: '/profile' });
                    toggleMenu();
                  }}
                  title={account?.name}
                  to="/profile"
                >
                  <span>
                    <PersonRounded />
                    {account?.name}
                  </span>
                  <ChevronRightRounded />
                </Button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Button
                  className={styles['menu-btn']}
                  onClick={() => {
                    fireEvent({ EventAction: 'Header', EventLabel: 'Log In' });
                    login();
                  }}
                  title="Log In"
                  type="button"
                >
                  <span>Log In</span>
                  <ChevronRightRounded />
                </Button>
              </li>
              <li>
                <Button
                  className={styles['menu-btn']}
                  component={Link}
                  onClick={() => {
                    fireEvent({ EventAction: 'Header', EventLabel: '/register' });
                    toggleMenu();
                  }}
                  title="Create an Account"
                  to="/register"
                >
                  <span>Create an Account</span>
                  <ChevronRightRounded />
                </Button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};
