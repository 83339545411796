import * as yup from 'yup';
import { AnySchema, StringSchema } from 'yup';

export const overviewValidationSchema = yup.object({
  email: yup.string().required('Email is required.').email('Must be a valid email.'),
  password: yup
    .string()
    .notRequired()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
    ),
  passwordConfirmation: yup.string().when('password', {
    is: (password: string): boolean => !!password,
    otherwise: yup.string().notRequired(),
    then: (schema: StringSchema): AnySchema =>
      schema.required('Passwords must match.').oneOf([yup.ref('password')], 'Passwords must match.'),
  }),
});
