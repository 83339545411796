// Libs
import { Fragment, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseRounded } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { Box, CircularProgress, Fab, Grid } from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';

// Components
import { Button, FormikDropdown, FormikInputField } from 'components';
import { ITypeaheadOption } from 'components/Typeahead';
import { WizardButtonRow } from '../WizardButtonRow';

// Styles
import styles from '../Wizard.module.scss';

// Core
import { IProfileStateBoard, IStateOrProvince, IUserProfile } from 'core';

// Store
import { stateAndProvinceSelector } from 'store/statesAndProvinces/selectors';
import { getStatesAndProvinces } from 'store/statesAndProvinces/actions';

// Types
import { IWizardProps } from '../types';
type IProps = Pick<IWizardProps, 'back' | 'next' | 'skip'>;

export const StateBoards = ({ back, skip, next }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<IUserProfile>();
  const statesAndProvinces: IStateOrProvince[] = useSelector(stateAndProvinceSelector);
  const stateOptions: ITypeaheadOption[] = useMemo(
    () =>
      statesAndProvinces
        ?.filter(({ stateName }: IStateOrProvince): boolean => !!stateName)
        ?.map(
          ({ stateName, isoStateCode }: IStateOrProvince): ITypeaheadOption => ({
            label: stateName,
            value: isoStateCode,
          }),
        ),
    [statesAndProvinces],
  );

  useEffect((): void => {
    dispatch(getStatesAndProvinces());
  }, [dispatch]);

  const { stateBoards } = values;

  // Get all of the ids that have been selected so that we can disable them from the picker.
  const filteredStateIds = (stateBoards ?? [])
    .map(({ stateAbbreviation }: IProfileStateBoard): string => stateAbbreviation)
    .filter(Boolean);

  return (
    <>
      <h5>In which state(s) are you licensed?</h5>
      <br />
      <div className={styles['boards-repeater']}>
        {!statesAndProvinces?.length ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <FieldArray name="stateBoards">
              {({ push, remove }): ReactElement => {
                return (
                  <>
                    <Grid container spacing={3}>
                      {stateBoards?.map(
                        (board: IProfileStateBoard, index: number): ReactElement => {
                          const { isEditable, stateAbbreviation } = board;

                          return (
                            <Fragment key={`${index}-${stateAbbreviation}`}>
                              <Grid item xs={6} sm={7}>
                                <FormikDropdown
                                  disabled={isEditable === false}
                                  disabledItemKeys={filteredStateIds}
                                  formikKey={`stateBoards.${index}.stateAbbreviation`}
                                  idProp="value"
                                  items={stateOptions}
                                  labelProp="label"
                                />
                              </Grid>
                              <Grid item xs={5} sm={4}>
                                <FormikInputField
                                  disabled={isEditable === false}
                                  formikKey={`stateBoards.${index}.learnerId`}
                                  placeholder="ID:"
                                />
                              </Grid>
                              {isEditable !== false && (
                                <Grid item xs={1} className={styles.remove}>
                                  <Button className="remove" onClick={() => remove(index)}>
                                    <CloseRounded />
                                  </Button>
                                </Grid>
                              )}
                            </Fragment>
                          );
                        },
                      )}
                    </Grid>
                    <div className={styles.add}>
                      <Fab
                        aria-label="add another instance"
                        color="inherit"
                        size="small"
                        onClick={() => push({ isEditable: true })}
                      >
                        <AddIcon color="inherit" />
                      </Fab>
                      <span className={styles.text}>Add Another Licensing State</span>
                    </div>
                  </>
                );
              }}
            </FieldArray>
            <div className={styles.caption}>
              ACCME has a direct reporting relationship with some{' '}
              <a href="https://accme.org/state-medical-boards-collaboration" target="_blank" rel="noopener noreferrer">
                licensing boards
              </a>
              . CME providers can report your participation on your behalf to those boards.
            </div>
          </>
        )}
        <Box display="flex" my={3}>
          <WizardButtonRow skip={skip} next={next} back={back} />
        </Box>
      </div>
    </>
  );
};
