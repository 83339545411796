import { createSelector } from 'reselect';
// Store.
import { AppState } from 'store';

// Types.
import { UserState } from './types';

export const onboardingSelector = ({ onboarding }: AppState): UserState => onboarding;

export const onboardingIsLoadingSelector = createSelector(onboardingSelector, ({ isLoading }) => isLoading);

export const onboardingRequestSelector = createSelector(
  onboardingSelector,
  ({ onboardingRequest }) => onboardingRequest,
);

export const onboardingMatchFailureCountSelector = createSelector(
  onboardingSelector,
  ({ matchFailureCount }) => matchFailureCount,
);

export const onboardingHasULIDAssignedSelector = createSelector(
  onboardingRequestSelector,
  ({ ulid }): boolean => !!ulid,
);

export const onboardingIsAbleToMatchSelector = createSelector(
  onboardingSelector,
  ({ isAbleToMatch }): boolean => isAbleToMatch,
);

export const onboardingIsClaimedSelector = createSelector(onboardingRequestSelector, ({ isClaimed }) => isClaimed);
export const onboardingIsLicensedInUnitedStatesSelector = createSelector(
  onboardingSelector,
  ({ isLicensedInUnitedStates }) => isLicensedInUnitedStates,
);
