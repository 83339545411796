import { ReactElement } from 'react';
import omit from 'lodash/omit';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

// Core + Store
import { IFacetProps, SearchRequest } from 'components/Facets/types';

// Styles.
import styles from './BooleanCheckboxes.module.scss';

// Types
interface ICheckboxProps {
  callback?(): void;
  label: string;
  name: string;
  type: 'checkbox';
  value: boolean;
}
const Checkbox = ({ label, value, callback, ...props }: ICheckboxProps): ReactElement => {
  const [field, , helpers] = useField(props.name);
  const { values, setValues } = useFormikContext<SearchRequest>();
  const { setValue } = helpers;
  const isChecked = values[props.name] === value;

  const onChange = (): void => {
    if (isChecked) {
      setValues(omit(values, field.name));
      callback?.();
    } else {
      setValue(value);
      callback?.();
    }
  };

  return (
    <>
      <label className={styles.checkbox}>
        <input {...field} {...props} checked={isChecked} onChange={onChange} />
        <span className="checkmark">
          <div className="label-text-container">
            <div className="label-text">{label}</div>
          </div>
        </span>
      </label>
    </>
  );
};

export const BooleanCheckboxes = (props: IFacetProps): ReactElement => {
  const { callback, classes, facet, facets, isDisabled, isExpanded, expandIcon, onToggleExpand } = props;

  const { displayValue, payloadValue, responseValue } = facet;

  return (
    (facets?.[responseValue] && (
      <Accordion
        key={payloadValue}
        className={classes.accordion}
        disabled={isDisabled}
        expanded={isExpanded}
        onChange={onToggleExpand}
      >
        <AccordionSummary
          aria-controls={`${payloadValue}-content`}
          className={classes.summary}
          expandIcon={expandIcon}
          id={`${payloadValue}-header`}
        >
          {displayValue}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Checkbox callback={callback} name={payloadValue} value={!!payloadValue} type="checkbox" label="Yes" />
          <Checkbox callback={callback} name={payloadValue} value={false} type="checkbox" label="No" />
        </AccordionDetails>
      </Accordion>
    )) ||
    null
  );
};
