// Libs
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@material-ui/core';

// Components
import { FormikInputField } from 'components/forms';
import { ButtonRow } from 'components/ButtonRow';

// Core
import { IUserProfile } from 'core';

// Store
import { userProfileSelector } from 'store/profile/selectors';
import { updatePassword } from 'store/profile/actions';

// Misc
import { overviewValidationSchema } from './overviewValidationSchema';

interface IProps {
  onClose: VoidFunction;
}

type IOverviewForm = Pick<IUserProfile, 'email'> & { password?: string; passwordConfirmation?: string };

export const EditOverview = ({ onClose }: IProps): ReactElement => {
  const dispatch = useDispatch();

  // Selectors.
  const userProfile = useSelector(userProfileSelector);

  const formik = useFormik({
    initialValues: {
      email: userProfile.email,
    },
    onSubmit: async (values: IOverviewForm): Promise<void> => {
      await dispatch(updatePassword(values.password));
      onClose();
    },
    validationSchema: overviewValidationSchema,
  });

  const { handleSubmit, isSubmitting, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="email" label="Email" readOnly type="email" required />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="password" label="Password" type="password" />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="passwordConfirmation" label="Confirm Password" type="password" />
          </Grid>
        </Grid>
        <ButtonRow
          firstButtonText="Save"
          isPrimaryDisabled={isSubmitting || !isValid || !dirty}
          isSecondaryDisabled={isSubmitting}
          isShowProgress={isSubmitting}
          onCancel={onClose}
          secondButtonText="Cancel"
          type="submit"
        />
      </form>
    </FormikProvider>
  );
};
