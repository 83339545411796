import { Box, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import max from 'lodash/max';
import { ReactElement, useMemo } from 'react';

// Core.
import { ILearnerCredit } from 'core';

interface IStateCreditsProps {
  abbreviation: string;
  credits: ILearnerCredit[];
}

// Icon styles
const useStyles = makeStyles({
  colorPrimary: {
    fill: '#82bc00',
  },
});

export const StateCredits = (props: IStateCreditsProps): ReactElement => {
  const { abbreviation, credits = [] } = props;
  const accepted: ILearnerCredit[] = credits.filter((c) => c.isAccepted);
  const notAccepted: ILearnerCredit[] = credits.filter((c) => !c.isAccepted);
  const acceptedCredits: number = useMemo(() => max(credits.map(({ acceptedCredits }) => acceptedCredits)), [credits]);
  const units: string = useMemo(() => credits[0]?.units || 'Credits', [credits]);
  const classes = useStyles();

  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <strong>
          {acceptedCredits} {abbreviation} {units}
        </strong>
      </Grid>
      {accepted.length > 0 && (
        <>
          <Grid container item justify="flex-end" xs={1}>
            <CheckIcon className={classes.colorPrimary} />
          </Grid>
          <Grid item xs={11}>
            <Box>
              {accepted
                .map(({ stateAbbreviation }) => stateAbbreviation)
                .filter(Boolean)
                .join(', ')}
            </Box>
          </Grid>
        </>
      )}
      {notAccepted.length > 0 && (
        <>
          <Grid container item justify="flex-end" xs={1}>
            {' '}
          </Grid>
          <Grid item xs={11}>
            <Box>
              {notAccepted
                .map(({ stateAbbreviation }) => stateAbbreviation)
                .filter(Boolean)
                .join(', ')}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};
