/* eslint-disable @typescript-eslint/no-explicit-any */
import keys from 'lodash/keys';
import { Selector } from 'react-redux';

// Components.
import { ITypeaheadOption } from 'components';

// Core.
import { FacetTypeEnum } from './enums';

// Store.
import { AppState } from 'store';
import { specialtiesSelector } from 'store/profile/selectors';
import { getSpecialties } from 'store/profile/actions';
import {
  searchFacetsCountrySelector,
  searchFacetsProviderNameSelector,
  searchFacetsJointProviderSelector,
  searchFacetsStateSelector,
} from '../store/activity/selectors';

export const CENTRAL_TIMEZONE = 'America/Chicago'; // certain validation rules target central timesone specifically, and should continue to, even if the default changes
export const DEFAULT_TIMEZONE = CENTRAL_TIMEZONE;
export const DEFAULT_COUNTRY = 'United States of America';
export const DEFAULT_DATE_FORMAT = 'MM/DD/yyyy';

// Search
export const DEFAULT_RESULTS_PER_PAGE = 10;
export const SORT_BY_RECENTLY_ADDED = 'Timestamp';
export const SORT_BY_A_Z_TITLE = 'Title';
export const SORT_BY_START_DATE = 'StartDate';
export const SORT_BY_END_DATE = 'EndDate';
export const SORT_BY_ACTIVITY_ID = 'Id';
export const SORT_BY_INTERNAL_ID = 'InternalId';
export const SORT_BY_CLAIM_DATE = 'ClaimDate';
export const SORT_BY_UAN = 'UANPharmacy';
export const SORT_BY_UAN_TECHNICIAN = 'UANPharmacyTech';

// Transcripts
export const SORT_BY_ACTIVITY_TITLE = 'ActivityTitle';
export const SORT_BY_COMPLETION_DATE = 'CompletionDate';
export const SORT_BY_PROVIDER_NAME = 'Provider';

// Storage
const cachePrefix = 'cmeFinder';

export const CACHE_ACTIVITY_KEYWORDS = `${cachePrefix}.activityKeywords`;
export const CACHE_ACTIVITY_RECENT_SEARCHES = `${cachePrefix}.recentSearches`;
export const CACHE_ACTIVITY_RECENT_SEARCHES_MAX = 5;
export const CACHE_COOKIE_PREFERENCE = `${cachePrefix}.cookiesAccepted`;
export const CACHE_TAXONOMY_TERM_PREFIX = `${cachePrefix}.taxonomyTerm_`;
export const CACHE_TAXONOMY_TERM_EXPIRY_MINS = 60 * 24;
export const CACHE_ACCESS_TOKEN = `${cachePrefix}.access_token`;
export const CACHE_IMPERSONATION_ACCESS_TOKEN = `${cachePrefix}.impersonation_access_token`;

// Taxonomy.
export const TAXONOMY_FAQ_ROOT_ID = '3d040401-2456-48bb-966e-052e5e47e3b5';
export const TAXONOMY_FOOTER_ID = '8219E6F4-CAE4-443F-8F54-FE48AB97D6CD';
export const TAXONOMY_MEDICAL_SCHOOLS_ID = 'D65ACB57-5F80-4C29-9EAE-C4F6EAA6D0D0';
export const TAXONOMY_NAVIGATION_ROOT_ID = 'd5f00740-e38d-496c-818f-a7df3d6b6788';
export const TAXONOMY_HELPDESK_TOPICS_ROOT_ID = '5F6C16BA-A829-490C-B880-AB27249D0BC9';

/***
 * Tags
 * @description - These are used as well-known taxonomy ids that do not change.
 *                Taxonomy term ids have the potential to change so we use these instead to rely on a constant lookup.
 */
export enum TAGS_ENUM {
  ACTIVITY_CREDIT_TYPE__AAPA_CATEGORY1 = 'ACTIVITY_CREDIT_TYPE__AAPA_CATEGORY1',
  ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1 = 'ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1',
  ACTIVITY_CREDIT_TYPE__CONTROLLED_SUBSTANCE = 'ACTIVITY_CREDIT_TYPE__CONTROLLED_SUBSTANCE',
  ACTIVITY_CREDIT_TYPE__DENTISTRY = 'ACTIVITY_CREDIT_TYPE__DENTISTRY',
  ACTIVITY_CREDIT_TYPE__DIETIC_CPEUS = 'ACTIVITY_CREDIT_TYPE__DIETIC_CPEUS',
  ACTIVITY_CREDIT_TYPE__IPCE_CREDITS = 'ACTIVITY_CREDIT_TYPE__IPCE_CREDITS',
  ACTIVITY_CREDIT_TYPE__NURSING = 'ACTIVITY_CREDIT_TYPE__NURSING',
  ACTIVITY_CREDIT_TYPE__OPTOMETRY = 'ACTIVITY_CREDIT_TYPE__OPTOMETRY',
  ACTIVITY_CREDIT_TYPE__PAIN_MANAGEMENT = 'ACTIVITY_CREDIT_TYPE__PAIN_MANAGEMENT',
  ACTIVITY_CREDIT_TYPE__PHARMACY = 'ACTIVITY_CREDIT_TYPE__PHARMACY',
  ACTIVITY_CREDIT_TYPE__PRESCRIBING_OPIOD_EDUCATION = 'ACTIVITY_CREDIT_TYPE__PRESCRIBING_OPIOD_EDUCATION',
  ACTIVITY_CREDIT_TYPE__PSYCHOLOGY = 'ACTIVITY_CREDIT_TYPE__PSYCHOLOGY',
  ACTIVITY_CREDIT_TYPE__SOCIAL_WORK = 'ACTIVITY_CREDIT_TYPE__SOCIAL_WORK',
  LEARNER_TYPES__ALLIED_DENTAL_STAFF = 'LEARNER_TYPES__ALLIED_DENTAL_STAFF',
  LEARNER_TYPES__DENTISTS = 'LEARNER_TYPES__DENTISTS',
  LEARNER_TYPES__DIETETIC_TECHNICIANS_REGISTERED = 'LEARNER_TYPES__DIETETIC_TECHNICIANS_REGISTERED',
  LEARNER_TYPES__NURSES = 'LEARNER_TYPES__NURSES',
  LEARNER_TYPES__OPTOMETRISTS = 'LEARNER_TYPES__OPTOMETRISTS',
  LEARNER_TYPES__OTHER_LEARNERS = 'LEARNER_TYPES__OTHER_LEARNERS',
  LEARNER_TYPES__PHARMACISTS = 'LEARNER_TYPES__PHARMACISTS',
  LEARNER_TYPES__PHARMACY_TECHNICIANS = 'LEARNER_TYPES__PHARMACY_TECHNICIANS',
  LEARNER_TYPES__PHYSICIAN_ASSISTANTS = 'LEARNER_TYPES__PHYSICIAN_ASSISTANTS',
  LEARNER_TYPES__PHYSICIANS = 'LEARNER_TYPES__PHYSICIANS',
  LEARNER_TYPES__PSYCHOLOGISTS = 'LEARNER_TYPES__PSYCHOLOGISTS',
  LEARNER_TYPES__REGISTERED_DIETITIANS = 'LEARNER_TYPES__REGISTERED_DIETITIANS',
  LEARNER_TYPES__SOCIAL_WORKERS = 'LEARNER_TYPES__SOCIAL_WORKERS',
  MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH = 'MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH',
  MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__OBJECTIVE = 'MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__OBJECTIVE',
  MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__SUBJECTIVE = 'MEASURED_OUTCOMES__COMMUNITY_POPULATION_HEALTH__SUBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_COMPETENCE = 'MEASURED_OUTCOMES__LEARNER_COMPETENCE',
  MEASURED_OUTCOMES__LEARNER_COMPETENCE__OBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_COMPETENCE__OBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_COMPETENCE__SUBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_COMPETENCE__SUBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_KNOWLEDGE = 'MEASURED_OUTCOMES__LEARNER_KNOWLEDGE',
  MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__OBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__OBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__SUBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_KNOWLEDGE__SUBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_PERFORMANCE = 'MEASURED_OUTCOMES__LEARNER_PERFORMANCE',
  MEASURED_OUTCOMES__LEARNER_PERFORMANCE__OBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_PERFORMANCE__OBJECTIVE',
  MEASURED_OUTCOMES__LEARNER_PERFORMANCE__SUBJECTIVE = 'MEASURED_OUTCOMES__LEARNER_PERFORMANCE__SUBJECTIVE',
  MEASURED_OUTCOMES__PATIENT_HEALTH = 'MEASURED_OUTCOMES__PATIENT_HEALTH',
  MEASURED_OUTCOMES__PATIENT_HEALTH__OBJECTIVE = 'MEASURED_OUTCOMES__PATIENT_HEALTH__OBJECTIVE',
  MEASURED_OUTCOMES__PATIENT_HEALTH__SUBJECTIVE = 'MEASURED_OUTCOMES__PATIENT_HEALTH__SUBJECTIVE',
  SPECIALTY_BOARD_CREDIT_TYPES__ABPATH_MOC_SAM = 'SPECIALTY_BOARD_CREDIT_TYPES__ABPATH_MOC_SAM',
  SPECIALTY_BOARD_CREDIT_TYPES__ABS_MOC = 'SPECIALTY_BOARD_CREDIT_TYPES__ABS_MOC',
  SPECIALTY_BOARD_CREDIT_TYPES__ACCREDITED_CME = 'SPECIALTY_BOARD_CREDIT_TYPES__ACCREDITED_CME',
  SPECIALTY_BOARD_CREDIT_TYPES__IMPROVEMENT_IN_MEDICAL_PRACTICE = 'SPECIALTY_BOARD_CREDIT_TYPES__IMPROVEMENT_IN_MEDICAL_PRACTICE',
  SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING = 'SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING',
  SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING_AND_SELF_ASSESSMENT = 'SPECIALTY_BOARD_CREDIT_TYPES__LIFELONG_LEARNING_AND_SELF_ASSESSMENT',
  SPECIALTY_BOARD_CREDIT_TYPES__MEDICAL_KNOWLEDGE = 'SPECIALTY_BOARD_CREDIT_TYPES__MEDICAL_KNOWLEDGE',
  SPECIALTY_BOARD_CREDIT_TYPES__PATIENT_SAFETY = 'SPECIALTY_BOARD_CREDIT_TYPES__PATIENT_SAFETY',
  SPECIALTY_BOARD_CREDIT_TYPES__PRACTICE_ASSESSMENT = 'SPECIALTY_BOARD_CREDIT_TYPES__PRACTICE_ASSESSMENT',
  SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT = 'SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT',
  SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT_MODULE_PART2 = 'SPECIALTY_BOARD_CREDIT_TYPES__SELF_ASSESSMENT_MODULE_PART2',
  HELP_DESK__LOG_IN_ISSUES = 'HELP_DESK__LOG_IN_ISSUES',
}

export interface IFacetAccordion {
  alphabetize?: boolean;
  asArray?: boolean;
  displayValue: string;
  payloadEndValue?: string;
  payloadIdValue?: string;
  payloadNameValue?: string;
  payloadStartValue?: string;
  payloadValue?: string;
  reduxAction?: VoidFunction;
  reduxSelector?: Selector<AppState, any>;
  responseValue?: string;
  specificSortOrder?: string[];
  transformTypeaheadData?: (data: any) => ITypeaheadOption[];
  type: FacetTypeEnum;
}

// User Transcript Facets
export const TRANSCRIPT_ACCORDION_TITLES: IFacetAccordion[] = [
  {
    displayValue: 'Activity Date Range',
    payloadEndValue: 'endDate',
    payloadStartValue: 'startDate',
    type: FacetTypeEnum.Date,
  },
  {
    alphabetize: true,
    displayValue: 'Certifying Boards',
    payloadValue: 'boardNames',
    responseValue: 'Credits/BoardName', // BE sends this as the value.
    type: FacetTypeEnum.Array,
  },
  {
    alphabetize: true,
    displayValue: 'State Medical Boards',
    payloadValue: 'stateNames',
    responseValue: 'Credits/StateName',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Format',
    payloadValue: 'activityFormats',
    responseValue: 'ActivityFormat',
    type: FacetTypeEnum.Array,
  },
  {
    alphabetize: true,
    displayValue: 'Credit Types',
    payloadValue: 'creditTypeNames',
    responseValue: 'Credits/Name',
    type: FacetTypeEnum.Array,
  },
];

// Generic Activity Facets
export const ACCORDION_TITLES: IFacetAccordion[] = [
  {
    asArray: true,
    displayValue: 'Specialty',
    payloadValue: 'practiceAreas',
    reduxAction: getSpecialties,
    reduxSelector: specialtiesSelector,
    responseValue: 'PracticeAreas/Name',
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ label: data[id], value: id })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'Certifying Boards',
    payloadValue: 'boards',
    responseValue: 'Boards/Name', // BE sends this as the value.
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Activity Format',
    payloadValue: 'type',
    responseValue: 'CMEFinderType',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Educational Delivery Method', // Learning Method
    payloadValue: 'locationTypes',
    responseValue: 'LocationType', // Notice the spelling differences.
    type: FacetTypeEnum.Array,
  },
  // These are called `Collaborations` in the spreadsheet.
  // ACCME wants these split out into MIPS/REMS/MOC.
  {
    displayValue: 'MOC',
    payloadValue: 'isMoc',
    responseValue: 'IsMoc',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'REMS',
    payloadValue: 'isRems',
    responseValue: 'IsRems',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'MIPS',
    payloadValue: 'isMips',
    responseValue: 'IsMips',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'Registration',
    payloadValue: 'registration',
    responseValue: 'Registration',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Credit Types',
    payloadValue: 'creditTypes',
    responseValue: 'CreditTypes/Name', // BE sends this as the value.
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'Fee',
    payloadValue: 'participationFee',
    responseValue: 'ParticipationFee',
    type: FacetTypeEnum.Array,
  },
  {
    displayValue: 'CME Points',
    payloadValue: 'cmeCreditTypeTotal',
    responseValue: 'CMECreditTypeTotal',
    type: FacetTypeEnum.Range,
  },
  {
    displayValue: 'MOC Points',
    payloadValue: 'moCCreditTypeTotal',
    responseValue: 'MoCCreditTypeTotal',
    type: FacetTypeEnum.Range,
  },
  {
    displayValue: 'Activity Date Range',
    payloadEndValue: 'endDate',
    payloadStartValue: 'startDate',
    type: FacetTypeEnum.Date,
  },
  // These are called `Locations` in the spreadsheet.
  // ACCME wants these split out into State/City/Country.
  {
    asArray: true,
    displayValue: 'State',
    payloadValue: 'states', // FE sends plural, Sends an array of one.
    reduxSelector: searchFacetsStateSelector,
    responseValue: 'State', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'City',
    payloadValue: 'cities', // FE sends plural
    responseValue: 'City', // BE is singular
    type: FacetTypeEnum.Text,
  },
  {
    asArray: true,
    displayValue: 'Country',
    payloadValue: 'countries', // FE sends plural, Sends an array of one.
    reduxSelector: searchFacetsCountrySelector,
    responseValue: 'Country', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    asArray: true,
    displayValue: 'Provider Name',
    payloadValue: 'providerNames', // FE sends plural, Sends an array of one.
    reduxSelector: searchFacetsProviderNameSelector,
    responseValue: 'ProviderName', // BE is singular
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'Providership',
    payloadValue: 'Providership',
    responseValue: 'Providership',
    type: FacetTypeEnum.Array,
  },
  {
    asArray: true,
    displayValue: 'Joint Provider Name',
    payloadValue: 'jointProviders', // Sends an array of one.
    reduxSelector: searchFacetsJointProviderSelector,
    responseValue: 'JointProviders/Name',
    transformTypeaheadData: (data) => keys(data).map((id: string) => ({ id, label: data[id] })),
    type: FacetTypeEnum.Typeahead,
  },
  {
    displayValue: 'Commercial Support',
    payloadValue: 'isCommercialSupport',
    responseValue: 'IsCommercialSupport',
    type: FacetTypeEnum.Boolean,
  },
  {
    displayValue: 'MOC Credit Types',
    payloadValue: 'creditTypeWithBoards',
    responseValue: 'MoCCreditTypes/Name',
    type: FacetTypeEnum.Array,
  },
];

export const IMPERSONATION_TOKEN_HASH = 'impersonationToken';
