import { ITaxonomyTerm, PARSAction } from 'core';

export type TaxonomyTerms = { [id: string]: ITaxonomyTerm };

export interface TaxonomyState {
  terms: TaxonomyTerms;
}

export const GET_TAXONOMY_TERM_BY_ID = 'GET_TAXONOMY_TERM_BY_ID';
export const GET_TAXONOMY_TERM_BY_ID_SUCCESS = 'GET_TAXONOMY_TERM_BY_ID_SUCCESS';
export const GET_TAXONOMY_TERM_BY_ID_FAILURE = 'GET_TAXONOMY_TERM_BY_ID_FAILURE';

interface GetTaxonomyTermById extends PARSAction<undefined, typeof GET_TAXONOMY_TERM_BY_ID> {
  type: typeof GET_TAXONOMY_TERM_BY_ID;
}

interface GetTaxonomyTermByIdSuccess extends PARSAction<TaxonomyTerms, typeof GET_TAXONOMY_TERM_BY_ID_SUCCESS> {
  type: typeof GET_TAXONOMY_TERM_BY_ID_SUCCESS;
}

interface GetTaxonomyTermByIdFailure extends PARSAction<Error, typeof GET_TAXONOMY_TERM_BY_ID_FAILURE> {
  type: typeof GET_TAXONOMY_TERM_BY_ID_FAILURE;
}

export type TaxonomyActionTypes = GetTaxonomyTermById | GetTaxonomyTermByIdSuccess | GetTaxonomyTermByIdFailure;
