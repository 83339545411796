import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import findIndex from 'lodash/findIndex';
import isNaN from 'lodash/isNaN';
import uniqueId from 'lodash/uniqueId';
import { ChangeEvent, ReactElement } from 'react';

// Styles.
import styles from './RangeCheckboxes.module.scss';

// Types.
import { IFacetProps, SearchRequest } from 'components/Facets/types';

export const RangeCheckboxes = (props: IFacetProps): ReactElement => {
  const { callback, classes, expandIcon, isDisabled, isExpanded, facet, facets, onToggleExpand } = props;
  const { values } = useFormikContext<SearchRequest>();
  const { displayValue, payloadValue, responseValue } = facet;

  return (
    <Accordion
      key={payloadValue}
      className={classes.accordion}
      disabled={isDisabled}
      expanded={isExpanded}
      onChange={onToggleExpand}
    >
      <AccordionSummary
        aria-controls={`${payloadValue}-content`}
        className={classes.summary}
        expandIcon={expandIcon}
        id={`${payloadValue}-header`}
      >
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details} role="group" aria-labelledby={`${payloadValue}-header`}>
        <FieldArray
          name={payloadValue}
          render={(arrayHelpers: FieldArrayRenderProps): ReactElement => {
            const options = facets?.[responseValue] ?? [];
            return options.map((currentFacet, idx) => {
              const lowerBound = currentFacet;
              const upperBound = options[idx + 1] - 1;
              const value = [lowerBound, upperBound].filter((toCheck) => !isNaN(toCheck));
              const valueIndex = findIndex(
                values?.[facet?.payloadValue],
                (checkedValue) => checkedValue[0] === lowerBound,
              );
              const isChecked = valueIndex > -1;

              return (
                <label className={styles.checkbox} key={uniqueId(idx)}>
                  <input
                    checked={isChecked}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      if (e.target.checked) {
                        arrayHelpers.push(value);
                      } else {
                        arrayHelpers.remove(valueIndex);
                      }

                      callback?.();
                    }}
                    type="checkbox"
                  />
                  <span className="checkmark">
                    <div className="label-text-container">
                      {!upperBound ? (
                        <div>{lowerBound}+</div>
                      ) : (
                        <div>
                          {lowerBound} - {upperBound}
                        </div>
                      )}
                    </div>
                  </span>
                </label>
              );
            });
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
