import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components.
import { OnboardingMatchLearnerForm } from 'components';

// Hooks.
import { useLoadEvent } from 'hooks';

// Styles.
import styles from './MatchLearnerPage.module.scss';

// Store + Core
import { successToastOptions } from 'store/toast/constants';
import { popToast } from 'store/toast/actions';

export const MatchLearnerPage = (): ReactElement => {
  const [isTryingAgain, setIsTryingAgain] = useState(false);
  const fireLoadEvent = useLoadEvent({ PageType: 'Registration Page' });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      popToast({
        ...successToastOptions,
        autoHideDuration: 3000,
        message: (
          <>
            Your account has been created successfully. Now we need some more information to attempt to link to your CME
            transcripts.
          </>
        ),
      }),
    );
  }, []);

  const updateOnFailure = useCallback((): void => setIsTryingAgain(true), []);

  useEffect(() => {
    fireLoadEvent({ RegistrationStep: !isTryingAgain ? 3 : 4 });
  }, [fireLoadEvent, isTryingAgain]);

  return (
    <section className={styles.section}>
      <h1 className={styles.eyebrow}>Create an Account</h1>
      <OnboardingMatchLearnerForm isTryingAgain={isTryingAgain} onFailure={updateOnFailure} />
    </section>
  );
};
