import { IToastOptions, PARSAction } from 'core/models';

export interface ToastState {
  toastOpen: boolean;
  toastOptions?: IToastOptions;
}

export const TOAST_OPEN = 'TOAST_OPEN';
export const TOAST_CLOSE = 'TOAST_CLOSE';

interface ToastOpen extends PARSAction<IToastOptions, typeof TOAST_OPEN> {
  type: typeof TOAST_OPEN;
  payload: IToastOptions;
}

interface ToastClose extends PARSAction<undefined, typeof TOAST_CLOSE> {
  type: typeof TOAST_CLOSE;
}

export type ToastActionTypes = ToastOpen | ToastClose;
