import { ReactElement } from 'react';

// Components
import { Button } from 'components';

// Core.
import { ButtonVariant } from 'core';

// Globals.
import background from 'globals/images/cmeCta.png';

// Hooks.
import { useClickEvent } from 'hooks';

// Styles
import styles from './Cta.module.scss';

export const Cta = (): ReactElement => {
  const fireEvent = useClickEvent();

  return (
    // Must have cmeCta image
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <h3>
        CME Passport is the all-in-one CME management tool built by ACCME, the national accrediting authority for
        continuing medical education.
      </h3>
      <Button
        component="a"
        href="https://www.accme.org/what-we-do"
        onClick={() => fireEvent({ EventAction: 'Links', EventLabel: 'Learn More' })}
        rel="noreferrer noopener"
        target="_blank"
        variant={ButtonVariant.Secondary}
      >
        Learn More
      </Button>
    </div>
  );
};
