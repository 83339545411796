/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Configuration } from 'core/configuration';
import { AnalyticsService } from './AnalyticsService';
import { TraceLevel } from '../core';
import { IEventTelemetry, IExceptionTelemetry } from '@microsoft/applicationinsights-web';

export abstract class LogService {
  public static log(payload: any, traceLevel: TraceLevel = TraceLevel.Off): void {
    const shouldSendToAppInsights = traceLevel >= Configuration.sendToAppInsightsThreshold;
    const shouldSendToToast = traceLevel >= Configuration.sendToToastThreshold;
    const shouldSendToConsole = traceLevel >= Configuration.sendToConsoleThreshold;

    if (shouldSendToAppInsights) LogService.sendToAppInsights(payload, traceLevel);
    if (shouldSendToToast) LogService.sendToToast(payload, traceLevel);
    if (shouldSendToConsole) LogService.sendToConsole(payload, traceLevel);
  }

  public static sendToAppInsights = (payload: IExceptionTelemetry & IEventTelemetry, traceLevel: TraceLevel): void => {
    switch (traceLevel) {
      case TraceLevel.Info:
      case TraceLevel.Verbose:
      case TraceLevel.Warning:
        AnalyticsService.trackEvent(payload);
        break;
      case TraceLevel.Error:
        AnalyticsService.trackException(payload);
        break;
    }
  };

  private static sendToToast(payload: any, _traceLevel?: TraceLevel) {
    // TODO connect to forthcoming ToastService
    alert(payload);
  }

  private static sendToConsole(payload: any, traceLevel: TraceLevel) {
    switch (traceLevel) {
      case TraceLevel.Info:
        console.info(payload);
        break;
      case TraceLevel.Verbose:
        console.debug(payload);
        break;
      case TraceLevel.Warning:
        console.warn(payload);
        break;
      case TraceLevel.Error:
        console.error(payload);
        break;
    }
  }
}
