import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components.
import { Button, Link } from 'components';

// Core.
import { ButtonVariant, IOnboardingRequest, LinkVariant } from 'core';

// Hooks.
import { useAuthLogin, useLoadEvent } from 'hooks';

// Store.
import { onboardingIsAbleToMatchSelector, onboardingRequestSelector } from 'store/onboarding/selectors';

// Styles.
import styles from './RegisterSuccessPage.module.scss';

export const RegisterSuccessPage = (): ReactElement => {
  const login = useAuthLogin();
  const fireLoadEvent = useLoadEvent({ LoginStatus: 'Registered', PageType: 'Registration Page', RegistrationStep: 5 });

  // Selectors
  const onboardingRequest: IOnboardingRequest = useSelector(onboardingRequestSelector);
  const isAbleToMatch: boolean = useSelector(onboardingIsAbleToMatchSelector);

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  return (
    <section className={styles.section}>
      <div className={styles.box}>
        <h1>You've successfully created an account! You can now login with your credentials.</h1>
        {!isAbleToMatch && <p>Although we were unable to find a match, we created an account for you.</p>}
        {isAbleToMatch && <p>We were able to find a match for you, {onboardingRequest.firstName}.</p>}
        <h2 className={styles.eyebrow}>Account Details</h2>
        <p>
          {onboardingRequest.firstName} {onboardingRequest.lastName} | DOB:{' '}
          {String(onboardingRequest.dobMonth).padStart(2, '0')}/{String(onboardingRequest.dobDate).padStart(2, '0')}
        </p>
        <p>{onboardingRequest.email}</p>
        {onboardingRequest.stateName && <p>Licensed in {onboardingRequest.stateName}</p>}
      </div>
      <footer>
        <Link className={styles.button} to="/activity/search" variant={LinkVariant.Secondary}>
          Find Accredited CME
        </Link>
        <Button className={styles.button} onClick={login} variant={ButtonVariant.Primary}>
          Log In
        </Button>
      </footer>
    </section>
  );
};
