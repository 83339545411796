export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Icon = 'icon',
  Label = 'label',
  Danger = 'danger',
  Ghost = 'ghost',
  Link = 'link',
  Hyperlink = 'link--hyperlink',
  Pill = 'pill',
  Filter = 'filter',
}

export enum TraceLevel {
  Off,
  Error,
  Warning,
  Info,
  Verbose,
}

export enum LinkVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Label = 'label',
  Hyperlink = 'link--hyperlink',
}

export enum ToastType {
  TOAST_WARNING = 'TOAST_WARNING',
  TOAST_ALERT = 'TOAST_ALERT',
  TOAST_INFO = 'TOAST_INFO',
  TOAST_SUCCESS = 'TOAST_SUCCESS',
}

export enum ToastLocationEnum {
  Top,
  Bottom,
}

export enum FacetTypeEnum {
  Array,
  Boolean,
  Range,
  Date,
  Text,
  Typeahead,
}

export enum StatusEnum {
  ACCEPTED = 'Accepted',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  DRAFT = 'Draft',
  NOT_PROCESSED = 'Not Processed',
  READY_TO_CLOSE = 'Ready to Close',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
}

export enum BreakpointsEnum {
  xxs = '321px',
  xs = '415px',
  sm = '768px',
  md = '992px',
  lg = '1024px',
  xl = '1280px',
  xxl = '1440px',
}
