export enum ProfileWizardEnum {
  CertifyingBoards = 'CertifyingBoards',
  StateBoards = 'StateBoards',
  NPI = 'NPI',
  Specialties = 'Specialties',
}

export interface IWizardProps {
  back?: VoidFunction;
  skip?: VoidFunction;
  next?: VoidFunction;
  submit?(): Promise<void>;
}
