// Libs
import { ReactElement } from 'react';

// Components
import { Button } from 'components/Button';

// Core
import { ButtonVariant } from 'core/enums';

// Styles
import styles from './ButtonRow.module.scss';

interface IProps {
  firstButtonText: string;
  isPrimaryDisabled?: boolean;
  isSecondaryDisabled?: boolean;
  isShowProgress?: boolean;
  onCancel?: VoidFunction;
  onClick?: VoidFunction;
  secondButtonText: string;
  type?: 'button' | 'reset' | 'submit';
}
export const ButtonRow = ({
  firstButtonText,
  isPrimaryDisabled,
  isSecondaryDisabled,
  isShowProgress,
  onCancel,
  onClick,
  secondButtonText,
  type = 'button',
}: IProps): ReactElement => {
  return (
    <div className={styles['button-row']}>
      <Button
        disabled={isPrimaryDisabled}
        onClick={onClick}
        isSubmitting={isShowProgress}
        variant={ButtonVariant.Secondary}
        type={type}
      >
        {firstButtonText}
      </Button>
      <Button disabled={isSecondaryDisabled} variant={ButtonVariant.Tertiary} onClick={onCancel}>
        {secondButtonText}
      </Button>
    </div>
  );
};
