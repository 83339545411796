// Libs
import { Grid } from '@material-ui/core';
import keys from 'lodash/keys';
import { Fragment, ReactElement } from 'react';

// Components.
import { BoardCredits } from './BoardCredits';
import { StateCredits } from './StateCredits';

// Core
import { CompletionSources, IDictionary, ILearnerCredit } from 'core';

// Misc
import { groupCreditsByCompletionSourceAndAbbreviation } from './util';

// Styles
import styles from '../TranscriptCard.module.scss';

interface ICreditsProps {
  credits: ILearnerCredit[];
}

export const Credits = ({ credits }: ICreditsProps): ReactElement => {
  const sourceGroups: IDictionary<IDictionary<ILearnerCredit[]>> = groupCreditsByCompletionSourceAndAbbreviation(
    credits,
  );

  return (
    <>
      {keys(sourceGroups).map((completionSource) => {
        const abbreviationGroups = sourceGroups[completionSource];
        return keys(abbreviationGroups).map((abbreviation, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <>
                <Grid item xs={1} />
                <Grid className={styles.divider} item xs={11} />
              </>
            )}
            {completionSource === CompletionSources.Board ? (
              <BoardCredits abbreviation={abbreviation} credits={abbreviationGroups[abbreviation]} />
            ) : (
              <StateCredits abbreviation={abbreviation} credits={abbreviationGroups[abbreviation]} />
            )}
          </Fragment>
        ));
      })}
    </>
  );
};
