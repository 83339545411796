import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Link } from 'components';
import { TranscriptCard } from 'components/TranscriptCard';

// Core.
import { LinkVariant } from 'core';

// Store.
import { getUserProfileRecentCME } from 'store/profile/actions';
import { userProfileRecentCMELoadingSelector, userProfileRecentCMESelector } from 'store/profile/selectors';

// Styles
import styles from './RecentCme.module.scss';
import { LoadingCards } from 'components/LoadingCards';

export const RecentCme = (): ReactElement => {
  const dispatch = useDispatch();
  const isLoadingRecentCME = useSelector(userProfileRecentCMELoadingSelector);
  const recentCme = useSelector(userProfileRecentCMESelector);

  useEffect(() => {
    dispatch(getUserProfileRecentCME());
  }, [dispatch]);

  return (
    <div className={styles['recent-completed-cme']}>
      <h4>
        Recently Completed CME
        <Link icon={KeyboardBackspaceRounded} to="/profile/transcripts" variant={LinkVariant.Tertiary}>
          See All
        </Link>
      </h4>
      {isLoadingRecentCME ? (
        <LoadingCards count={5} />
      ) : (
        recentCme?.results?.map((activity) => (
          <TranscriptCard key={activity?.document?.activityId} learnerActivity={activity?.document} />
        ))
      )}
    </div>
  );
};
