import { Reducer } from 'redux';

// Core.
import { CACHE_ACTIVITY_RECENT_SEARCHES, SORT_BY_RECENTLY_ADDED } from 'core';

// Services.
import { CacheService } from 'services';

// Types.
import {
  ActivityState,
  CLEAR_RECENT_ACTIVITY_SEARCHES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITY_KEYWORDS_SUCCESS,
  RESET_ACTIVITY_SEARCH,
  SET_RECENT_ACTIVITY_SEARCHES,
  UPDATE_ACTIVITY_SEARCH_REQUEST,
  UPDATE_ACTIVITY_SEARCH_STATE_PROPS,
} from './types';

const INITIAL_SEARCH_REQUEST = {
  sortAscending: false,
  sortBy: SORT_BY_RECENTLY_ADDED,
  top: 10,
};

const INITIAL_SEARCH_STATE_PROPS = {
  page: 1,
  selectedActivities: [],
};

const initialState: ActivityState = {
  isLoading: true,
  keywords: {},
  recentSearches: CacheService.get<string[]>(CACHE_ACTIVITY_RECENT_SEARCHES) || [],
  searchRequest: INITIAL_SEARCH_REQUEST,
  searchResponse: undefined,
  searchStateProps: INITIAL_SEARCH_STATE_PROPS,
};

export const activityReducer: Reducer<ActivityState> = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITIES:
      return { ...state, isLoading: true, searchRequest: action.payload };
    case GET_ACTIVITIES_SUCCESS:
      return { ...state, isLoading: false, searchResponse: action.payload };
    case GET_ACTIVITIES_FAILURE:
      return { ...state, isLoading: false };
    case GET_ACTIVITY_KEYWORDS_SUCCESS:
      return { ...state, keywords: action.payload };
    case CLEAR_RECENT_ACTIVITY_SEARCHES:
      return { ...state, recentSearches: [] };
    case SET_RECENT_ACTIVITY_SEARCHES:
      return { ...state, recentSearches: action.payload };
    case RESET_ACTIVITY_SEARCH:
      return {
        ...state,
        searchRequest: INITIAL_SEARCH_REQUEST,
        searchResponse: undefined,
        searchStateProps: INITIAL_SEARCH_STATE_PROPS,
      };
    case UPDATE_ACTIVITY_SEARCH_REQUEST:
      return { ...state, searchRequest: action.payload };
    case UPDATE_ACTIVITY_SEARCH_STATE_PROPS:
      return {
        ...state,
        searchStateProps: action.payload,
      };
    default:
      return state;
  }
};
