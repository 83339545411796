// Libs
import { KeyboardBackspaceRounded, Share } from '@material-ui/icons';
import { Grid, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { groupBy, keys, uniqueId } from 'lodash';

// Components
import { Button, Link, Rail, RailVariants, Summary } from 'components';
import { LoadingCards } from 'components/LoadingCards';
import { LearnerResultShare } from 'components/LearnerResultCard/LearnerResultShare';

// Core + Store
import {
  BreakpointsEnum,
  ButtonVariant,
  ILearnerActivity,
  IActivityCommercialSupportSource,
  ILearnerContentOutline,
  ILearnerCredit,
  ILearnerPracticeArea,
  LinkVariant,
  ILearnerMeasuredOutcome,
  ILearnerJointProvider,
  Providerships,
  IDictionary,
} from 'core';

// Globals.
import background from 'globals/images/ctaCreateAccount.png';

// Hooks.
import { useAuth, useClickEvent, useLoadEvent } from 'hooks';

// Store.
import { getActivities } from 'store/activity/actions';
import { isLoadingSelector, searchResultsSelector } from 'store/activity/selectors';
import { isRailOpenSelector } from 'store/rail/selectors';
import { closeRail, openRail } from 'store/rail/actions';

// Styles
import styles from './ActivityDetail.module.scss';

interface IParams {
  id: string;
}

const dateFormat = 'MM/DD/YYYY';
const captionTextClass = styles['caption-text'];
const detailContentClass = styles['detail-content'];
const detailSectionClass = styles['detail-section'];
const detailSnippetClass = styles['detail-snippet'];
const creditsListClass = styles['credits-text'];

export const ActivityDetail = (): ReactElement => {
  const [, { isAuthenticated }] = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams<IParams>();
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const fireEvent = useClickEvent();
  const isMdBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);
  const activityResults: ILearnerActivity[] = useSelector(searchResultsSelector);
  const isLoading: boolean = useSelector(isLoadingSelector);
  const isRailOpen: boolean = useSelector(isRailOpenSelector);
  const activityDetails: ILearnerActivity = useMemo(
    () => activityResults?.find((activity: ILearnerActivity): boolean => activity.id === id),
    [activityResults, id],
  );

  const measuredOutcomes: ILearnerMeasuredOutcome[] = activityDetails?.measuredOutcomes;
  const jointProviders: ILearnerJointProvider[] = activityDetails?.jointProviders;
  const title: string = activityDetails?.title;
  const providerName: string = activityDetails?.providerName || 'None';
  const activityUrl: string = activityDetails?.url || 'None';
  const description: string = activityDetails?.description || 'None';
  const registration: string = activityDetails?.registration || 'None';
  const participationFee: string = activityDetails?.participationFee || 'None';
  const isJointlyProvided: boolean = activityDetails?.providership === Providerships.JOINTLY_PROVIDED;
  const type: string = activityDetails?.type || 'None';
  const rawStartDate: string = activityDetails?.startDate;
  const rawEndDate: string = activityDetails?.endDate;
  const formattedStartDate: string = rawStartDate ? moment(rawStartDate).format(dateFormat) : '';
  const formattedEndDate: string = rawEndDate ? moment(rawEndDate).format(dateFormat) : '';
  const dates: string = rawStartDate && rawEndDate ? `${formattedStartDate} - ${formattedEndDate}` : 'None';
  const city: string = activityDetails?.city;
  const state: string = activityDetails?.state;
  const postalCode: string = activityDetails?.postalCode;
  const country: string = activityDetails?.country;
  const credits: ILearnerCredit[] = activityDetails?.creditTypes;
  const specialties: string[] = activityDetails?.practiceAreas?.map(({ name }: ILearnerPracticeArea) => name);

  const mocCredits = activityDetails?.moCCreditTypes;
  const moCCreditTypesByBoardAbbreviation: IDictionary<ILearnerCredit[]> = useMemo(
    () => groupBy(mocCredits, ({ boardAbbreviation }) => boardAbbreviation),
    [mocCredits],
  );
  const isRems = activityDetails?.isRems;
  const isMips = activityDetails?.isMips;
  const isCommercialSupport: boolean = activityDetails?.isCommercialSupport;
  const commercialSupportSources: IActivityCommercialSupportSource[] = activityDetails?.commercialSupportDetails;
  const contentOutlines: string[] = activityDetails?.contentOutlines?.map(({ name }: ILearnerContentOutline) => name);
  const hasMonetarySupport = commercialSupportSources?.some(({ inKindSupport }) => !inKindSupport);
  const hasInKindSupport = commercialSupportSources?.some(({ inKindSupport }) => inKindSupport);

  const commercialSupportString = `Yes${hasMonetarySupport ? ', Monetary' : ''}${
    hasInKindSupport ? ', In-Kind (non-monetary)' : ''
  }`;

  // TODO: Understand why this is firing twice for 2 different activities.
  const fireLoadEvent = useLoadEvent({ PageType: 'Activity Detail Page', ActivityTitle: title });

  useEffect(() => {
    if (!isLoading && !!activityDetails) {
      fireLoadEvent({ ActivityTitle: title, ActivityType: type, Provider: providerName });
    }
  }, [activityDetails, fireLoadEvent, isLoading, providerName, title, type]);

  useEffect(() => {
    if (activityDetails?.id !== id) {
      dispatch(getActivities({ legacyId: id }));
    }
  }, [activityDetails, dispatch, id]);

  const Loading = <LoadingCards count={5} />;

  const formatCreditNumber = (credits: number): string =>
    credits?.toLocaleString('en-US', { minimumFractionDigits: 2 }) || '0.00';

  const renderCreditTypes = (cmeCredits: ILearnerCredit[]): ReactElement[] => {
    if (!cmeCredits?.length) {
      return null;
    }
    return cmeCredits.map(
      ({ credits, name }: ILearnerCredit, idx: number): ReactElement => (
        <div key={idx} className={detailSnippetClass}>
          <div className={captionTextClass}>{name}</div>
          <p className={detailContentClass}>{`${formatCreditNumber(credits)} hours`}</p>
        </div>
      ),
    );
  };

  return (
    <>
      <Summary>
        {isLoading ? (
          Loading
        ) : (
          <>
            <div className="page-navigation">
              <Link
                icon={KeyboardBackspaceRounded}
                isIconPrepended
                to="/activity/search"
                variant={LinkVariant.Tertiary}
              >
                Activity Search
              </Link>
            </div>
            {!!activityDetails && (
              <>
                <h4 className={styles['detail-title']}>{title}</h4>
                {/* Render the sidebar for mobile */}
                {!isMdBreakpoint && (
                  <div className="summary-refiners">
                    <Button onClick={() => dispatch(openRail())} variant={ButtonVariant.Filter}>
                      Open aside
                    </Button>
                  </div>
                )}
                <section className={detailSectionClass}>
                  <h5>Details</h5>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Accredited Provider</div>
                    <p className={detailContentClass}>{providerName}</p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Activity Link</div>
                    <p className={detailContentClass}>
                      {activityDetails?.url ? (
                        <a
                          className={styles['activity-url']}
                          onClick={() => fireEvent({ EventAction: 'Activity Link', EventLabel: activityUrl })}
                          href={activityUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {activityUrl}
                        </a>
                      ) : (
                        activityUrl
                      )}
                    </p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>About this Activity</div>
                    <p className={detailContentClass}>{description}</p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Registration</div>
                    <p className={detailContentClass}>{registration}</p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Fee to Participate</div>
                    <p className={detailContentClass}>{participationFee}</p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Activity Type</div>
                    <p className={detailContentClass}>{type}</p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Start and End Dates</div>
                    <p className={detailContentClass}>{dates}</p>
                  </div>
                  {city && state && country ? (
                    <div className={detailSnippetClass}>
                      <div className={captionTextClass}>Location</div>
                      <p className={detailContentClass}>{`${city}, ${state} ${postalCode}${
                        country ? ` - ${country}` : null
                      }`}</p>
                    </div>
                  ) : null}
                  {renderCreditTypes(credits)}
                </section>
                <section className={detailSectionClass}>
                  <h5>Specialties</h5>
                  <div className={detailSnippetClass}>
                    {specialties?.length ? (
                      <ul>
                        {specialties.map(
                          (specialty: string, index: number): ReactElement => (
                            <li className={detailContentClass} key={index}>
                              {specialty}
                            </li>
                          ),
                        )}
                      </ul>
                    ) : (
                      <p className={detailContentClass}>None</p>
                    )}
                  </div>
                </section>
                <section className={detailSectionClass}>
                  <h5>Additional Credit Opportunities</h5>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Registered for MOC</div>
                    <p className={detailContentClass}>
                      {mocCredits?.length ? (
                        keys(moCCreditTypesByBoardAbbreviation).map((boardAbbreviation, boardIndex) => {
                          const mocCredits = moCCreditTypesByBoardAbbreviation[boardAbbreviation];
                          const { credits, units } = mocCredits[0] || {};
                          return (
                            <div key={boardIndex} className={styles.list}>
                              <span>
                                <abbr>
                                  {boardAbbreviation} - {credits} {units}; {boardAbbreviation} Credit Type(s):
                                </abbr>
                              </span>
                              <ul className={creditsListClass}>
                                {mocCredits?.map((creditType, creditTypeIndex) => (
                                  <li key={creditTypeIndex}>
                                    <span title={creditType.name}>{creditType.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          );
                        })
                      ) : (
                        <>No</>
                      )}
                    </p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>FDA REMS</div>
                    <p className={detailContentClass}>{isRems ? 'Yes' : 'No'}</p>
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Qualifies for MIPS</div>
                    <p className={detailContentClass}>{isMips ? 'Yes' : 'No'}</p>
                  </div>
                </section>
                <section className={detailSectionClass}>
                  <h5>Additional Details</h5>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Content Outlines</div>
                    {contentOutlines?.length
                      ? contentOutlines.map((content: string, idx: number) => (
                          <p key={idx} className={detailContentClass}>
                            {content}
                          </p>
                        ))
                      : 'None'}
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Providership</div>
                    {isJointlyProvided ? (
                      <ul>
                        {jointProviders?.length ? (
                          jointProviders?.map(({ name }: ILearnerJointProvider) => (
                            <li className={detailContentClass} key={uniqueId(name)}>
                              Jointly provided - {name}
                            </li>
                          ))
                        ) : (
                          <li className={detailContentClass} key={id}>
                            Jointly provided
                          </li>
                        )}
                      </ul>
                    ) : (
                      <p className={detailContentClass}>Directly provided</p>
                    )}
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Measured Outcomes</div>
                    {measuredOutcomes?.length
                      ? measuredOutcomes.map(({ name }: ILearnerMeasuredOutcome, idx: number) => (
                          <p key={idx} className={detailContentClass}>
                            {name}
                          </p>
                        ))
                      : 'None'}
                  </div>
                  <div className={detailSnippetClass}>
                    <div className={captionTextClass}>Commercial Support</div>
                    <p className={detailContentClass}>{isCommercialSupport ? commercialSupportString : 'None'}</p>
                  </div>
                </section>
              </>
            )}
          </>
        )}
        <Grid container direction="row-reverse">
          <Grid item>
            <Button component={RouterLink} to="/activity/search" variant={ButtonVariant.Primary}>
              Done
            </Button>
          </Grid>
        </Grid>
      </Summary>
      <Rail handleClose={() => dispatch(closeRail())} isOpen={isRailOpen} variant={RailVariants.Partial}>
        <div className={styles.aside}>
          <div className={styles['cta-share']}>
            {isSharing && <LearnerResultShare activity={activityDetails} handleClose={() => setIsSharing(false)} />}
            <Button
              onClick={() => {
                fireEvent({ EventAction: 'Share' });
                setIsSharing(!isSharing);
              }}
              variant={ButtonVariant.Label}
            >
              <Share />
              Share this Activity
            </Button>
          </div>
          {!isAuthenticated && (
            <>
              <hr className={styles['aside-divider']} />
              <div className={styles['cta-image']} style={{ backgroundImage: `url(${background})` }} />
              <p>Create an account to track and manage your CME transcripts</p>
              <Button
                className={styles.button}
                component={Link}
                onClick={() => fireEvent({ EventAction: 'Registration', EventLabel: '/register' })}
                to="/register"
                variant={ButtonVariant.Secondary}
              >
                Get Started
              </Button>
            </>
          )}
        </div>
      </Rail>
    </>
  );
};
