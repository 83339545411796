import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { ReactElement, useCallback } from 'react';

// Components.
import { Link } from 'components';

// Core.
import { LinkVariant } from 'core';

// Hooks.
import { useClickEvent } from 'hooks';

// Styles.
import styles from './SearchContext.module.scss';

// Types.
import { SearchTypes } from '..';

interface ISearchContent {
  onChange(searchType: SearchTypes): void;
  searchType: SearchTypes;
}

export const SearchContext = ({ onChange, searchType }: ISearchContent): ReactElement => {
  const fireEvent = useClickEvent();

  const handleChange = useCallback(
    (e): void => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={styles.container}>
      <div className={styles.selector}>
        {/* toggles search paramaters */}
        <input
          defaultChecked={searchType === SearchTypes.Specialty}
          id="specialtySearchRadio"
          type="radio"
          name="searchContext"
          onChange={handleChange}
          value={SearchTypes.Specialty}
        />
        <label htmlFor="specialtySearchRadio">Specialty Search</label>
        <input
          defaultChecked={searchType === SearchTypes.Keyword}
          id="keywordSearchRadio"
          type="radio"
          name="searchContext"
          onChange={handleChange}
          value={SearchTypes.Keyword}
        />
        <label htmlFor="keywordSearchRadio">Keyword Search</label>
        <a
          href="https://content.cmepassport.org/"
          rel="noreferrer noopener"
          target="_blank"
          type="radio"
          id="stateCmeRequirementsLink"
        >
          <label htmlFor="stateCmeRequirementsLink">State CME Requirements</label>
        </a>
      </div>
      <div className={styles.buttons}>
        {/* Link to CME Finder Activity Search */}
        <Link
          icon={KeyboardBackspaceRounded}
          onClick={() => fireEvent({ EventAction: 'Links', EventLabel: 'Browse All Activities' })}
          to="/activity/search"
          variant={LinkVariant.Tertiary}
        >
          Browse All Activities
        </Link>
      </div>
    </div>
  );
};
