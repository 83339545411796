/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
  IPageViewTelemetry,
  ITelemetryItem,
  Telemetry,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import History from './History';
import { Configuration } from 'core/configuration';

let authDomain;
try {
  authDomain = new URL(Configuration.azureMsalAuthority).host;
} catch {}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    correlationHeaderExcludedDomains: ['www.google-analytics.com', authDomain],
    enableCorsCorrelation: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: History },
    },
    extensions: [reactPlugin],
    instrumentationKey: Configuration.applicationInsightsKey,
  },
});

export abstract class AnalyticsService {
  /**
   * Initialize analytics.
   */
  public static initialize(): void {
    AnalyticsService.doSafeAppInisghtsAction(() => {
      appInsights.loadAppInsights();
      if (appInsights.context?.application) {
        appInsights.context.application.ver = Configuration.cmeFinderUiVersion;
      }
      appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => this.initializeTelemetry(envelope));
    });
  }

  /**
   * Set user.
   */
  public static setUser(userName: string): void {
    AnalyticsService.doSafeAppInisghtsAction(() => {
      if (userName !== null && userName !== undefined) {
        appInsights.setAuthenticatedUserContext(userName);
      }
    });
  }

  /**
   * Track a page view.
   * @param event The event to be tracked.
   */
  public static trackPageView(pageView?: IPageViewTelemetry): void {
    AnalyticsService.doSafeAppInisghtsAction(() => {
      const pageTitle: string =
        pageView && pageView.name
          ? pageView.name
          : window.document && window.document.title
          ? window.document.title
          : '';
      const pageUrl: string =
        pageView && pageView.uri
          ? pageView.uri.toLowerCase()
          : window.location && window.location.href
          ? window.location.href.toLowerCase()
          : '';

      appInsights.trackPageView({
        name: pageTitle,
        properties: pageView && pageView.properties,
        uri: pageUrl,
      } as IPageViewTelemetry);
    });
  }

  /**
   * Track an event.
   * @param event The event to be tracked.
   */
  public static trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: any }): void {
    AnalyticsService.doSafeAppInisghtsAction(() => {
      appInsights.trackEvent({ name: event.name } as IEventTelemetry, customProperties);
    });
  }

  /**
   * Log an exception you have caught.
   * @param exception Error Object(s)
   */
  public static trackException(exception: IExceptionTelemetry): void {
    AnalyticsService.doSafeAppInisghtsAction(() => {
      appInsights.trackException(exception);
    });
  }

  /**
   * Immediately send all queued telemetry.
   */
  public static flush(): void {
    AnalyticsService.doSafeAppInisghtsAction(() => {
      appInsights.flush();
    });
  }

  /**
   * Initialize the telemetry for an envelope
   * @param telemetryItem The destination telemetry item.
   * @param userData The current user.
   */
  public static initializeTelemetry(telemetryItem: ITelemetryItem): void {
    telemetryItem.tags = telemetryItem.tags || [];
    telemetryItem.tags['ai.cloud.role'] = 'CME Finder UI';

    switch (telemetryItem.name) {
      case Telemetry.PageView.envelopeType:
        telemetryItem.tags.push({
          'ai.operation.name': window.location.pathname.toLowerCase(),
        });
        break;
    }
  }

  private static doSafeAppInisghtsAction(action: () => void): void {
    try {
      action();
    } catch (ex) {
      console.warn(ex);
      appInsights.trackException(ex);
    }
  }
}
