import { IContextualMenuOptionProps } from 'components';

// Types
interface IOption {
  options: IContextualMenuOptionProps[];
}

interface IArgs {
  onExportAllLearners(): void;
  onExportFilteredLearners(): void;
}

export const exportMenuItems = ({ onExportAllLearners, onExportFilteredLearners }: IArgs): IOption => ({
  options: [
    {
      icon: 'get_app',
      name: 'Export All Learners',
      onSelectAction: onExportAllLearners,
      selected: true,
    },
    {
      icon: 'get_app',
      name: 'Export Filtered Learners',
      onSelectAction: onExportFilteredLearners,
    },
  ],
});
