import { ReactElement } from 'react';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

// Components
import { Button } from 'components/Button';

// Styles
import styles from '../Wizard.module.scss';

// Core + Store
import { ButtonVariant } from 'core';

// Types
import { IWizardProps } from '../types';

export const WizardButtonRow = (props: IWizardProps): ReactElement => {
  const { back, next, skip } = props;

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        {back && (
          <Button className={styles['wizard-button']} variant={ButtonVariant.Tertiary} onClick={back}>
            <KeyboardBackspaceRounded /> Back
          </Button>
        )}
      </Grid>
      <Grid item>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            {skip && (
              <Button className={styles['wizard-button']} variant={ButtonVariant.Secondary} onClick={skip}>
                Skip
              </Button>
            )}
          </Grid>
          <Grid item>
            {next && (
              <Button className={styles['wizard-button']} variant={ButtonVariant.Primary} onClick={next}>
                Next
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
