import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { CheckboxProps, RadioGroupProps, RadioProps, TextFieldProps } from '@material-ui/core';
import { ReactElement } from 'react';
import { FieldInputProps } from 'formik';

export type FormikTextFieldProps = {
  formikKey: string;
} & TextFieldProps;

export type FormikInputFieldProps = {
  formikKey: string;
  label?: string;
  readOnly?: boolean;
  noOffset?: boolean;
  placeholder?: string;
  startIcon?: ReactElement;
} & TextFieldProps;

export type FormikRadioFieldProps = {
  label?: string;
  formikKey: string;
} & RadioProps;

export type FormikRadioGroupProps = {
  formikKey: string;
} & RadioGroupProps;

export type FormikRadioButtonProps = {
  className?: string;
  formikKey: string;
  label?: string;
  value?: boolean | string | readonly string[];
};

// Types
export enum TimesOfDay {
  Midnight = 'midnight',
  Noon = 'noon',
  EndOfDay = 'endOfDay',
}
type TimeOfDay = 'midnight' | 'noon' | 'endOfDay';

export type FormikDatePickerProps = {
  field: FieldInputProps<Date>;
  format?: string;
  formikKeyDate?: string;
  formikKeyMonth?: string;
  formikKeyYear?: string;
  label?: string;
  onChange?: (name: string, date: string | number) => void;
  required?: boolean;
  timeOfDay?: TimeOfDay;
  views?: Array<'year' | 'date' | 'month'>;
} & Omit<KeyboardDatePickerProps, 'onChange' | 'value' | 'views'>;

export type FormikCheckboxFieldProps = {
  formikKey: string;
} & CheckboxProps;
