import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components.
import { OnboardingVerifyCodeForm } from 'components';

// Hooks.
import { useLoadEvent } from 'hooks';

// Store.
import { onboardingRequestSelector } from 'store/onboarding/selectors';

// Styles.
import styles from './VerifyCodePage.module.scss';

export const VerifyCodePage = (): ReactElement => {
  const { email } = useSelector(onboardingRequestSelector);
  const fireLoadEvent = useLoadEvent({ PageType: 'Registration Page', RegistrationStep: 2 });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  return (
    <section className={styles.section}>
      <header>
        <h1 className={styles.eyebrow}>Create an Account</h1>
        <p className={styles.subheading}>We've sent a confirmation email with a code to {email}</p>
      </header>
      <OnboardingVerifyCodeForm />
    </section>
  );
};
