/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, MenuItem, FormHelperText, SelectProps } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import { FocusEvent, ReactElement } from 'react';

// Styles.
import styles from './Dropdown.module.scss';

export interface IDropdownProps extends SelectProps {
  disabledItemKeys?: string[];
  error?: boolean;
  helperText?: string;
  idProp?: string;
  isDisabled?: boolean;
  items?: any[];
  label?: string;
  labelProp?: string;
  name?: string;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (event, item) => void;
  placeholder?: string;
  required?: boolean;
  value?: string;
}

export const Dropdown = (props: IDropdownProps): ReactElement => {
  const {
    disabledItemKeys,
    error,
    helperText,
    isDisabled,
    items,
    label,
    labelProp,
    idProp,
    required,
    placeholder,
    name,
    onBlur,
    onChange,
    value,
    ...selectProps
  } = props;
  const idKey = idProp || 'id';
  const labelKey = labelProp || 'name';

  return (
    <div>
      <Select
        aria-required={required}
        className={styles.select}
        disabled={isDisabled}
        error={error}
        IconComponent={ExpandMoreRounded}
        label={label}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        variant="outlined"
        {...selectProps}
      >
        {placeholder && (
          <MenuItem disabled value={placeholder}>
            {placeholder}
          </MenuItem>
        )}
        {items?.map((item) => {
          const key: string = item?.[idKey];

          return (
            <MenuItem disabled={disabledItemKeys?.includes(key)} key={key} value={key}>
              {item?.[labelKey]}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && (
        <FormHelperText error={error} variant="outlined">
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};
