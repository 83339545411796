import { InteractionStatus } from '@azure/msal-browser';
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { CACHE_ACCESS_TOKEN, CACHE_IMPERSONATION_ACCESS_TOKEN, Configuration } from 'core';
import { useMemo } from 'react';
import { CacheService } from 'services';
import moment from 'moment';
import { AnalyticsService } from 'services/AnalyticsService';

// we will renew the token slightly early to account for clock skew
const gracePeriodInSeconds = 5 * 60;

interface IAuthStatus {
  isAuthenticated: boolean;
  isLoaded: boolean;
}

// this will be re-assigned by `useAuth` calls which will have proper context to be able to get a fresh token if necessary
export let getOrAcquireAccessToken = async (): Promise<string> => {
  const impersonationToken = CacheService.get<string>(CACHE_IMPERSONATION_ACCESS_TOKEN);
  if (impersonationToken) {
    return `BearerPars ${impersonationToken}`;
  } else {
    const accessToken = CacheService.get<AuthenticationResult>(CACHE_ACCESS_TOKEN)?.accessToken;
    if (accessToken) {
      return `Bearer ${accessToken}`;
    }
  }

  return null;
};

export const useAuth = (): [AccountInfo, IAuthStatus, VoidFunction] => {
  const { accounts, inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isLoaded = useMemo(
    () => inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect,
    [inProgress],
  );
  const account = useMemo(() => accounts?.[0] as AccountInfo, [accounts]);

  const getAccessTokenValue = async (): Promise<string> => {
    const impersonationToken = CacheService.get<string>(CACHE_IMPERSONATION_ACCESS_TOKEN);
    if (impersonationToken) {
      return `BearerPars ${impersonationToken}`;
    } else {
      const currentToken = CacheService.get<AuthenticationResult>(CACHE_ACCESS_TOKEN);
      // consider a token expired if it'll expire within 30 seconds
      const isTokenExpired = moment().add(gracePeriodInSeconds, 'second').isAfter(currentToken?.expiresOn);

      // If you're logged in and
      //   - you don't have a token
      //   - or
      //   - your token is expired
      // then try to set a new token into cache.
      if (isAuthenticated && (!currentToken || isTokenExpired)) {
        try {
          const authResult = await instance.acquireTokenSilent({
            account,
            scopes: ['openid', Configuration.azureMsalScope],
          });

          CacheService.set(CACHE_ACCESS_TOKEN, authResult);
          return `Bearer ${authResult.accessToken}`;
        } catch (error) {
          AnalyticsService.trackException(error);
          throw error;
        }
      }

      return currentToken ? `Bearer ${currentToken.accessToken}` : null;
    }
  };

  const getAccessToken = async (): Promise<void> => {
    try {
      getAccessTokenValue();
    } catch (error) {
      // already logged to AppInsights
      console.error('Token Error:', error);
    }
  };

  getOrAcquireAccessToken = getAccessTokenValue;
  const impersonationToken = CacheService.get<string>(CACHE_IMPERSONATION_ACCESS_TOKEN);

  return impersonationToken
    ? [
        {} as AccountInfo,
        {
          // return true for `isAuthenticated` and `isLoaded`, the server will reject if it detects an invalid token
          isAuthenticated: true,
          isLoaded: true,
        },
        getAccessToken,
      ]
    : [
        account,
        {
          isAuthenticated,
          isLoaded,
        },
        getAccessToken,
      ];
};
