import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';

// Components.
import { Dropdown, IDropdownProps } from 'components';

// Styles
import styles from '../FormikFields.module.scss';

interface IFormikDropdown extends IDropdownProps {
  disabledItemKeys?: string[];
  formikKey?: string;
  formikKeyLabel?: string;
  label?: string;
  required?: boolean;
}

export const FormikDropdown = (props: IFormikDropdown): ReactElement => {
  const { disabledItemKeys, formikKey, formikKeyLabel, label, required, ...dropdownProps } = props;
  const { dirty, errors, setFieldValue, touched, validateForm, values } = useFormikContext();

  // Get helper text based on formikKeys.
  const helperText = useMemo(() => get(errors, formikKey) || get(errors, formikKeyLabel), [
    errors,
    formikKey,
    formikKeyLabel,
  ]);

  // Get touched state based on formikKeys.
  const isTouched = useMemo(() => get(touched, formikKey, false) || get(touched, formikKeyLabel, false), [
    formikKey,
    formikKeyLabel,
    touched,
  ]);

  // Get value based on formikKeys.
  const value = useMemo(() => get(values, formikKey), [formikKey, values]);

  // Set field values to set based on formikKeys.
  const handleChange = useCallback(
    (_, child) => {
      if (formikKey) setFieldValue(formikKey, child?.props?.value);
      if (formikKeyLabel) setFieldValue(formikKeyLabel, child?.props?.children);
    },
    [formikKey, formikKeyLabel, setFieldValue],
  );

  // Make sure we re-validate form when value changes.
  useEffect(() => {
    if (dirty) validateForm();
  }, [dirty, validateForm, value]);

  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label} htmlFor={dropdownProps.name}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <Dropdown
        {...dropdownProps}
        disabledItemKeys={disabledItemKeys}
        error={isTouched && !!helperText}
        helperText={helperText}
        onChange={handleChange}
        required={required}
        value={value}
      />
    </div>
  );
};
