import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import { EmojiObjectsRounded } from '@material-ui/icons';
import classNames from 'classnames';

// Components
import { AccountSettings, Rail, Profile, Summary, TranscriptFacets, ViewTranscripts, CustomTooltip } from 'components';

// Hooks
import { useAuth } from 'hooks';

// Store
import { userProfileFullNameSelector, userProfileSelector } from 'store/profile/selectors';
import { IUserProfile } from 'core';
import { closeRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';

// Styles.
import styles from './ProfilePage.module.scss';

// Types
import { INITIAL_TRANSCRIPTS_FACETS } from 'components/Facets/TranscriptFacets/constants';

const tooltipText =
  'No transcripts listed? Transcripts will only be displayed if we can match you to a participating certifying or licensing board in the United States, you have given permission to the accredited CME provider, and they have reported your completion.';
const tooltipLabel = 'View My Transcripts tooltip label.';

export const ProfilePage = (): ReactElement => {
  useAuth();
  const dispatch = useDispatch();
  const isTranscripts: boolean = useLocation()?.pathname?.includes('transcripts');

  // Selectors
  const userProfile: IUserProfile = useSelector(userProfileSelector);
  const fullName: string = useSelector(userProfileFullNameSelector);
  const isRailOpen: boolean = useSelector(isRailOpenSelector);

  const isClaimed: boolean = userProfile?.isClaimed;

  const onCloseRail = () => dispatch(closeRail());

  return (
    <>
      <Rail handleClose={onCloseRail} isOpen={isRailOpen}>
        <h5>{fullName}</h5>
        <nav className={styles.navigation}>
          <ul>
            <li>
              {/* manually setting selected here for styling */}
              <NavLink
                activeClassName={styles.selected}
                className={styles.link}
                exact
                onClick={onCloseRail}
                to="/profile"
              >
                Home
              </NavLink>
            </li>
            <li>
              {isClaimed ? (
                <NavLink
                  activeClassName={styles.selected}
                  className={styles.link}
                  exact
                  onClick={onCloseRail}
                  to="/profile/transcripts"
                >
                  View My Transcripts
                </NavLink>
              ) : (
                <>
                  <span className={classNames(styles.link, styles.disabled)}>View My Transcripts</span>{' '}
                  <CustomTooltip iconTooltip tooltipText={tooltipText}>
                    {tooltipLabel}
                  </CustomTooltip>
                </>
              )}
            </li>
            <li>
              <NavLink
                activeClassName={styles.selected}
                className={styles.link}
                exact
                onClick={onCloseRail}
                to="/profile/account-settings"
              >
                Account Settings
              </NavLink>
            </li>
          </ul>
        </nav>
        {isTranscripts ? (
          <TranscriptFacets handleClose={onCloseRail} initialValues={INITIAL_TRANSCRIPTS_FACETS} />
        ) : (
          <div className={styles.cta}>
            <EmojiObjectsRounded />
            <p>Do you have an idea for a feature you would like to see? Please send your idea to:</p>
            <a href="mailto:info@cmepassport.org">info@cmepassport.org</a>
          </div>
        )}
      </Rail>
      <Summary hasExploreMore>
        <Switch>
          <Route component={Profile} exact path="/profile" />
          <Route component={AccountSettings} exact path="/profile/account-settings" />
          {isClaimed ? (
            <Route component={ViewTranscripts} exact path="/profile/transcripts" />
          ) : (
            <Redirect to="/profile" />
          )}
        </Switch>
      </Summary>
    </>
  );
};
