// Libs
import { ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormikProvider, useFormik } from 'formik';
import { Grid } from '@material-ui/core';
import moment from 'moment';

// Components
import { ButtonRow } from 'components/ButtonRow';
import { FormikDatePicker, FormikInputField, FormikDropdown } from 'components/forms';

// Store
import { updateUserProfile } from 'store/profile/actions';
import { userProfileSelector } from 'store/profile/selectors';
import { editGeneralValidation } from './edit-general-validation';
import { useTaxonomyMedicalSchools } from 'hooks';

interface IProps {
  onClose: VoidFunction;
}

export const EditGeneral = ({ onClose }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const userProfile = useSelector(userProfileSelector);
  const medicalSchools = useTaxonomyMedicalSchools();
  const initialValues = useMemo(() => {
    const { dobDate, dobMonth, ...rest } = userProfile;
    const dateOfBirth =
      dobMonth >= 1 && dobDate >= 1 && moment({ day: dobDate, month: dobMonth - 1, year: 2020 }).toDate();
    return { dob: dateOfBirth, ...rest };
  }, [userProfile]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values): Promise<void> => {
      const { dob, ...rest } = values;
      const dobMoment = moment(dob);
      const dobDate = dobMoment.date();
      const dobMonth = dobMoment.month() + 1;
      await dispatch(updateUserProfile({ dobDate, dobMonth, ...rest }));
      onClose();
    },
    validationSchema: editGeneralValidation,
  });

  const { handleSubmit, isSubmitting, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="firstName" label="First Name" required />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="middleName" label="Middle Name" />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="lastName" label="Last Name" required />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field name="dob">
              {({ field }) => (
                <FormikDatePicker
                  field={field}
                  format="MM/DD"
                  formikKeyDate="dob"
                  label="Date of Birth"
                  openTo="date"
                  placeholder="MM/DD"
                  required
                  timeOfDay="midnight"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="alternateFirstName" label="Alternate First Name" />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="alternateLastName" label="Alternate Last Name" />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormikInputField formikKey="zipCode" label="Home Zip Code" />
          </Grid>
          <Grid item xs={12} md={5}>
            <FormikDropdown
              formikKey="medicalSchoolId"
              label="Medical School"
              idProp="id"
              items={medicalSchools}
              labelProp="name"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikInputField formikKey="phoneNumber" label="Phone Number" />
          </Grid>
        </Grid>
        <ButtonRow
          firstButtonText="Save"
          isPrimaryDisabled={isSubmitting || !isValid || !dirty}
          isSecondaryDisabled={isSubmitting}
          isShowProgress={isSubmitting}
          onCancel={onClose}
          secondButtonText="Cancel"
          type="submit"
        />
      </form>
    </FormikProvider>
  );
};
