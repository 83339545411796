import { Reducer } from 'redux';

// Core.
import { CACHE_COOKIE_PREFERENCE } from 'core';

// Services.
import { CacheService } from 'services';

// Types.
import { DISMISS_COOKIES, PreferenceState } from './types';

const cookiesAccepted = CacheService.get(CACHE_COOKIE_PREFERENCE);

export const initialState: PreferenceState = {
  cookiesAccepted: Boolean(cookiesAccepted === 'true'),
};

export const preferencesReducer: Reducer<PreferenceState> = (state = initialState, action): PreferenceState => {
  switch (action.type) {
    case DISMISS_COOKIES: {
      CacheService.set(CACHE_COOKIE_PREFERENCE, 'true');
      return { cookiesAccepted: true };
    }
    default:
      return state;
  }
};
