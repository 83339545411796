import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import keys from 'lodash/keys';

// Components.
import { Wizard } from './Wizard';

// Core
import { IUserProfile, IProfileCertifyingBoard, IProfileStateBoard } from 'core';

// Store
import { onCompleteWizard, updateUserProfile } from 'store/profile/actions';
import { userProfileSelector } from 'store/profile/selectors';

// Styles.
import styles from './index.module.scss';

export const ProfileOnBoarding = (): ReactElement => {
  const dispatch = useDispatch();
  const userProfile: IUserProfile = useSelector(userProfileSelector);

  const formik = useFormik<IUserProfile>({
    initialValues: {
      certifyingBoards: userProfile?.certifyingBoards || [{} as IProfileCertifyingBoard],
      npi: userProfile?.npi,
      specialtyIds: userProfile?.specialtyIds || [],
      stateBoards: userProfile?.stateBoards || [{} as IProfileStateBoard],
    },
    onSubmit: async (values: IUserProfile): Promise<void> => {
      const { certifyingBoards, specialtyIds, stateBoards } = values;
      const submitValues: IUserProfile = {
        certifyingBoards: keys(certifyingBoards)?.length ? certifyingBoards : undefined,
        specialtyIds,
        stateBoards: keys(stateBoards)?.length ? stateBoards : undefined,
      };

      const error = await dispatch(updateUserProfile(submitValues));

      // We only show the wizard of profile/home if the `hasCompletedWizard` is false. If it is false and the user
      // makes a submit, we call another endpoint to update that flag on the backend.
      if (!error) {
        dispatch(onCompleteWizard());
      }
    },
  });

  return (
    <div className={styles['profile-onboarding']}>
      <FormikProvider value={formik}>
        <Wizard />
      </FormikProvider>
    </div>
  );
};
