import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

// Core.
import { ITaxonomyTerm, TAXONOMY_FAQ_ROOT_ID } from 'core';

// Store.
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { faqTermsSelector } from 'store/taxonomy/selectors';

export const useTaxonomyFaq = (metadataText?: string): ITaxonomyTerm[] => {
  const dispatch = useDispatch();
  const faqTerms = useSelector(faqTermsSelector);
  const filteredFaqTerms = useMemo(() => faqTerms?.filter((term) => term.metadataText === metadataText), [
    faqTerms,
    metadataText,
  ]);

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_FAQ_ROOT_ID));
  }, [dispatch]);

  return filteredFaqTerms;
};
