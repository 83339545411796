import { ThunkAction } from 'redux-thunk';

// Services.
import { TaxonomyService } from 'services';

// Core.
import { ITaxonomyTerm, PARSAction } from 'core';

// Store.
import { AppState } from 'store';

// Utils
import { handleServerError } from 'globals/utils/handleServerError';

// Types.
import {
  GET_TAXONOMY_TERM_BY_ID,
  GET_TAXONOMY_TERM_BY_ID_SUCCESS,
  GET_TAXONOMY_TERM_BY_ID_FAILURE,
  TaxonomyTerms,
} from './types';

export const getTaxonomyTermByIdAction = (id: string): PARSAction<{ id: string }, typeof GET_TAXONOMY_TERM_BY_ID> => {
  return {
    payload: { id },
    type: GET_TAXONOMY_TERM_BY_ID,
  };
};

export const getTaxonomyTermByIdSuccessAction = (
  payload: TaxonomyTerms,
): PARSAction<TaxonomyTerms, typeof GET_TAXONOMY_TERM_BY_ID_SUCCESS> => {
  return {
    payload,
    type: GET_TAXONOMY_TERM_BY_ID_SUCCESS,
  };
};

export const getTaxonomyTermByIdFailureAction = (
  payload: Error,
): PARSAction<Error, typeof GET_TAXONOMY_TERM_BY_ID_FAILURE> => {
  return {
    payload,
    type: GET_TAXONOMY_TERM_BY_ID_FAILURE,
  };
};

export const getTaxonomyTermById = (id: string): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
) => {
  await dispatch(getTaxonomyTermByIdAction(id));
  try {
    const taxonomyService = new TaxonomyService();
    const term: ITaxonomyTerm = await taxonomyService.getByIdAsync(id);
    const result: { [id: string]: ITaxonomyTerm } = {};
    result[id] = term;
    await dispatch(getTaxonomyTermByIdSuccessAction(result));
  } catch (error) {
    handleServerError({ error, thunkName: 'getTaxonomyTermById' });
    await dispatch(getTaxonomyTermByIdFailureAction(error));
  }
};
