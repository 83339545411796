import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Core.
import { ITaxonomyTerm, TAXONOMY_FOOTER_ID } from 'core';

// Store.
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { footerTermsSelector } from 'store/taxonomy/selectors';

export const useTaxonomyFooter = (): ITaxonomyTerm[] => {
  const dispatch = useDispatch();
  const footer = useSelector(footerTermsSelector);

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_FOOTER_ID));
  }, [dispatch]);

  return footer;
};
