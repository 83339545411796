import { createSelector } from 'reselect';

// Core.
import {
  IActivityKeywords,
  IActivitySearchRequest,
  IActivitySearchResult,
  IActivitySearchResponse,
  IActivitySearchStateProps,
  Facet,
} from 'core';

// Store.
import { AppState } from 'store';

export const activityKeywordsSelector = ({ activity }: AppState): IActivityKeywords => activity.keywords;
export const recentSearchesSelector = ({ activity }: AppState): string[] => activity.recentSearches.slice().reverse();
export const isLoadingSelector = ({ activity }: AppState): boolean => activity.isLoading;
export const searchRequestSelector = ({ activity }: AppState): IActivitySearchRequest => activity.searchRequest;
export const searchResponseSelector = ({ activity }: AppState): IActivitySearchResponse => activity.searchResponse;

export const searchFacetsSelector = createSelector(
  searchResponseSelector,
  (searchResponse: IActivitySearchResponse) => searchResponse?.facets,
);
export const searchFacetsJointProviderSelector = createSelector(
  searchFacetsSelector,
  (facet: Facet) => facet?.['JointProviders/Name'],
);

export const searchFacetsStateSelector = createSelector(searchFacetsSelector, (facet: Facet) => facet?.State);
export const searchFacetsCountrySelector = createSelector(searchFacetsSelector, (facet: Facet) => facet?.Country);
export const searchFacetsProviderNameSelector = createSelector(
  searchFacetsSelector,
  (facet: Facet) => facet?.ProviderName,
);

export const searchResultsSelector = createSelector(searchResponseSelector, (searchResponse: IActivitySearchResponse) =>
  searchResponse?.results?.map(({ document }: IActivitySearchResult) => document),
);

export const searchTotalCountSelector = createSelector(
  searchResponseSelector,
  (searchResponse: IActivitySearchResponse) => searchResponse?.totalCount,
);

export const searchStatePropsSelector = ({ activity }: AppState): IActivitySearchStateProps =>
  activity?.searchStateProps;

export const activitySearchCurrentPageSelector = createSelector(
  searchStatePropsSelector,
  (state: IActivitySearchStateProps) => state.page,
);
