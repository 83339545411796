/* eslint-disable @typescript-eslint/no-use-before-define */
import { infoToastOptions, errorToastOptions, successToastOptions } from '../toast/constants';
import { ThunkAction } from 'redux-thunk';

// Core.
import {
  CACHE_ACTIVITY_RECENT_SEARCHES,
  CACHE_ACTIVITY_RECENT_SEARCHES_MAX,
  DEFAULT_RESULTS_PER_PAGE,
  IActivityKeywords,
  IActivitySearchRequest,
  IActivitySearchResponse,
  IActivitySearchStateProps,
  PARSAction,
} from 'core';

// Services.
import { ActivityService, CacheService } from 'services';

// Store.
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';

// Utils
import { handleServerError } from 'globals/utils/handleServerError';

// Types.
import {
  CLEAR_RECENT_ACTIVITY_SEARCHES,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITY_KEYWORDS_SUCCESS,
  RESET_ACTIVITY_SEARCH,
  SET_RECENT_ACTIVITY_SEARCHES,
  UPDATE_ACTIVITY_SEARCH_REQUEST,
  UPDATE_ACTIVITY_SEARCH_STATE_PROPS,
} from './types';

export const getActivitiesAction = (searchRequest: IActivitySearchRequest): PARSAction<IActivitySearchRequest> => ({
  payload: searchRequest,
  type: GET_ACTIVITIES,
});

export const getActivitiesSuccessAction = (
  activities: IActivitySearchResponse,
): PARSAction<IActivitySearchResponse> => ({
  payload: activities,
  type: GET_ACTIVITIES_SUCCESS,
});

export const getActivitiesFailureAction = (error: Error): PARSAction<Error> => ({
  payload: error,
  type: GET_ACTIVITIES_FAILURE,
});

export const updateSearchStatePropsAction = (
  props: IActivitySearchStateProps,
): PARSAction<IActivitySearchStateProps, typeof UPDATE_ACTIVITY_SEARCH_STATE_PROPS> => ({
  payload: props,
  type: UPDATE_ACTIVITY_SEARCH_STATE_PROPS,
});

export const getActivityKeywordsSuccess = (
  keywords: IActivityKeywords,
): PARSAction<IActivityKeywords, typeof GET_ACTIVITY_KEYWORDS_SUCCESS> => ({
  payload: keywords,
  type: GET_ACTIVITY_KEYWORDS_SUCCESS,
});

export const updateActivitySearchRequest = (
  searchRequest: IActivitySearchRequest,
): PARSAction<IActivitySearchRequest> => ({
  payload: searchRequest,
  type: UPDATE_ACTIVITY_SEARCH_REQUEST,
});

export const updateSearchStateProps = (
  props: IActivitySearchStateProps,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  await dispatch(updateSearchStatePropsAction(props));
};

export const getActivityKeywords = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  try {
    const activityService = new ActivityService();
    dispatch(getActivityKeywordsSuccess(await activityService.getKeywords()));
  } catch (error) {
    handleServerError({ error, thunkName: 'getActivityKeywords' });
  }
};

export const clearRecentSearches = (): PARSAction<void, typeof CLEAR_RECENT_ACTIVITY_SEARCHES> => {
  CacheService.remove(CACHE_ACTIVITY_RECENT_SEARCHES);
  return { type: CLEAR_RECENT_ACTIVITY_SEARCHES };
};

export const setRecentSearch = (keyword: string): PARSAction<string[], typeof SET_RECENT_ACTIVITY_SEARCHES> => {
  // Get keywords from cache.
  const recentSearches = new Set(CacheService.get<string[]>(CACHE_ACTIVITY_RECENT_SEARCHES));

  // Delete and add keyword to ensure proper order.
  recentSearches.delete(keyword);
  recentSearches.add(keyword);

  // Set most recent keywords in cache.
  const result = Array.from(recentSearches).slice(-CACHE_ACTIVITY_RECENT_SEARCHES_MAX);
  CacheService.set(CACHE_ACTIVITY_RECENT_SEARCHES, result);

  return {
    payload: result,
    type: SET_RECENT_ACTIVITY_SEARCHES,
  };
};

export const onUpdateActivitiesPaginationState = (
  props: IActivitySearchStateProps,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { searchRequest } = getState().activity;

  dispatch(updateSearchStatePropsAction({ ...searchRequest, ...props }));
  await dispatch(
    getActivities(
      {
        ...props,
        skip: (searchRequest.top || DEFAULT_RESULTS_PER_PAGE) * (props.page - 1),
      },
      false,
    ),
  );
};

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const getActivities = (
  payload?: IActivitySearchRequest,
  shouldResetPageReset = true,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { searchRequest } = getState().activity;
  const newSearchRequest: IActivitySearchRequest = { ...searchRequest, ...payload };

  // Always reset to page 1 except from an explicit pagination call.
  if (shouldResetPageReset) {
    await dispatch(onUpdateActivitiesPaginationState({ ...newSearchRequest, page: 1 }));
    return;
  }
  dispatch(getActivitiesAction(newSearchRequest));
  dispatch(popToast({ ...infoToastOptions, message: <>Searching...</> }));
  try {
    const activities = await ActivityService.get(newSearchRequest);
    dispatch(getActivitiesSuccessAction(activities));
    dispatch(popToast({ ...successToastOptions, autoHideDuration: 1000, message: <>Activity search updated</> }));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getActivities' });
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    dispatch(getActivitiesFailureAction(error));
  }
};

export const resetActivitySearch = (): PARSAction<void> => ({ type: RESET_ACTIVITY_SEARCH });
