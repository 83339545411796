import { ThunkAction } from 'redux-thunk';

// Core
import { IStateOrProvince, PARSAction } from 'core';

// Services
import { StatesService } from 'services/StatesService';

// Store
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';

// Utils
import { handleServerError } from 'globals/utils/handleServerError';

// Types
import { GET_STATES_AND_PROVINCES_SUCCESS } from './types';

export const getStatesAndProvincesSuccessAction = (payload: IStateOrProvince[] = []): PARSAction => ({
  payload,
  type: GET_STATES_AND_PROVINCES_SUCCESS,
});

export const getStatesAndProvinces = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<void> => {
  try {
    const statesAndProvinces: IStateOrProvince[] = await StatesService.getStates();
    dispatch(getStatesAndProvincesSuccessAction(statesAndProvinces));
  } catch (error) {
    handleServerError({ error, thunkName: 'getStatesAndProvinces' });
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }
};
