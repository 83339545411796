import last from 'lodash/last';
import parseInt from 'lodash/parseInt';
import { ReactElement } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

// Globals.
import error404Image from 'globals/images/error404.png';

// Styles.
import styles from './NotFoundPage.module.scss';

export const NotFoundPage = (): ReactElement => {
  const { pathname } = useLocation();
  // Example pathname: https://cmefinderdev.accme.org/conference/test-active-210013693
  // These paths are generated and shared with google. We only need the last series of numbers.
  const slugPathSplit = pathname.split('-');
  const seoSlugId = last(slugPathSplit);
  const isSEOSlugPath = slugPathSplit.length > 1 && !!parseInt(seoSlugId); // ID

  // Check if an SEO friendly URL was entered, and redirect as appropriate
  if (isSEOSlugPath) {
    return <Redirect to={`/activity/details/${seoSlugId}`} />;
  }

  // If the path does not include this SEO slug, show the 404 page
  return (
    <section className={styles.container}>
      <h1>Error 404 - Page Not Found</h1>
      <img alt="Sorry, we can't find the page you're looking for." src={error404Image} />
      <h2>Here Are Some Helpful Links:</h2>
      <ul>
        <li>
          If you were searching for a particular activity, it may no longer be available. Try a{' '}
          <Link to="/activity/search">new search</Link>.
        </li>
        <li>
          Email us at <a href="mailto:info@accme.org">info@accme.org</a>
        </li>
      </ul>
    </section>
  );
};
