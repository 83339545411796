import { KeyboardBackspaceRounded } from '@material-ui/icons';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Components
import { Button, ITypeaheadOption, Typeahead } from 'components';
import { RecentSearches } from './RecentSearches';

// Core.
import { IActivityKeywords } from 'core';

// Store.
import { setRecentSearch } from 'store/activity/actions';
import { activityKeywordsSelector } from 'store/activity/selectors';

// Styles
import styles from './SearchBox.module.scss';

// Types.
import { SearchTypes } from '..';

interface ISearchBox {
  searchType: SearchTypes;
}

export const SearchBox = ({ searchType }: ISearchBox): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');
  const keywords: IActivityKeywords = useSelector(activityKeywordsSelector);
  const isSpecialty: boolean = useMemo(() => searchType === SearchTypes.Specialty, [searchType]);
  const options: ITypeaheadOption[] = useMemo(
    () => Object.keys(keywords).map((option) => ({ label: option, value: option })),
    [keywords],
  );

  const dispatch = useDispatch();
  const history = useHistory();

  // Updates recent searches and navigates to search page.
  const conductSearch = useCallback(
    (searchText): void => {
      if (searchText) {
        if (isSpecialty) {
          dispatch(setRecentSearch(searchText));
          history.push(`/activity/search?specialty=${encodeURIComponent(searchText)}`);
        } else {
          history.push(`/activity/search?searchText=${encodeURIComponent(searchText)}`);
        }
      }
    },
    [dispatch, history, isSpecialty],
  );

  // Automatically searches if option selected.
  const handleChange = useCallback(
    (e, option): void => {
      e.preventDefault(); // Prevents double submit.
      const searchText = typeof option === 'string' ? option : option?.value;
      conductSearch(searchText);
    },
    [conductSearch],
  );

  // Keeps track of input characters for future submit.
  const handleInputChange = useCallback((value): void => {
    setInputValue(value);
  }, []);

  // Handles search if no option selected.
  const handleSubmit = useCallback(
    (e): void => {
      e.preventDefault(); // Prevents double submit.
      conductSearch(inputValue);
    },
    [conductSearch, inputValue],
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.box}>
        <Typeahead
          className={styles.typeahead}
          canOpen={isSpecialty}
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={handleInputChange}
          openAfter={3}
          options={options}
          placeholder="Search Activities..."
        />
        {isSpecialty && inputValue.length < 1 && <RecentSearches handleSearch={conductSearch} />}
        <Button className={styles.submit} type="submit">
          <KeyboardBackspaceRounded />
        </Button>
      </div>
    </form>
  );
};
