import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { ReactElement } from 'react';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#bfcdd4',
    color: '#007bbd',
  },
});

interface ILoadingOverlayProps {
  isOpen?: boolean;
}

export const LoadingOverlay = (props: ILoadingOverlayProps): ReactElement => {
  const { isOpen } = props;
  const classes = useStyles();

  return (
    <Backdrop classes={classes} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
