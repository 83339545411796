import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Hooks.
import { useAuth } from 'hooks';

// Store + Core
import { userProfileFullNameSelector } from 'store/profile/selectors';

// Styles
import styles from './Welcome.module.scss';

export const Welcome = (): ReactElement => {
  const [account, { isAuthenticated }] = useAuth();
  const fullName: string = useSelector(userProfileFullNameSelector);

  return (
    <div className={styles.container}>
      {isAuthenticated ? (
        <h2 data-testid="account-welcome-message">
          <div className={styles['welcome-prompt']}>{`Welcome back, ${fullName}`}</div>
          {account?.name}
        </h2>
      ) : (
        // Logged out state message
        <>
          <h2 data-testid="guest-welcome-message">Take control of your CME</h2>
          <ul>
            <li>Find CME and track your CME credits </li>
            <li>Generate CME transcripts</li>
            <li>Focus on your patients – not paperwork</li>
          </ul>
        </>
      )}
    </div>
  );
};
