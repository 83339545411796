import { useCallback } from 'react';

// Hooks.
import { EventArgs, GtmEvents, useEvent } from 'hooks';

type ClickEventFunction = (overrideArgs?: EventArgs) => void;

export const useClickEvent = (args?: EventArgs): ClickEventFunction => {
  const fireEvent = useEvent();
  const { Event = 'On Page Engagement', EventCategory = 'Page Action', ...rest } = args || {};

  return useCallback(
    (overrideArgs?: EventArgs): void => {
      fireEvent({ Event, EventCategory, event: GtmEvents.PageInteraction, ...rest, ...overrideArgs });
    },
    [Event, EventCategory, fireEvent, rest],
  );
};
