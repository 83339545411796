import { Box, Grid, Typography } from '@material-ui/core';
import { ChangeEvent, ReactElement } from 'react';
import moment from 'moment';

// Core + Store
import { ILearnerResult } from 'core';

// Misc
import { Credits } from './Credits';

// Styles
import styles from './TranscriptCard.module.scss';

// Types
interface IProps {
  isSelected?: boolean;
  onSelect?({ e, learnerActivity }: { e: ChangeEvent<HTMLInputElement>; learnerActivity: ILearnerResult }): void;
  learnerActivity?: ILearnerResult;
}

export const TranscriptCard = (props: IProps): ReactElement => {
  const { isSelected, onSelect, learnerActivity } = props;
  const { activityTitle, completionDate, credits, key, provider, activityFormat } = learnerActivity;

  return (
    <Box className={styles.card} p={4} mt={3} mb={3}>
      <Grid container>
        {/* Checkbox column */}
        {onSelect && (
          <Grid item>
            <label className={styles.checkbox} htmlFor={key}>
              <input id={key} type="checkbox" checked={isSelected} onChange={(e) => onSelect({ e, learnerActivity })} />
              <span className="checkmark" />
              <Typography variant="srOnly">{activityTitle}</Typography>
            </label>
          </Grid>
        )}
        {/* Main card content column */}
        <Grid item xs={11}>
          <h1 className={styles.heading}>{activityTitle}</h1>
          <Box display="flex" className={styles['pipe-separated']} mb={1} mt={1}>
            <Box>Completed {moment(completionDate).format('M/D/YYYY')}</Box>
            <Box>{provider}</Box>
            <Box>{activityFormat}</Box>
          </Box>
          {!!credits?.length && (
            <Grid container spacing={1}>
              <Credits credits={credits} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
