import classNames from 'classnames';
import { ReactElement, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { LoadingCards } from 'components';

// Styles.
import styles from './RegisterPage.module.scss';

// Hooks.
import { useClickEvent, useTaxonomyFaq, useWarnBeforeLeaving } from 'hooks';

// Pages.
import { MatchLearnerPage } from './MatchLearner';
import { RegisterSuccessPage } from './RegisterSuccess';
import { VerifyEmailPage } from './VerifyEmail';
import { VerifyCodePage } from './VerifyCode';

// Store + Core.
import {
  onboardingHasULIDAssignedSelector,
  onboardingIsClaimedSelector,
  onboardingIsLicensedInUnitedStatesSelector,
  onboardingSelector,
} from 'store/onboarding/selectors';
import { ITaxonomyTerm } from 'core';
import { onboardingRequestReset } from 'store/onboarding/actions';
import { UserState } from 'store/onboarding/types';

export const RegisterPage = (): ReactElement => {
  const dispatch = useDispatch();
  const faqTerms: ITaxonomyTerm[] = useTaxonomyFaq('CMEFinder');
  const fireEvent = useClickEvent();

  // Selectors
  const { isCodeSent, isCodeVerified }: UserState = useSelector(onboardingSelector);
  const hasUlid: boolean = useSelector(onboardingHasULIDAssignedSelector);
  const isClaimed: boolean = useSelector(onboardingIsClaimedSelector);
  const isLicensedInUnitedStates: boolean = useSelector(onboardingIsLicensedInUnitedStatesSelector);

  /**
   * Prompt the user before reloading or navigating away
   * from the screen if they've already begun onboarding.
   */
  useWarnBeforeLeaving(isCodeSent && !hasUlid);

  // Reset onboarding request if user navigates away.
  useEffect(() => {
    return () => {
      dispatch(onboardingRequestReset());
    };
  }, [dispatch]);

  // A user can view the success page in one of two situations.
  // 1. When a user:
  //    - is not licensed in the US, has a ulid, is not claimed.
  // OR
  //
  // 2. When a user:
  //    - is licensed in the US, has a ulid, isClaimed
  // On the initial state, we are setting the value of isLicensedInUnitedStates to undefined.
  // If a user selects no, we set that value to false and then we push them to the success page.

  // Order matters here as we are rendering pages based on a specific flow.
  const verifyEmailPage: ReactElement = !isCodeSent && <VerifyEmailPage />;
  const verifyCodePage: ReactElement = !isCodeVerified && !hasUlid && <VerifyCodePage />;
  const matchLearnerPage = (): ReactElement => {
    // Base case - first time getting to this page.
    if (hasUlid && isLicensedInUnitedStates === undefined) return <MatchLearnerPage />;
    // In US - yes is selected and isClaimed is false.
    if (hasUlid && isLicensedInUnitedStates && !isClaimed) return <MatchLearnerPage />;
    // If not in US - no is selected and isClaimed is false.
    if (hasUlid && isLicensedInUnitedStates === false && isClaimed === false) return <MatchLearnerPage />;
    // Fallback.
    return null;
  };
  const successPage: ReactElement = hasUlid && (isClaimed || isLicensedInUnitedStates === false) && (
    <RegisterSuccessPage />
  );
  const loadingCards: ReactElement = <LoadingCards count={3} />;

  return (
    <>
      <div className={classNames(styles.container, { [styles['has-sidebar']]: isCodeSent })}>
        {/* Main content. The order matters here because there is a process to the onboarding flow. */}
        <section>{verifyEmailPage || verifyCodePage || matchLearnerPage() || successPage || loadingCards}</section>

        {/* Sidebar */}
        {isCodeSent && (
          <aside className={styles.aside}>
            <h2>Helpful Links</h2>
            <ul>
              {faqTerms?.map(
                ({ description, id }: ITaxonomyTerm): ReactElement => (
                  <li
                    dangerouslySetInnerHTML={{ __html: description }}
                    key={id}
                    onClick={(e: MouseEvent<HTMLLIElement>): void => {
                      const { textContent } = e.target as HTMLElement;
                      fireEvent({ EventAction: 'Links', EventLabel: textContent });
                    }}
                  />
                ),
              )}
            </ul>
          </aside>
        )}
      </div>
    </>
  );
};
