import { useMediaQuery } from '@material-ui/core';
import classnames from 'classnames';
import { FilterListRounded } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { ReactElement, ReactNode } from 'react';

// Components
import { Button } from 'components';

// Styles
import styles from './Summary.module.scss';

// Store + Core
import { openRail } from 'store/rail/actions';
import { BreakpointsEnum, ButtonVariant } from 'core';

interface ISummarySection {
  children: ReactNode;
  className?: string;
  hasExploreMore?: boolean;
}

export const Summary = ({ children, className, hasExploreMore }: ISummarySection): ReactElement => {
  const dispatch = useDispatch();
  const isMdBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);

  return (
    <section className={classnames(styles.section, className)}>
      {/* Render the facets for mobile. */}
      {!isMdBreakpoint && hasExploreMore && (
        <div className="summary-refiners">
          <Button onClick={() => dispatch(openRail())} variant={ButtonVariant.Filter}>
            <FilterListRounded /> Explore More
          </Button>
        </div>
      )}
      {children}
    </section>
  );
};
