import * as yup from 'yup';

export const validationSchema = yup.object({
  dob: yup.string().required('Date of Birth is required.').nullable(),
  firstName: yup.string().required('First Name is required.'),
  isLicensedInUnitedStates: yup.boolean(),
  lastName: yup.string().required('Last Name is required.'),
  stateAbbreviation: yup.string().when('isLicensedInUnitedStates', {
    is: true,
    then: yup.string().required('Licensing State is required.'),
  }),
  stateName: yup.string().when('isLicensedInUnitedStates', {
    is: true,
    then: yup.string().required('Licensing State is required.'),
  }),
});
