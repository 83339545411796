import { IContextualMenuProps } from 'components';
import { SORT_BY_RECENTLY_ADDED, SORT_BY_PROVIDER_NAME, SORT_BY_COMPLETION_DATE, SORT_BY_ACTIVITY_TITLE } from 'core';
import { ISortLearnerMenu } from './types';

/**
 * @function sortLearnerMenu
 * @param args
 * @description Dropdown items specifically for Learners Summary Sorting.
 */
export const sortLearnerMenu = (args: ISortLearnerMenu): IContextualMenuProps => {
  const { sortBy, sortByProviderName, sortByCompletionDate, sortByTimestamp, sortByTitle } = args;
  const sortOptions = [
    {
      name: 'Recently Added',
      onSelectAction: sortByTimestamp,
      selected: sortBy === SORT_BY_RECENTLY_ADDED,
    },
    {
      name: 'Activity Title',
      onSelectAction: sortByTitle,
      selected: sortBy === SORT_BY_ACTIVITY_TITLE,
    },
    {
      name: 'Completion Start Date',
      onSelectAction: sortByCompletionDate,
      selected: sortBy === SORT_BY_COMPLETION_DATE,
    },
    {
      name: 'Provider Name',
      onSelectAction: sortByProviderName,
      selected: sortBy === SORT_BY_PROVIDER_NAME,
    },
  ];

  return {
    label: 'Sort by: ',
    options: sortOptions,
  };
};
