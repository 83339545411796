import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Core.
import { ITaxonomyTerm, TAXONOMY_MEDICAL_SCHOOLS_ID } from 'core';

// Store.
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { medicalSchoolsSelector } from 'store/taxonomy/selectors';

export const useTaxonomyMedicalSchools = (): ITaxonomyTerm[] => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_MEDICAL_SCHOOLS_ID));
  }, [dispatch]);

  return useSelector(medicalSchoolsSelector);
};
