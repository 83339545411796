import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Components.
import { Button } from 'components';

// Core.
import { ButtonVariant } from 'core';

// Store.
import { recentSearchesSelector } from 'store/activity/selectors';

// Styles.
import styles from './RecentSearches.module.scss';

interface IRecentSearches {
  handleSearch(searchText: string): void;
}

export const RecentSearches = ({ handleSearch }: IRecentSearches): ReactElement => {
  const recentSearches: string[] = useSelector(recentSearchesSelector);

  return (
    recentSearches.length > 0 && (
      <div className={styles.recent}>
        <h4>Your Previous Searches</h4>
        <ul>
          {recentSearches.map((keyword) => (
            <li key={keyword}>
              <Button className={styles.link} onClick={() => handleSearch(keyword)} variant={ButtonVariant.Hyperlink}>
                {keyword}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};
