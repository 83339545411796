// Libs
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';

// Components
import { Button } from 'components/Button';
import { Wizard } from 'components/Profile/OnBoarding/Wizard';

// Core
import { ButtonVariant, IUserProfile } from 'core';

// Store
import { updateUserProfile } from 'store/profile/actions';
import { userProfileSelector } from 'store/profile/selectors';

// Styles
import styles from '../../AccountSettings.module.scss';

interface IProps {
  onClose: VoidFunction;
}

export const EditLicensing = ({ onClose }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const userProfile = useSelector(userProfileSelector);
  const formik = useFormik({
    initialValues: userProfile,
    onSubmit: async (values: IUserProfile): Promise<void> => {
      await dispatch(updateUserProfile(values));
      onClose();
    },
  });
  const { handleSubmit, isSubmitting } = formik;
  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className={styles['button-row']}>
          <Button disabled={isSubmitting} variant={ButtonVariant.Tertiary} onClick={onClose}>
            Cancel
          </Button>
        </div>
        <Wizard />
      </form>
    </FormikProvider>
  );
};
