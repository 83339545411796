// Libs
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';

// Components
import { Button, Card, LoadingCards } from 'components';
import { EditGeneral } from './forms/EditGeneral';
import { EditLicensing } from './forms/EditLicensing';
import { EditNotifications } from './forms/EditNotifications';
import { EditOverview } from './forms/EditOverview';
import { ContentOutput } from './ContentOutput';

// Core
import {
  ButtonVariant,
  IBoard,
  IDictionary,
  IProfileCertifyingBoard,
  IProfileStateBoard,
  ITaxonomyTerm,
  IUserProfile,
} from 'core';

// Store
import { getCertifyingBoardsSelector, specialtiesSelector, userProfileSelector } from 'store/profile/selectors';
import { getCertifyingBoards, getSpecialties } from 'store/profile/actions';

// Hooks
import { useTaxonomyMedicalSchools } from 'hooks';

// Styles
import styles from './AccountSettings.module.scss';

export interface ILearnerBoardDetail {
  board: IBoard;
  learnerId: string;
}

export const AccountSettings = (): ReactElement => {
  const dispatch = useDispatch();
  const certifyingBoards: IBoard[] = useSelector(getCertifyingBoardsSelector);
  const medicalSchools: ITaxonomyTerm[] = useTaxonomyMedicalSchools();
  const profile: IUserProfile = useSelector(userProfileSelector);
  const specialties: IDictionary<string> = useSelector(specialtiesSelector);
  const [isEditOverview, setIsEditOverview] = useState<boolean>(false);
  const [isEditGeneral, setIsEditGeneral] = useState<boolean>(false);
  const [isEditLicensing, setIsEditLicensing] = useState<boolean>(false);
  const [isEditNotifications, setIsEditNotifications] = useState<boolean>(false);

  useEffect(() => {
    if (!certifyingBoards) {
      dispatch(getCertifyingBoards());
    }
    dispatch(getSpecialties());
  }, [getCertifyingBoards, dispatch]);

  if (!certifyingBoards?.length || !medicalSchools?.length || !profile || !specialties) {
    return <LoadingCards count={4} />;
  }

  const captionClass = styles.caption;
  const contentClass = styles.content;
  const editClass = styles.edit;
  const headingClass = styles.heading;
  const {
    alternateFirstName,
    alternateLastName,
    certifyingBoards: learnerCertifyingBoards,
    dobDate,
    dobMonth,
    email,
    firstName,
    isOptedOutOfNotifications,
    lastName,
    medicalSchoolId,
    middleName,
    npi,
    phoneNumber,
    specialtyIds,
    stateBoards,
    zipCode,
  } = profile;

  const filteredCertifyingBoards: ILearnerBoardDetail[] = learnerCertifyingBoards?.map(
    ({ boardId, learnerId }: IProfileCertifyingBoard): ILearnerBoardDetail => ({
      board: { ...certifyingBoards?.find(({ id }: IBoard): boolean => id === boardId) },
      learnerId,
    }),
  );
  const dobString = `${dobMonth}/${dobDate}`;
  const altName = `${alternateFirstName} ${alternateLastName}`;

  // Remove the last comma for name display.
  const nameString: string = [lastName, firstName, middleName].join(', ').replace(/, ([^,]*)$/, ' $1');

  const renderCertifyingBoardList = (): ReactElement => {
    if (!filteredCertifyingBoards?.length || !certifyingBoards?.length) {
      return <ContentOutput />;
    }
    return (
      <ul className={contentClass}>
        {filteredCertifyingBoards?.map(
          ({ board, learnerId }: ILearnerBoardDetail, index: number): ReactElement => (
            <li key={index}>
              {board.name} ({board.abbreviation}) - ID {learnerId}
            </li>
          ),
        )}
      </ul>
    );
  };
  const renderStateBoardList = (): ReactElement => {
    if (!stateBoards?.length) {
      return <ContentOutput />;
    }
    return (
      <ul className={contentClass}>
        {stateBoards?.map(
          ({ stateName, learnerId }: IProfileStateBoard, index: number): ReactElement => (
            <li key={index}>
              {stateName} - ID {learnerId}
            </li>
          ),
        )}
      </ul>
    );
  };
  const renderSpecialties = (): ReactElement => {
    if (!specialtyIds?.length) {
      return <ContentOutput />;
    }
    return (
      <ul className={contentClass}>
        {specialtyIds?.map(
          (specialtyId: string, index: number): ReactElement =>
            !!specialties?.[specialtyId] && <li key={index}>{specialties?.[specialtyId]}</li>,
        )}
      </ul>
    );
  };
  const getEditButton = (onClickEdit: (isEditing: boolean) => void) => (
    <Button variant={ButtonVariant.Secondary} onClick={() => onClickEdit(true)}>
      Edit
    </Button>
  );
  return (
    <div className={styles['account-settings']}>
      <Card>
        <Box display="flex" justifyContent="space-between">
          <h5 className={headingClass}>Account Overview</h5>
          {!isEditOverview && <div className={editClass}>{getEditButton(setIsEditOverview)}</div>}
        </Box>
        {!isEditOverview ? (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Email</div>
              <ContentOutput output={email} conditions={[email]} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Password</div>
              <p className={contentClass}>************</p>
            </Grid>
          </Grid>
        ) : (
          <EditOverview onClose={() => setIsEditOverview(false)} />
        )}
      </Card>
      <Card>
        <Box display="flex" justifyContent="space-between">
          <h5 className={headingClass}>General Information</h5>
          {!isEditGeneral && <div className={editClass}>{getEditButton(setIsEditGeneral)}</div>}
        </Box>
        {!isEditGeneral ? (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Name</div>
              <ContentOutput output={nameString} conditions={[firstName, lastName]} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Date of Birth</div>
              <ContentOutput output={dobString} conditions={[dobDate, dobMonth]} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Alternate Names</div>
              <ContentOutput output={altName} conditions={[alternateFirstName, alternateLastName]} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>ZIP Code</div>
              <ContentOutput output={zipCode} conditions={[zipCode]} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Medical School</div>
              <ContentOutput
                output={medicalSchools.find((i) => i.id === medicalSchoolId)?.name}
                conditions={[medicalSchoolId]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Phone</div>
              <ContentOutput output={phoneNumber} conditions={[phoneNumber]} />
            </Grid>
          </Grid>
        ) : (
          <EditGeneral onClose={() => setIsEditGeneral(false)} />
        )}
      </Card>
      <Card>
        <Box display="flex" justifyContent="space-between">
          <h5 className={headingClass}>Licensing and Certification</h5>
          {!isEditLicensing && <div className={editClass}>{getEditButton(setIsEditLicensing)}</div>}
        </Box>
        {!isEditLicensing ? (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>Licensing State</div>
              {renderStateBoardList()}
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={captionClass}>NPI</div>
              <ContentOutput output={npi} conditions={[npi]} />
            </Grid>
            <Grid item xs={12}>
              <div className={captionClass}>Certifying Boards</div>
              {renderCertifyingBoardList()}
            </Grid>
            <Grid item xs={12}>
              <div className={captionClass}>Specialty/Area of Practice</div>
              {renderSpecialties()}
            </Grid>
          </Grid>
        ) : (
          <EditLicensing onClose={() => setIsEditLicensing(false)} />
        )}
      </Card>
      <Card>
        <Box display="flex" justifyContent="space-between">
          <h5 className={headingClass}>Email Notifications</h5>
          {!isEditNotifications && <div className={editClass}>{getEditButton(setIsEditNotifications)}</div>}
        </Box>
        {!isEditNotifications ? (
          <Grid container>
            <Grid item xs={12}>
              <div className={captionClass}>Receive CME credit notification emails</div>
              <p className={contentClass}>{isOptedOutOfNotifications ? 'No' : 'Yes'}</p>
            </Grid>
          </Grid>
        ) : (
          <EditNotifications onClose={() => setIsEditNotifications(false)} />
        )}
      </Card>
    </div>
  );
};
