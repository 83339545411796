import { Reducer } from 'redux';

// Core.
import { IToastOptions } from 'core';

// Types.
import { ToastActionTypes, TOAST_OPEN, TOAST_CLOSE, ToastState } from './types';

const initialState: ToastState = {
  toastOpen: false,
  toastOptions: undefined,
};

export const toastReducer: Reducer<ToastState, ToastActionTypes> = (
  state = initialState,
  action: ToastActionTypes,
): ToastState => {
  switch (action.type) {
    case TOAST_OPEN:
      return {
        ...state,
        toastOpen: true,
        toastOptions: action.payload as IToastOptions,
      };
    case TOAST_CLOSE:
      return {
        ...state,
        toastOpen: false,
      };
    default:
      return state;
  }
};
