import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup.string().required('Email is required.').email('Must be a valid email.'),
  password: yup
    .string()
    .required('Password is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character',
    ),
  passwordConfirmation: yup
    .string()
    .required('Password must be confirmed.')
    .oneOf([yup.ref('password'), null], 'Passwords must match.'),
});
