import { IInitialSearchFacet } from '../types';

export const INITIAL_SEARCH_FACETS: IInitialSearchFacet = {
  excludeFilters: false,
  isDeleted: false,
  searchText: '',
  skip: 0,
  sortAscending: true,
  sortBy: 'Timestamp',
  statuses: [],
  top: 10,
};
