import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';

// Components.
import { ActivityDetail } from './ActivityDetail';
import { ActivitySearchPage } from './ActivitySearch';

// Store.
import { resetActivitySearch } from 'store/activity/actions';

export const ActivityPage = (): ReactElement => {
  const dispatch = useDispatch();

  // Reset activity search on unmount.
  useEffect((): VoidFunction => {
    return () => {
      dispatch(resetActivitySearch());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route component={ActivitySearchPage} path={['/activity/search/:curatedQuery', '/activity/search']} />
      <Route component={ActivityDetail} path="/activity/details/:id" />
    </Switch>
  );
};
