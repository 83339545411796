import { ReactElement } from 'react';
import { Box, Grid } from '@material-ui/core';

// Components
import { FormikInputField } from 'components/forms';
import { WizardButtonRow } from '../WizardButtonRow';

// Styles
import styles from '../Wizard.module.scss';

// Types
import { IWizardProps } from '../types';
type IProps = Pick<IWizardProps, 'back' | 'next' | 'skip'>;

export const NPIForm = (props: IProps): ReactElement => {
  const { back, next, skip } = props;

  return (
    <div>
      <h5>What is your NPI?</h5>
      <br />
      <div className={styles['boards-repeater']}>
        <Grid item xs={12} sm={6}>
          <FormikInputField defaultValue="" formikKey="npi" placeholder="XXXXXXXXX" />
        </Grid>
        <Box display="flex" my={3}>
          <WizardButtonRow next={next} skip={skip} back={back} />
        </Box>
      </div>
    </div>
  );
};
