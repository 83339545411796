import { IOnboardingMatchLearner } from 'core';

export interface UserState {
  isAbleToMatch: boolean;
  isCodeSent: boolean;
  isCodeVerified: boolean;
  isLicensedInUnitedStates: boolean;
  isLoading: boolean;
  isRegistered: boolean;
  matchFailureCount: number;
  onboardingRequest: IOnboardingMatchLearner;
}

export const IS_LICENSED_IN_UNITED_STATES = 'IS_LICENSED_IN_UNITED_STATES';

export const ONBOARDING_REQUEST_UPDATED = 'ONBOARDING_REQUEST_UPDATED';
export const ONBOARDING_REQUEST_RESET = 'ONBOARDING_REQUEST_RESET';

export const ONBOARDING_CODE_REQUEST = 'ONBOARDING_CODE_REQUEST';
export const ONBOARDING_CODE_SUCCESS = 'ONBOARDING_CODE_SUCCESS';
export const ONBOARDING_CODE_FAILURE = 'ONBOARDING_CODE_FAILURE';

export const ONBOARDING_CODE_VALIDATE_REQUEST = 'ONBOARDING_CODE_VALIDATE_REQUEST';
export const ONBOARDING_CODE_VALIDATE_SUCCESS = 'ONBOARDING_CODE_VALIDATE_SUCCESS';
export const ONBOARDING_CODE_VALIDATE_FAILURE = 'ONBOARDING_CODE_VALIDATE_FAILURE';

export const ONBOARDING_LEARNER_MATCHED_REQUEST = 'ONBOARDING_LEARNER_MATCHED_REQUEST';
export const ONBOARDING_LEARNER_MATCHED_SUCCESS = 'ONBOARDING_LEARNER_MATCHED_SUCCESS';
export const ONBOARDING_LEARNER_MATCHED_FAILURE = 'ONBOARDING_LEARNER_MATCHED_FAILURE';

export const ONBOARDING_REGISTER_REQUEST = 'ONBOARDING_REGISTER_REQUEST';
export const ONBOARDING_REGISTER_SUCCESS = 'ONBOARDING_REGISTER_SUCCESS';
export const ONBOARDING_REGISTER_FAILURE = 'ONBOARDING_REGISTER_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
