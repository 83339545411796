import { CheckRounded } from '@material-ui/icons';
import { ReactElement, useEffect } from 'react';

// Components.
import { Button, OnboardingVerifyEmailForm } from 'components';

// Hooks.
import { useLoadEvent, useClickEvent } from 'hooks';

// Styles.
import styles from './VerifyEmailPage.module.scss';
import { ButtonVariant } from 'core';

export const VerifyEmailPage = (): ReactElement => {
  const fireLoadEvent = useLoadEvent({ PageType: 'Registration Page', RegistrationStep: 1 });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  const fireEvent = useClickEvent();

  return (
    <div className={styles.container}>
      <section>
        <h1>Create an account – and take control of your CME.</h1>
        <ul>
          <li>
            <CheckRounded className={styles.icon} />
            <span>
              No need to keep track of your credits yourself – your CME provider can report your CME and MOC credits to
              your account.
            </span>
          </li>
          <li>
            <CheckRounded className={styles.icon} />
            <span>Track your CME and MOC credits.</span>
          </li>
          <li>
            <CheckRounded className={styles.icon} />
            <span>
              Generate a transcript to send to your licensing and certifying boards, credentialing authorities or
              employer.
            </span>
          </li>
          <li>
            <CheckRounded className={styles.icon} />
            <span>
              CME Passport will automatically report your CME and MOC credits to licensing and certifying boards
              collaborating with the ACCME.
            </span>
          </li>
        </ul>
        <Button
          style={{ width: 150, marginLeft: `65px` }}
          component="a"
          href="https://accme.org/about-cmepassport"
          onClick={() => fireEvent({ EventAction: 'Links', EventLabel: 'Learn More' })}
          rel="noreferrer noopener"
          target="_blank"
          variant={ButtonVariant.Secondary}
        >
          Learn More
        </Button>
      </section>
      <div>
        <OnboardingVerifyEmailForm />
      </div>
    </div>
  );
};
