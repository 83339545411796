import { ChangeEvent, ReactElement, useState } from 'react';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { Close, ExpandMoreRounded, SearchRounded } from '@material-ui/icons';
import { useField } from 'formik';
import { uniq } from 'lodash';

// Components
import { Button } from 'components/Button';
import { InputOption } from './InputOption';

// Styles
import styles from './MultiTypeahead.module.scss';

// Types
export interface ILabelType {
  description?: string;
  id: string;
  label: string;
  name?: string;
}

const useStyles = makeStyles({
  listbox: {
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
  },
  option: {
    backgroundColor: '#fff',
    borderBottom: '0.03125rem solid #dedede',
    borderRadius: 0,
    padding: '0.5rem 1rem',
    '&[aria-selected="true"]': {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '&:last-child': {
      borderBottom: 0,
    },
  },
  paper: {
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    padding: 0,
  },
  popper: {
    backgroundColor: '#fff',
    border: '0.03125rem solid #dedede',
    position: 'relative',
  },
});

interface IProps {
  onInputChange?(_: ChangeEvent<HTMLInputElement>, val: string): void;
  options: ILabelType[];
  placeholder: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  id: string;
  initialOptions?: ILabelType[];
}

export const MultiTypeahead = (props: IProps): ReactElement => {
  const { onInputChange, options, placeholder, setFieldValue, id, initialOptions } = props;
  const [field] = useField<string[] | { id: string }[]>(id);
  const { value } = field;
  const initialAutocompleteValues =
    initialOptions ||
    options?.filter(
      ({ id }) =>
        (value as string[])?.includes(id) ||
        (value as { id: string }[])?.find((val: { id: string }): boolean => val.id === id),
    );
  const classes = useStyles();
  const [values, setValues] = useState<ILabelType[]>(initialAutocompleteValues || []);
  const [pendingValues, setPendingValues] = useState<ILabelType[]>(initialAutocompleteValues || []);

  // Select the dropdown value.
  const onClickDropdownOption = (_event: ChangeEvent<never>, newValue: ILabelType[]): void => {
    setPendingValues(newValue);
    setValues(newValue);
    const sortedSelectedValues: string[] = uniq(
      newValue
        ?.sort((labelA: ILabelType, labelB: ILabelType): number => {
          if (labelA?.label > labelB?.label) {
            return 1;
          }
          if (labelA?.label < labelB?.label) {
            return -1;
          }
          return 0;
        })
        ?.map(({ id }: ILabelType): string => id),
    );
    if (setFieldValue) setFieldValue(id, sortedSelectedValues, true);
  };

  // Select (remove) from outside the dropdown.
  const onClickOutsideSelected = (newValue: ILabelType): void => {
    const filteredPendingValues = pendingValues.filter((value) => value.id !== newValue.id);
    const filteredBaseValues = values.filter((value) => value.id !== newValue.id);
    const fieldValues = filteredBaseValues.map(({ id: Id }) => Id);
    setPendingValues(filteredPendingValues);
    setValues(filteredBaseValues);
    setFieldValue(id, fieldValues, true);
  };

  const renderField = (params: AutocompleteRenderInputParams): ReactElement => {
    params.InputProps.startAdornment = (
      <InputAdornment position="start">
        <SearchRounded />
      </InputAdornment>
    );
    params.InputProps.endAdornment = (
      <InputAdornment position="end">
        <ExpandMoreRounded />
      </InputAdornment>
    );
    return (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, 'aria-label': placeholder }}
        placeholder={placeholder}
        variant="outlined"
      />
    );
  };
  const renderOption = (option, { selected }): ReactElement => <InputOption option={option} selected={selected} />;
  const optionLabel = ({ label }: ILabelType): string => label;

  // Display the selected options at the top of the options.
  const sortedOptions: ILabelType[] = [...options].sort((a: ILabelType, b: ILabelType): number => {
    let ai = values.indexOf(a);
    ai = ai === -1 ? values.length + options.indexOf(a) : ai;
    let bi = values.indexOf(b);
    bi = bi === -1 ? values.length + options.indexOf(b) : bi;
    return ai - bi;
  });

  return (
    <div className={styles['multi-autocomplete']}>
      <Autocomplete
        aria-label={placeholder}
        classes={{
          listbox: classes.listbox,
          option: classes.option,
          paper: classes.paper,
          popperDisablePortal: classes.popper,
        }}
        disableCloseOnSelect
        disablePortal
        getOptionLabel={optionLabel}
        getOptionSelected={(option: ILabelType, value: ILabelType) => option?.id === value?.id}
        multiple
        noOptionsText="No options"
        onInputChange={onInputChange}
        onChange={onClickDropdownOption}
        openOnFocus
        options={sortedOptions}
        renderInput={renderField}
        renderOption={renderOption}
        value={pendingValues}
      />

      {/* List of selected items. */}
      {values.length >= 1 && (
        <div className={styles['selected-summary']}>
          {values.map(
            (item: ILabelType): ReactElement => {
              const { id, label } = item;

              return (
                <div className={styles['selected-summary-item']} key={id}>
                  <div>{label}</div>
                  <Button onClick={(): void => onClickOutsideSelected(item)} title="remove">
                    <Close />
                  </Button>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
};
