import { HowToRegRounded } from '@material-ui/icons';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Components.
import { Button } from 'components';

// Core.
import { ButtonVariant } from 'core';

// Hooks.
import { useClickEvent } from 'hooks';

// Styles.
import styles from './Banner.module.scss';

export const Banner = (): ReactElement => {
  const fireEvent = useClickEvent();

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <HowToRegRounded data-testid="banner-icon" />
        <span>Create an account to track and manage your CME transcripts.</span>
      </div>
      {/* Second class on button used for component specific styles that need to override global */}
      <Button
        className={styles.button}
        component={Link}
        onClick={() => fireEvent({ EventAction: 'Registration', EventLabel: '/register' })}
        to="/register"
        variant={ButtonVariant.Secondary}
      >
        Create an Account
      </Button>
    </div>
  );
};
