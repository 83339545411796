import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { SearchContext } from './SearchContext';
import { SearchBox } from './SearchBox';

// Store.
import { getActivityKeywords } from 'store/activity/actions';

// Styles
import styles from './Search.module.scss';

export enum SearchTypes {
  Keyword = 'keyword',
  Specialty = 'specialty',
}

export const Search = (): ReactElement => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = useState<SearchTypes>(SearchTypes.Specialty);

  useEffect(() => {
    dispatch(getActivityKeywords());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <SearchContext onChange={(searchType) => setSearchType(searchType)} searchType={searchType} />
      <SearchBox searchType={searchType} />
    </div>
  );
};
