// Core
import {
  IBoard,
  IDictionary,
  ILearnerSearchRequest,
  ILearnerSearchResult,
  ILearnerSearchStateProps,
  IProfileCME,
  IUserProfile,
  PARSAction,
} from 'core';

export interface ProfileState {
  accountId: string;
  boards: IBoard[];
  cme: IProfileCME;
  error: Error;
  isUserProfileCMELoading: boolean;
  isUserProfileLoading: boolean;
  isUserProfileRecentCMELoading: boolean;
  isUserProfileSearchLoading: boolean;
  recentCme: ILearnerSearchResult;
  searchRequest: ILearnerSearchRequest;
  searchResponse: ILearnerSearchResult;
  specialties: IDictionary<string>;
  ulid: string;
  userProfile: IUserProfile;
  searchStateProps: ILearnerSearchStateProps;
}

export const GET_LEARNER_PROFILE = 'GET_LEARNER_PROFILE';
export const GET_LEARNER_PROFILE_SUCCESS = 'GET_LEARNER_PROFILE_SUCCESS';
export const GET_LEARNER_PROFILE_FAILURE = 'GET_LEARNER_PROFILE_FAILURE';

export const GET_LEARNER_PROFILE_SEARCH = 'GET_LEARNER_PROFILE_SEARCH';
export const GET_LEARNER_PROFILE_SEARCH_SUCCESS = 'GET_LEARNER_PROFILE_SEARCH_SUCCESS';
export const GET_LEARNER_PROFILE_SEARCH_FAILURE = 'GET_LEARNER_PROFILE_SEARCH_FAILURE';
export const UPDATE_LEARNER_PROFILE_SEARCH_PROPS = 'UPDATE_LEARNER_PROFILE_SEARCH_PROPS';

export const UPDATE_PROFILE_SEARCH_PAGINATION_STATE = 'UPDATE_PROFILE_SEARCH_PAGINATION_STATE';
export const UPDATE_LEARNER_PROFILE_SEARCH = 'UPDATE_LEARNER_PROFILE_SEARCH';

export const RESET_LEARNER_PROFILE = 'RESET_LEARNER_PROFILE';

export const UPDATE_LEARNER_PROFILE_SUCCESS = 'UPDATE_LEARNER_PROFILE_SUCCESS';
export const UPDATE_LEARNER_PROFILE_FAILURE = 'UPDATE_LEARNER_PROFILE_FAILURE';

export const GET_PROFILE_BOARDS_SUCCESS = 'GET_PROFILE_BOARDS_SUCCESS';

export const GET_PROFILE_SPECIALTIES_SUCCESS = 'GET_PROFILE_SPECIALTIES_SUCCESS';

export const DOWNLOAD_TRANSCRIPT = 'DOWNLOAD_TRANSCRIPT';
export const DOWNLOAD_TRANSCRIPT_SUCCESS = 'DOWNLOAD_TRANSCRIPT_SUCCESS';
export const DOWNLOAD_TRANSCRIPT_FAILURE = 'DOWNLOAD_TRANSCRIPT_FAILURE';

export const SEND_TRANSCRIPT = 'SEND_TRANSCRIPT';
export const SEND_TRANSCRIPT_SUCCESS = 'SEND_TRANSCRIPT_SUCCESS';
export const SEND_TRANSCRIPT_FAILURE = 'SEND_TRANSCRIPT_FAILURE';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const GET_PROFILE_CME = 'GET_PROFILE_CME';
export const GET_PROFILE_CME_SUCCESS = 'GET_PROFILE_CME_SUCCESS';
export const GET_PROFILE_CME_FAILURE = 'GET_PROFILE_CME_FAILURE';

export const GET_PROFILE_RECENT_CME = 'GET_PROFILE_RECENT_CME';
export const GET_PROFILE_RECENT_CME_SUCCESS = 'GET_PROFILE_RECENT_CME_SUCCESS';
export const GET_PROFILE_RECENT_CME_FAILURE = 'GET_PROFILE_RECENT_CME_FAILURE';

export const SET_HAS_COMPLETED_WIZARD = 'SET_HAS_COMPLETED_WIZARD';

interface GetLearnerProfile extends PARSAction<void, typeof GET_LEARNER_PROFILE> {
  type: typeof GET_LEARNER_PROFILE;
}

interface GetLearnerProfileSuccess extends PARSAction<IUserProfile, typeof GET_LEARNER_PROFILE_SUCCESS> {
  payload: IUserProfile;
  type: typeof GET_LEARNER_PROFILE_SUCCESS;
}

interface GetLearnerProfileFailure extends PARSAction<IUserProfile, typeof GET_LEARNER_PROFILE_FAILURE> {
  payload: IUserProfile;
  type: typeof GET_LEARNER_PROFILE_FAILURE;
}

interface GetLearnerProfileSearch extends PARSAction<ILearnerSearchRequest, typeof GET_LEARNER_PROFILE_SEARCH> {
  payload: ILearnerSearchRequest;
  type: typeof GET_LEARNER_PROFILE_SEARCH;
}

interface UpdatePassword extends PARSAction<void, typeof UPDATE_PASSWORD> {
  type: typeof UPDATE_PASSWORD;
}

interface UpdatePasswordSuccess extends PARSAction<void, typeof UPDATE_PASSWORD_SUCCESS> {
  type: typeof UPDATE_PASSWORD_SUCCESS;
}

interface UpdatePasswordFailure extends PARSAction<Error, typeof UPDATE_PASSWORD_FAILURE> {
  payload: Error;
  type: typeof UPDATE_PASSWORD_FAILURE;
}

interface DownloadTranscript extends PARSAction<void, typeof DOWNLOAD_TRANSCRIPT> {
  type: typeof DOWNLOAD_TRANSCRIPT;
}
interface DownloadTranscriptSuccess extends PARSAction<void, typeof DOWNLOAD_TRANSCRIPT_SUCCESS> {
  type: typeof DOWNLOAD_TRANSCRIPT_SUCCESS;
}
interface DownloadTranscriptFailure extends PARSAction<void, typeof DOWNLOAD_TRANSCRIPT_FAILURE> {
  type: typeof DOWNLOAD_TRANSCRIPT_FAILURE;
}

interface SendTranscript extends PARSAction<void, typeof SEND_TRANSCRIPT> {
  type: typeof SEND_TRANSCRIPT;
}
interface SendTranscriptSuccess extends PARSAction<void, typeof SEND_TRANSCRIPT_SUCCESS> {
  type: typeof SEND_TRANSCRIPT_SUCCESS;
}
interface SendTranscriptFailure extends PARSAction<void, typeof SEND_TRANSCRIPT_FAILURE> {
  type: typeof SEND_TRANSCRIPT_FAILURE;
}

interface GetLearnerProfileSearchSuccess
  extends PARSAction<ILearnerSearchResult, typeof GET_LEARNER_PROFILE_SEARCH_SUCCESS> {
  payload: ILearnerSearchResult;
  type: typeof GET_LEARNER_PROFILE_SEARCH_SUCCESS;
}

interface GetLearnerProfileSearchFailure extends PARSAction<Error, typeof GET_LEARNER_PROFILE_SEARCH_FAILURE> {
  payload: Error;
  type: typeof GET_LEARNER_PROFILE_SEARCH_FAILURE;
}

interface UpdateLearnerProfileSearchProps
  extends PARSAction<ILearnerSearchStateProps, typeof UPDATE_LEARNER_PROFILE_SEARCH_PROPS> {
  payload: ILearnerSearchStateProps;
  type: typeof UPDATE_LEARNER_PROFILE_SEARCH_PROPS;
}

interface UpdateProfileSearch extends PARSAction<ILearnerSearchRequest, typeof UPDATE_LEARNER_PROFILE_SEARCH> {
  payload: ILearnerSearchRequest;
  type: typeof UPDATE_LEARNER_PROFILE_SEARCH;
}

interface UpdateProfileSearchPaginationState
  extends PARSAction<ILearnerSearchStateProps, typeof UPDATE_PROFILE_SEARCH_PAGINATION_STATE> {
  payload: ILearnerSearchStateProps;
  type: typeof UPDATE_PROFILE_SEARCH_PAGINATION_STATE;
}

interface ResetLearnerProfile extends PARSAction<undefined, typeof RESET_LEARNER_PROFILE> {
  type: typeof RESET_LEARNER_PROFILE;
}

interface UpdateLearnerProfileSuccess extends PARSAction<IUserProfile, typeof UPDATE_LEARNER_PROFILE_SUCCESS> {
  payload: IUserProfile;
  type: typeof UPDATE_LEARNER_PROFILE_SUCCESS;
}

interface UpdateLearnerProfileFailure extends PARSAction<undefined, typeof UPDATE_LEARNER_PROFILE_FAILURE> {
  type: typeof UPDATE_LEARNER_PROFILE_FAILURE;
}

interface GetProfileBoardsSuccess extends PARSAction<IBoard[], typeof GET_PROFILE_BOARDS_SUCCESS> {
  payload: IBoard[];
  type: typeof GET_PROFILE_BOARDS_SUCCESS;
}

interface GetProfileSpecialtiesSuccess extends PARSAction<IDictionary<string>, typeof GET_PROFILE_SPECIALTIES_SUCCESS> {
  payload: IDictionary<string>;
  type: typeof GET_PROFILE_SPECIALTIES_SUCCESS;
}

interface GetProfileCME extends PARSAction<void, typeof GET_PROFILE_CME> {
  type: typeof GET_PROFILE_CME;
}

interface GetProfileCMESuccess extends PARSAction<IProfileCME, typeof GET_PROFILE_CME_SUCCESS> {
  type: typeof GET_PROFILE_CME_SUCCESS;
}

interface GetProfileCMEFailure extends PARSAction<void, typeof GET_PROFILE_CME_FAILURE> {
  type: typeof GET_PROFILE_CME_FAILURE;
}

interface GetProfileRecentCME extends PARSAction<void, typeof GET_PROFILE_RECENT_CME> {
  type: typeof GET_PROFILE_RECENT_CME;
}

interface GetProfileRecentCMESuccess extends PARSAction<ILearnerSearchResult, typeof GET_PROFILE_RECENT_CME_SUCCESS> {
  type: typeof GET_PROFILE_RECENT_CME_SUCCESS;
}

interface GetProfileRecentCMEFailure extends PARSAction<void, typeof GET_PROFILE_RECENT_CME_FAILURE> {
  type: typeof GET_PROFILE_RECENT_CME_FAILURE;
}

interface SetHasCompletedWizard extends PARSAction<void, typeof SET_HAS_COMPLETED_WIZARD> {
  type: typeof SET_HAS_COMPLETED_WIZARD;
}

export type ProfileActionTypes =
  | DownloadTranscript
  | DownloadTranscriptFailure
  | DownloadTranscriptSuccess
  | GetLearnerProfile
  | GetLearnerProfileFailure
  | GetLearnerProfileSearch
  | GetLearnerProfileSearchFailure
  | GetLearnerProfileSearchSuccess
  | GetLearnerProfileSuccess
  | GetProfileBoardsSuccess
  | GetProfileSpecialtiesSuccess
  | GetProfileCME
  | GetProfileCMESuccess
  | GetProfileCMEFailure
  | GetProfileRecentCME
  | GetProfileRecentCMESuccess
  | GetProfileRecentCMEFailure
  | ResetLearnerProfile
  | SendTranscript
  | SendTranscriptFailure
  | SendTranscriptSuccess
  | SetHasCompletedWizard
  | UpdateLearnerProfileFailure
  | UpdateLearnerProfileSearchProps
  | UpdateLearnerProfileSuccess
  | UpdatePassword
  | UpdatePasswordSuccess
  | UpdatePasswordFailure
  | UpdateProfileSearch
  | UpdateProfileSearchPaginationState;
