/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import * as axios from 'axios';

// Core.
import { Configuration } from 'core';

export class ServerRoute {
  private constructor(controller: string, action?: string) {
    this.controller = controller;
    this.action = action;

    if (!this.controller) {
      throw new Error(`Expected controller argument to be defined.`);
    }
  }

  public static forAction(controller: string, action?: string): ServerRoute {
    const result: ServerRoute = new ServerRoute(controller, action);
    const actionUrl = action ? `/${action}` : '';
    result.fullUrl = `/${controller}${actionUrl}`;
    return result;
  }

  private readonly controller: string;
  // @ts-ignore
  // TODO Can we remove this?
  private readonly action: string;
  private fullUrl?: string;

  public get url(): string {
    return this.fullUrl || '';
  }
}

const instance = axios.default;
// TODO config axios response interceptor
instance.interceptors.request.use((config) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  // Content-Disposition: form-data; name="file"; filename="ExcelActivities2.xlsx"
  // Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet

  return Object.assign({}, config, {
    // TODO check if config.data.headers is ok or if we need config.headers too
    headers: Object.assign({}, defaultHeaders, config.headers || {}),
  });
});

interface RequestConfig extends axios.AxiosRequestConfig {
  headers?: any;
  sendAuthentication?: boolean;
  authTokenOverride?: string;
  globalErrorHandling?: boolean;
}

function makeUrl(routeData: ServerRoute, queryString?: string | null) {
  const baseUrl: string = Configuration.api;
  let url = `${baseUrl}/api${routeData.url}`;

  if (queryString && queryString.length > 0) {
    url += `?${queryString}`;
  }

  return url;
}

export async function get<TResult>(
  routeData: ServerRoute,
  queryString?: string,
  config?: RequestConfig,
): Promise<TResult> {
  return (await instance.get(makeUrl(routeData, queryString), config)).data;
}

export async function getFullResponse(
  routeData: ServerRoute,
  queryString?: string,
  config?: RequestConfig,
): Promise<axios.AxiosResponse<any>> {
  return await instance.get(makeUrl(routeData, queryString), config);
}

export async function post<TResult>(routeData: ServerRoute, payload: any, config?: RequestConfig): Promise<TResult> {
  return (await instance.post(makeUrl(routeData), payload, config)).data;
}

export async function sendDelete<TResult>(
  routeData: ServerRoute,
  queryString?: string,
  config?: RequestConfig,
): Promise<TResult> {
  return (await instance.delete(makeUrl(routeData, queryString), config)).data;
}

export function getErrorMessage(error: any): string {
  // TODO add error type?
  if (
    error &&
    error.response &&
    (error.response.status === 400 || error.response.status === 404 || error.response.status === 409) &&
    error.response.message
  ) {
    if (typeof error.response.message === 'string') {
      return error.response.message;
    } else if (typeof error.response.title === 'string') {
      return error.response.title;
    }
  }
  return error.message ?? '';
}
