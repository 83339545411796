import { ReactElement } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Box, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Button, FormikInputField } from 'components';

// Store
import { onSendTranscript } from 'store/profile/actions';
import { selectedCompletionIdsSelector } from 'store/profile/selectors';

// Validation
import { validationSchema } from './validationSchema';

// Types
import { ButtonVariant, ISendTranscript } from 'core';

interface IProps {
  callback?(): void;
}

type Transcript = Omit<ISendTranscript, 'completionIds'>;

export const ShareTranscriptForm = (props: IProps): ReactElement => {
  const { callback } = props;
  const dispatch = useDispatch();

  // Selectors
  const completionIds = useSelector(selectedCompletionIdsSelector);

  const formik = useFormik<Transcript>({
    initialValues: {
      recipientEmail: '',
      recipientName: '',
    },
    onSubmit: async ({ recipientEmail, recipientName }: Transcript): Promise<void> => {
      await dispatch(
        onSendTranscript({
          completionIds,
          recipientEmail,
          recipientName,
        }),
      );

      callback?.();
    },
    validateOnMount: true,
    validationSchema,
  });

  const { handleSubmit, isSubmitting, isValid } = formik;

  return (
    <Container>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Box mt={3}>
            <FormikInputField formikKey="recipientName" label="Recipient Name" placeholder="Enter Name" />
          </Box>
          <Box mt={3}>
            <FormikInputField
              formikKey="recipientEmail"
              label="Recipient Email*"
              placeholder="Enter Email"
              type="email"
            />
          </Box>
          <Box display="flex" flexDirection="row-reverse" mt={4}>
            <Button
              isSubmitting={isSubmitting}
              variant={ButtonVariant.Primary}
              disabled={isSubmitting || !isValid}
              type="submit"
            >
              Send Transcript
            </Button>
          </Box>
        </form>
      </FormikProvider>
    </Container>
  );
};
