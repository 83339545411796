// Core.
import { BaseApiService } from './baseApiService';
import { CACHE_ACTIVITY_KEYWORDS } from './constants';
import { IActivityKeywords } from './models';

// Services.
import { CacheService } from 'services';

export class ActivityApiService extends BaseApiService {
  public async getActivityKeywords(): Promise<IActivityKeywords> {
    const cachedKeywords: IActivityKeywords = CacheService.get(CACHE_ACTIVITY_KEYWORDS);
    if (cachedKeywords) return cachedKeywords;

    const keywords: IActivityKeywords = await super.getAsync('/api/Activities/keywords');
    CacheService.set(CACHE_ACTIVITY_KEYWORDS, keywords);
    return keywords;
  }
}
