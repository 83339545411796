export class ProviderService {
  private static _currentProviderId: string | null = '';

  public get currentProviderId(): string | null {
    return ProviderService._currentProviderId;
  }

  public set currentProviderId(value: string | null) {
    ProviderService._currentProviderId = value;
  }
}
