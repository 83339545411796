/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from 'redux';
import { ReactElement } from 'react';
import { IInitialSearchFacet } from 'components/Facets/types';
import { TAGS_ENUM } from './constants';
import { ToastType, ToastLocationEnum, StatusEnum } from './enums';

export interface IOperand {
  ['$type']: string;
  OperandType: 'Comparison' | 'Constant' | 'FieldRef' | 'Logical';
}

export interface IWhere {
  Operand: IOperand;
}

export interface IDictionary<TValue> {
  [key: string]: TValue;
}

export interface CommercialSupportSource {
  source: string;
  amountGiven?: number;
  hasInKindSupport?: boolean;
  id?: string; // Optional id used for rendering unique components in an array
}

export interface BoardMocDetails {
  practiceIds?: Array<string>;
  typesOfCreditIds?: Array<string>;
  mocPointsGiven?: number;
  contentOutlineIds?: Array<string>;
}

export interface IActivityCredits {
  credits: number;
  isApproved: boolean;
  name: string;
}

export interface IBoard {
  abbreviation: string;
  contentOutlines: Array<ITaxonomyTerm>;
  creditTypes: Array<ITaxonomyTerm>;
  id: string;
  isEditable?: boolean;
  name: string;
  specialties: Array<ITaxonomyTerm>;
}

// TODO: Figure out what these interfaces should be.
export interface ILearnerContentOutline {
  name: string;
}

export interface ILearnerJointProvider {
  name: string;
}

// TODO: Figure out what these interfaces should be.
export interface ILearnerMeasuredOutcome {
  name: string;
}

// TODO: Figure out what these interfaces should be.
export interface ILearnerPracticeArea {
  name: string;
}

export interface IActivityCommercialSupportSource {
  amount: number;
  inKindSupport?: boolean;
  name: string;
}

export interface ILearnerActivity {
  boards: Pick<IBoard, 'abbreviation' | 'name'>[];
  city: string;
  cmeCreditTypeTotal: number;
  cmeFinderType?: string;
  commercialSupportDetails: IActivityCommercialSupportSource[];
  contentOutlines: ILearnerContentOutline[];
  country: string;
  createdBy: string;
  createdDate: string;
  creditTypes: ILearnerCredit[];
  description: string;
  endDate: string;
  id: string;
  inputMethod: string;
  internalId: string;
  isCmeFinder: boolean;
  isCommendation: boolean;
  isCommercialSupport: boolean;
  isDeleted: boolean;
  isJointlyProvided?: boolean;
  isMips: boolean;
  isMoc: boolean;
  isRems: boolean;
  jointProviders: ILearnerJointProvider[];
  key: string;
  learningMethod: string;
  measuredOutcomes: ILearnerMeasuredOutcome[];
  moCCreditTypes: ILearnerCredit[];
  moCCreditTypeTotal: number;
  participationFee: string;
  postalCode: string;
  practiceAreas: ILearnerPracticeArea[];
  providerId: string;
  providerName: string;
  providership: string;
  registration: string;
  startDate: string;
  state: string;
  status: string;
  timestamp: string;
  title: string;
  type: string;
  uanPharmacy: string;
  uanPharmacyTech: string;
  url: string;
  updatedBy: string;
}

export interface ITaxonomyTerm {
  name: string;
  description?: string;
  id: string;
  endDate: Date;
  startDate: Date;
  metadataText: string;
  metadataNumber?: number;
  parentId: string;
  sortOrder: number;
  tag?: TAGS_ENUM;
  terms: ITaxonomyTerm[];
}

export interface IStateOrProvince {
  isoCountryCode?: string;
  isoStateCode?: string;
  stateName: string;
}

export interface ICurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  defaultOrganizationId?: string;
}

export interface PARSAction<TPayload = any, TAction = string> extends Action<TAction> {
  readonly type: TAction;
  readonly payload?: TPayload;
  readonly error?: boolean;
  readonly meta?: any;
}

export interface Facet {
  'JointProviders/Name'?: string[];
  Boards?: string[];
  City?: string[];
  Country?: string[];
  IsCMEFinder?: boolean[];
  IsCommendation?: boolean[];
  IsCommercialSupport?: boolean[];
  JointProviders?: string[];
  LearningMethod?: string[];
  MeasuredOutcomes?: string[];
  ParticipationFee?: string[];
  ProviderName?: string[];
  Providership?: string[];
  Registration?: string[];
  State?: string[];
  Status?: string[];
  Type?: string[];
}

export interface IModalContentItem {
  Title: string;
  Content: string;
}

export interface IFormItem {
  ['$type']: string;
  Id: string;
  Visible: IWhere;
  Title?: string;
  ModalTitle?: string;
  ModalSubtitle?: string;
  ModalContent?: Array<IModalContentItem>;
  InputCaption?: string;
  LabelCaption?: string;
  TooltipContent?: string;
  FilterByOrganization?: boolean;
}

export interface IFormGroup extends IFormItem {
  Children: Array<IFormItem>;
  Variant: string;
  HideGroupTitleStyle?: boolean;
}

export interface IFormSection extends IFormGroup {
  Breadcrumb: string;
  Title: string;
  Subtitle: string;
}

export enum Providerships {
  DIRECTLY_PROVIDED = 'Directly Provided',
  JOINTLY_PROVIDED = 'Jointly Provided',
}

export interface BaseActivity {
  activityId?: string;
  boards?: IBoard[];
  boardMocDetails?: IDictionary<BoardMocDetails>;
  city?: string;
  closedDate?: Date;
  commendationCriteriaIds?: Array<string>;
  commercialSupportSources?: Array<CommercialSupportSource>;
  country?: string;
  credits?: IDictionary<number>;
  creditTypes?: { credits: number; name: string }[];
  description?: string;
  detailsUrl?: string;
  endDate: Date;
  hasCommercialSupport?: boolean;
  includeInCmeFinder?: boolean;
  internalId?: string;
  isDeleted?: boolean;
  isIpce?: boolean;
  isJointlyProvided?: boolean;
  isMips?: boolean;
  isMoc?: boolean;
  isPharmacyCertificateProgram?: boolean;
  isRems?: boolean;
  isRemsCompliant?: boolean;
  isRestrictedAudience?: boolean;
  jointProviders?: Array<string>;
  key?: string;
  learnerCounts?: IDictionary<number>;
  locationType?: string[];
  markedForCommendationCriteria?: boolean;
  mocCreditDeadline?: Date;
  mocProgramAttestation?: boolean;
  outcomesMeasured?: Array<string>;
  participationFeeTypeId?: string;
  pharmacyLiveDates?: Date[];
  pharmacySequenceNumber?: number;
  pharmacyTopicId?: string; // Taxonomy driven.
  pharmacyTypeId?: string; // Taxonomy driven.
  postalCode?: string;
  programs?: Array<string>;
  recurring?: boolean;
  remsRpcId?: string;
  startDate: Date;
  stateOrProvince?: string;
  status?: StatusEnum;
  supportedRemsIds?: Array<string>;
  targetAudience?: string[];
  title: string;
  type?: string;
  typeId: string;
  uanPharmacy?: string;
  uanPharmacyTech?: string;
  // these values aren't used in CME Finder (and aren't populated by the server)
  // hasIndividualLearners?: boolean;
  // hasRemsLearnersReported?: boolean;
  // learnerEarliestCompletionDate?: Date;
  // learnerLatestCompletionDate?: Date;
  // learnerMaximumCreditAmounts?: IDictionary<number>;
  // maximumCertifyingBoardCredits?: IDictionary<number>;
}

export interface Activity extends BaseActivity {
  id: string;
  printableId: string;
  formTemplate: Array<IFormSection>;
}

export interface IActivitySearchResult {
  score: number;
  highlights: any;
  document: ILearnerActivity;
}

export interface IActivitySearchRequest {
  activityTypes?: string[];
  boards?: string[];
  cities?: string[];
  collaborations?: string[];
  creditTypes?: string[];
  curatedQuery?: string;
  endDate?: Date;
  isCmeFinder?: boolean;
  isCommendation?: boolean;
  isCommercialSupport?: boolean;
  isDeleted?: boolean;
  isMips?: boolean;
  isMoc?: boolean;
  isPharmacyCertificateProgram?: boolean;
  isRems?: boolean;
  jointProviders?: string[];
  keys?: string[];
  learningMethods?: string[];
  learningMethod?: string[];
  legacyId?: string;
  measuredOutcomes?: string[];
  participationFees?: string[];
  participationFee?: string[];
  providerName?: string[];
  providerNames?: string[];
  providership?: string[];
  providerships?: string[];
  registration?: string[];
  registrations?: string[];
  searchText?: string;
  skip?: number;
  sortAscending?: boolean;
  sortBy?: string;
  startDate?: Date;
  states?: string[];
  statuses?: string[];
  type?: string[]; // Called `Formats` in the facet
  top?: number;
}

export interface IActivitySearchResponse {
  totalCount: number;
  facets: Facet;
  results: IActivitySearchResult[];
}

export interface IActivitySearchStateProps {
  page: number;
  selectedActivities?: Activity[];
}

export type IActivityLearnerSearchRequest = IActivitySearchRequest;

export interface IActivityLearnerSummarySearch extends IInitialSearchFacet {
  activityEndDate?: Date;
  activityFormats?: string[];
  activityStartDate?: Date;
  activityStatuses?: string[];
  boards?: string[];
  cities?: string[];
  completionInputMethods?: string[];
  completionStatuses?: string[];
  creditTypes?: string[];
  curatedQuery?: string;
  isCmeFinder?: boolean;
  isCommercialSupport?: boolean;
  isMips?: boolean;
  isMoc?: boolean;
  isRems?: boolean;
  learnerCompletionEndDate?: Date;
  learnerCompletionStartDate?: Date;
  learnerEntryMethods?: string[];
  learnerStatuses?: string[];
  learnerSubmissionEndDate?: Date;
  learnerSubmissionStartDate?: Date;
  measuredOutcomes?: string[];
  participationFees?: string[];
  providerIds?: string[];
  providerNames?: string[];
  providership?: string[];
  states?: string[];
}

export interface IToastOptions {
  autoHideDuration?: number;
  centered?: boolean;
  dismissable?: boolean;
  icon?: ReactElement;
  location?: ToastLocationEnum;
  message?: ReactElement;
  toastType: ToastType;
}

export interface IStaticToastOptions extends IToastOptions {
  onClick?: () => void;
}

export type IActivityKeywords = Record<string, string[]>;

export interface IOnboardingSendCodeRequest {
  email?: string;
  password?: string;
}

export interface IOnboardingValidateCodeRequest {
  code: string;
  email: string;
}

export interface ICreateUpdateAccountResponse {
  accountId: string;
  ulid: string;
}

export interface IOnboardingMatchLearnerRequest {
  alternateFirstName?: string;
  alternateLastName?: string;
  boardId?: string;
  dobDate?: number;
  dobMonth?: number;
  email?: string;
  firstName?: string;
  isClaimed?: boolean;
  lastName?: string;
  learnerId?: string;
  licenseId?: string;
  medicalSchoolId?: string;
  medicalSchoolName?: string;
  middleName?: string;
  npi?: string;
  password?: string;
  phoneNumber?: string;
  stateAbbreviation?: string;
  stateName?: string;
  zipCode?: string;
}

export type IOnboardingMatchLearner = IOnboardingMatchLearnerRequest & ICreateUpdateAccountResponse;

export type IOnboardingRequest = IOnboardingSendCodeRequest & IOnboardingMatchLearnerRequest;

export interface IOnboardingResponse {
  error?: string;
  ulid: string;
}

export interface IProfileCertifyingBoard {
  boardId: string;
  isEditable?: boolean;
  learnerId: string;
}

export interface IProfileStateBoard {
  isEditable: boolean;
  learnerId: string;
  stateAbbreviation: string;
  stateName: string;
}

export interface IUserProfile extends IOnboardingMatchLearnerRequest {
  accountId?: string;
  certifyingBoards?: IProfileCertifyingBoard[];
  firstName?: string;
  hasCompletedWizard?: boolean;
  isClaimed?: boolean;
  isOptedOutOfNotifications?: boolean;
  lastName?: string;
  middleName?: string;
  specialtyIds?: string[];
  stateBoards?: IProfileStateBoard[];
}

export interface ILearnerSearchRequest extends IActivityLearnerSummarySearch {
  isPhysicianApp?: true;
  ulid?: string;
}

export interface ILearnerSearchStateProps {
  page: number;
  selectedActivities?: ILearnerResult[];
}

export interface IDownloadTranscript {
  completionIds: string[];
}

export interface ISendTranscript extends IDownloadTranscript {
  recipientEmail: string;
  recipientName: string;
}

export interface ILearnerSearchResult {
  totalCount?: number;
  coverage?: number;
  facets?: any[];
  results: ILearnerSearch[];
}

export interface IArrayBufferDownload {
  file?: ArrayBuffer;
  type: string;
}

export interface ILearnerSearch {
  score: number;
  highlights: any;
  document: ILearnerResult;
}

export interface ILearnerResult extends Omit<BaseActivity, 'credits'> {
  activityGuid?: string;
  activityId?: string;
  activityName?: string;
  activityTitle?: string;
  activityFormat: string;
  board?: string;
  boardName?: string;
  businessId?: string;
  collaborationType: CollaborationType;
  completionDate?: Date;
  completionId?: string;
  completionIdentifier?: string;
  creditClaimDate?: Date;
  credits: ILearnerCredit[];
  firstName?: string;
  key?: string;
  lastName?: string;
  learnerDOBDay?: number;
  learnerDOBMonth?: number;
  learnerId?: string;
  learnerStatus?: string;
  provider?: string;
  providerId?: string;
  providerName?: string;
  rejectionReason?: string;
  timestamp?: Date;
  totalCredits?: number;
  ulid?: string;
}

export interface ILearnerCredit {
  abbreviation?: string;
  acceptedCredits?: number;
  boardAbbreviation?: string;
  boardId?: string;
  boardName?: string;
  completionSource?: CompletionSources;
  credits?: number;
  creditTypeId?: string;
  isAccepted?: boolean;
  name?: string;
  stateAbbreviation?: string;
  stateName?: string;
  totalCredits?: number;
  units?: string;
}

export enum CompletionSources {
  Board = 'Board',
  State = 'State',
}

export type CollaborationType = 'CreditsByCertifyingBoard' | 'CreditsByStateOrProvince' | 'CreditsByRems';

export interface IProfileCME {
  activities: IProfileCMEActivity[];
  annualCredits: Record<string, number>;
  email: string;
  name: string;
  totalCredits: Record<string, number>;
}

export interface IProfileCMEActivity {
  activityName: string;
  completionDate: string;
  completionId: string;
  credits: Record<string, number>;
  numItems: number;
  points: IProfileCMEActivityPoints[];
  providerName: string;
}

export interface IProfileCMEActivityPoints {
  amount: number;
  isChecked: boolean;
  name: string;
  points: Record<string, number>;
}
