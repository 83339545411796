import { ReactElement } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Box, IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// Components
import { FormikInputField } from 'components/forms';

// Styles
import styles from 'components/Facets/FacetSearch.module.scss';

// Types
import { FormikInputFieldProps } from '../types';

export const FormikInputFieldWithSearch = (props: FormikInputFieldProps): ReactElement => {
  const { formikKey, placeholder, title } = props;

  return (
    <Box display="flex" alignItems="center">
      <FormikInputField formikKey={formikKey} placeholder={placeholder} startIcon={<SearchIcon />} title={title} />
      <Box ml={-3}>
        <IconButton aria-label="search" className={styles['search-icon']} size="medium" type="submit">
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
