import { Reducer } from 'redux';
import {
  TaxonomyState,
  TaxonomyActionTypes,
  GET_TAXONOMY_TERM_BY_ID,
  GET_TAXONOMY_TERM_BY_ID_SUCCESS,
  GET_TAXONOMY_TERM_BY_ID_FAILURE,
} from './types';

const initialState: TaxonomyState = {
  terms: {},
};

export const taxonomyReducer: Reducer<TaxonomyState, TaxonomyActionTypes> = (
  state = initialState,
  action: TaxonomyActionTypes,
): TaxonomyState => {
  switch (action.type) {
    case GET_TAXONOMY_TERM_BY_ID:
      return state;

    case GET_TAXONOMY_TERM_BY_ID_FAILURE:
      console.error(action.payload);
      return state;

    case GET_TAXONOMY_TERM_BY_ID_SUCCESS:
      return {
        ...state,
        terms: { ...state.terms, ...action.payload },
      };

    default:
      return state;
  }
};
