/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { ReactElement } from 'react';

// Core.
import { IDictionary } from 'core';

// Styles.
import styles from './ErrorLabel.module.scss';

interface IProps {
  error: any;
  name: string;
}

export const ErrorLabel = ({ error, name }: IProps): ReactElement => {
  const renderErrorArrayEntry = (reactKey: string, key: string, list: IDictionary<string>): ReactElement => (
    <p className={styles.error} key={reactKey}>
      {list[key]}
    </p>
  );

  const renderErrorMessage = (error: any): ReactElement => {
    if (typeof error === 'string') {
      return <p className={styles.error}>{error}</p>;
    }
    if (isArray(error)) {
      const errorOutput = error?.map((entry, index): ReactElement[] | ReactElement => {
        if (typeof entry === 'object') {
          const entryKeys = keys(entry);
          const outputPerKey = entryKeys?.map((key, idx) => renderErrorArrayEntry(`${index}-${idx}`, key, entry));
          return outputPerKey;
        } else if (typeof entry === 'string') {
          return <p className={styles.error}>{entry}</p>;
        }
        return null;
      });
      return <>{errorOutput}</>;
    } else if (typeof error === 'object') {
      return <p className={styles.error}>{get(error, name)}</p>;
    }
  };
  return <>{renderErrorMessage(error)}</>;
};
