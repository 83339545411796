import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import { Button, ErrorLabel, Link, FormikInputField } from 'components';

// Core.
import { ButtonVariant, LinkVariant } from 'core';

// Hooks.
import { useAuthLogin } from 'hooks';

// Store.
import { sendCode } from 'store/onboarding/actions';
import { onboardingIsLoadingSelector } from 'store/onboarding/selectors';

// Styles.
import styles from './OnboardingVerifyEmailForm.module.scss';

// Validations.
import { validationSchema } from './validationSchema';

interface IOnboardingVerifyEmailForm {
  onSuccess?: VoidFunction;
}

export const OnboardingVerifyEmailForm = ({ onSuccess }: IOnboardingVerifyEmailForm): ReactElement => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const login = useAuthLogin();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values) => {
      try {
        await dispatch(sendCode(values));
        await onSuccess?.();
      } catch (error) {
        setError(error);
      }
    },
    validationSchema,
  });

  const { handleSubmit, isSubmitting } = formik;

  // Selectors
  const isLoading: boolean = useSelector(onboardingIsLoadingSelector);
  const isPending: boolean = isSubmitting || isLoading;

  return (
    <FormikProvider value={formik}>
      <form name="Registration Form" onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <FormikInputField formikKey="email" label="Email" required type="email" />
          <FormikInputField formikKey="password" label="Password" required type="password" />
          <FormikInputField formikKey="passwordConfirmation" label="Confirm Password" required type="password" />
        </fieldset>
        {error && <ErrorLabel error={error} name="email" />}
        <footer className={styles.footer}>
          {error ? (
            <div>
              <Button className={styles.link} onClick={login} title="Log In" variant={ButtonVariant.Hyperlink}>
                Log In
              </Button>
              <Link
                className={styles.link}
                title="Forgot My Password"
                to="/forgot-password"
                variant={LinkVariant.Hyperlink}
              >
                Forgot My Password
              </Link>
            </div>
          ) : (
            <>
              <p>
                Already have an account?{' '}
                <Button className={styles.link} onClick={login} title="Log In" variant={ButtonVariant.Hyperlink}>
                  Log In
                </Button>
              </p>
            </>
          )}
          <Button disabled={isPending} isSubmitting={isPending} type="submit" variant={ButtonVariant.Primary}>
            Create Account
          </Button>
        </footer>
      </form>
    </FormikProvider>
  );
};
