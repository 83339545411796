import { IStateOrProvince, PARSAction } from 'core';

export interface StatesAndProvincesState {
  statesAndProvinces: IStateOrProvince[];
}

export const GET_STATES_AND_PROVINCES_SUCCESS = 'GET_STATES_AND_PROVINCES_SUCCESS';

interface GetStatesAndProvincesSuccess extends PARSAction<IStateOrProvince[], typeof GET_STATES_AND_PROVINCES_SUCCESS> {
  payload: IStateOrProvince[];
  type: typeof GET_STATES_AND_PROVINCES_SUCCESS;
}

export type StatesAndProvincesActionTypes = GetStatesAndProvincesSuccess;
