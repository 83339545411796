import { ReactElement } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useFormikContext } from 'formik';

// Components
import { FacetDatePicker } from 'components/Facets/components';

// Types
import { IFacetProps, SearchRequest } from '../../../types';

export const Date = (props: IFacetProps): ReactElement => {
  const { callback, classes, facet, isExpanded, expandIcon, onToggleExpand } = props;
  const { values, setFieldValue } = useFormikContext<SearchRequest>();
  const { displayValue, payloadEndValue, payloadStartValue } = facet;

  const onChangeStart = (date): void => {
    setFieldValue(payloadStartValue, date?.toISOString());
    callback?.();
  };

  const onChangeFrom = (date): void => {
    setFieldValue(payloadEndValue, date?.toISOString());
    callback?.();
  };

  const minDate = values[payloadStartValue];
  const maxDate = values[payloadEndValue];

  return (
    <Accordion key={displayValue} className={classes.accordion} expanded={isExpanded} onChange={onToggleExpand}>
      <AccordionSummary
        aria-controls={`${displayValue}-content`}
        className={classes.summary}
        expandIcon={expandIcon}
        id={`${displayValue}-header`}
      >
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className="eyebrow">From: </div>
        <FacetDatePicker onChange={onChangeStart} maxDate={maxDate} value={values[payloadStartValue] || null} />

        <div className="eyebrow">To: </div>
        <FacetDatePicker onChange={onChangeFrom} value={values[payloadEndValue] || null} minDate={minDate} />
      </AccordionDetails>
    </Accordion>
  );
};
