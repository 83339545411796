import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { ReactElement, ReactNode } from 'react';

// Styles
import styles from './Rail.module.scss';

export enum RailVariants {
  Partial = 'partial',
  Full = 'full',
}

interface IRail {
  children?: ReactNode;
  handleClose?: VoidFunction;
  isOpen?: boolean;
  variant?: RailVariants;
}

export const Rail = ({ children, handleClose, isOpen, variant = RailVariants.Full }: IRail): ReactElement => {
  return (
    <aside className={classnames(styles.rail, styles[variant], { [styles.open]: isOpen })}>
      {handleClose && (
        <button className={styles.close} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </button>
      )}
      {children}
    </aside>
  );
};
