import { ThemeProvider } from '@material-ui/styles';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment-timezone';

// Contexts.
import { AuthProvider } from 'contexts';

// Styles.
import './index.scss';

// App.
import { App } from 'app';

// Core.
import { CACHE_IMPERSONATION_ACCESS_TOKEN, Configuration, DEFAULT_TIMEZONE, IMPERSONATION_TOKEN_HASH } from 'core';

// Store.
import { createStore } from 'store';

// Globals.
import { commonTheme } from 'globals/themes/common-theme';

import reportWebVitals from './reportWebVitals';
import { AnalyticsService } from 'services/AnalyticsService';
import { CacheService } from 'services';

const tagManagerArgs = { gtmId: Configuration.gtmId };
TagManager.initialize(tagManagerArgs);
AnalyticsService.initialize();

if (CacheService.get(CACHE_IMPERSONATION_ACCESS_TOKEN)) {
  CacheService.remove(CACHE_IMPERSONATION_ACCESS_TOKEN);
}

if (window.location.hash?.indexOf(IMPERSONATION_TOKEN_HASH) > 0 ?? false) {
  const token = window.location.hash.split(`#${IMPERSONATION_TOKEN_HASH}=`)?.pop();

  if (token) {
    CacheService.set(CACHE_IMPERSONATION_ACCESS_TOKEN, token);
    window.location.hash = '';
  }
}

// Force all moment timezones to match the default. You should still be able to use moment like normal in any ts/tsx files.
moment.tz.setDefault(DEFAULT_TIMEZONE);

render(
  <BrowserRouter>
    <AuthProvider>
      <Provider store={createStore()}>
        <ThemeProvider theme={commonTheme}>
          <App />
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
