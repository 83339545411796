import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import noop from 'lodash/noop';

// Components.
import { AuthenticatedRoute, LoadingOverlay, Toast } from 'components';
import { Footer } from './Footer';
import { Header } from './Header';

// Hooks.
import { useAuth } from 'hooks';

// Pages.
import { ActivityPage, HomePage, NotFoundPage, ProfilePage, RegisterPage, LoginPage } from 'pages';

// Styles.
import styles from './App.module.scss';

// Store + Core
import { userProfileSelector } from 'store/profile/selectors';
import { getUserProfile } from 'store/profile/actions';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { helpdeskTopicsTermSelector } from 'store/taxonomy/selectors';
import { IUserProfile, TAGS_ENUM, TAXONOMY_HELPDESK_TOPICS_ROOT_ID } from 'core';

// Types
import { AnalyticsService } from 'services/AnalyticsService';

export function App(): ReactElement {
  const [, { isAuthenticated, isLoaded }, getAccessToken] = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  // Selectors
  const userProfile: IUserProfile = useSelector(userProfileSelector);
  const helpDeskTopics = useSelector(helpdeskTopicsTermSelector);

  const isPendingProfile: boolean = isAuthenticated && !userProfile;
  const isLoading: boolean = isPendingProfile || !isLoaded;

  useEffect(() => {
    if (isPendingProfile) {
      const fetchUserData = async (): Promise<void> => {
        await getAccessToken();
        await dispatch(getUserProfile());
      };
      fetchUserData().then(noop);
    }
  }, [isAuthenticated, userProfile, isPendingProfile]);

  useEffect(() => {
    // Scroll to top when the route has changed.
    window.scroll(0, 0);
  }, [location]);

  useEffect(() => {
    dispatch(getTaxonomyTermById(TAXONOMY_HELPDESK_TOPICS_ROOT_ID));
  }, [dispatch]);

  // 5350 Display login error message when profile load fails.
  useEffect(() => {
    const queryStringParams = new URLSearchParams(location.search);
    // Read query string value
    const loginFailedValue = queryStringParams.get('isloginfailed');

    const loginErrorMessage = helpDeskTopics?.filter((i) => i.tag === TAGS_ENUM.HELP_DESK__LOG_IN_ISSUES)[0]
      ?.description;

    if (loginErrorMessage && loginFailedValue === '1') {
      dispatch(
        popToast({
          ...errorToastOptions,
          message: <>{loginErrorMessage}</>,
        }),
      );
      queryStringParams.delete('isloginfailed');
      history.replace({ search: queryStringParams.toString() });
    }
  });

  useEffect(() => {
    // track page views basd on URL change since we can't do it via AuthenticatedRoute
    AnalyticsService.trackPageView();
  }, [location.pathname, location.search, location.hash]);

  if (isLoading) return <LoadingOverlay isOpen />;

  return (
    <>
      <Helmet>
        <title>CME Passport</title>
      </Helmet>
      <Header />
      <main className={styles.main}>
        <Switch>
          <Route component={HomePage} exact path="/" />
          <Route component={LoginPage} path="/login" />
          <AuthenticatedRoute component={ProfilePage} path="/profile" requiresAuth />
          <AuthenticatedRoute component={RegisterPage} path="/register" requiresGuest />
          <Route component={ActivityPage} path="/activity" />
          <Route component={NotFoundPage} path="*" />
        </Switch>
      </main>
      <Footer />
      <Toast />
    </>
  );
}
