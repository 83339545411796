import { PARSAction } from 'core';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import { RAIL_CLOSE, RAIL_OPEN } from './types';

// action creators
export const railOpenAction = (): Action => {
  return { type: RAIL_OPEN };
};

export const railCloseAction = (): Action => {
  return { type: RAIL_CLOSE };
};

// thunk actions
export const openRail = (): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  dispatch(railOpenAction());
};

export const closeRail = (): ThunkAction<void, AppState, null, PARSAction> => (dispatch) => {
  dispatch(railCloseAction());
};
