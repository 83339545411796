import { IContextualMenuProps } from 'components';
import {
  SORT_BY_RECENTLY_ADDED,
  SORT_BY_A_Z_TITLE,
  SORT_BY_ACTIVITY_ID,
  SORT_BY_CLAIM_DATE,
  SORT_BY_INTERNAL_ID,
  SORT_BY_START_DATE,
  SORT_BY_END_DATE,
} from 'core';
import { ISortActivityMenu, ISortLearnerMenu } from './types';

/**
 * @function sortLearnerMenu
 * @param args
 * @description Dropdown items specifically for Learners Summary Sorting.
 */
export const sortLearnerMenu = (args: ISortLearnerMenu): IContextualMenuProps => {
  const { sortBy, sortByActivityId, sortByClaimDate, sortByTimestamp, sortByTitle } = args;
  const sortOptions = [
    {
      name: 'Recently Added',
      onSelectAction: sortByTimestamp,
      selected: sortBy === SORT_BY_RECENTLY_ADDED,
    },
    {
      name: 'A-Z Title',
      onSelectAction: sortByTitle,
      selected: sortBy === SORT_BY_A_Z_TITLE,
    },
    {
      name: 'Activity ID',
      onSelectAction: sortByActivityId,
      selected: sortBy === SORT_BY_ACTIVITY_ID,
    },
    {
      name: 'Claim Date',
      onSelectAction: sortByClaimDate,
      selected: sortBy === SORT_BY_CLAIM_DATE,
    },
  ];

  return {
    label: 'Sort by: ',
    options: sortOptions,
  };
};

/**
 * @function sortActivityMenu
 * @param args
 * @description Dropdown items specifically for Activity Summary Sorting.
 */
export const sortActivityMenu = (args: ISortActivityMenu): IContextualMenuProps => {
  const {
    sortBy,
    sortByActivityId,
    sortByEndDate,
    sortByInternalId,
    sortByStartDate,
    sortByTimestamp,
    sortByTitle,
  } = args;
  const sortOptions = [
    {
      name: 'Recently Added',
      onSelectAction: sortByTimestamp,
      selected: sortBy === SORT_BY_RECENTLY_ADDED,
    },
    {
      name: 'A-Z Title',
      onSelectAction: sortByTitle,
      selected: sortBy === SORT_BY_A_Z_TITLE,
    },
    {
      name: 'Start Date',
      onSelectAction: sortByStartDate,
      selected: sortBy === SORT_BY_START_DATE,
    },
    {
      name: 'End Date',
      onSelectAction: sortByEndDate,
      selected: sortBy === SORT_BY_END_DATE,
    },
    {
      name: 'Activity ID',
      onSelectAction: sortByActivityId,
      selected: sortBy === SORT_BY_ACTIVITY_ID,
    },
    {
      name: 'Internal ID',
      onSelectAction: sortByInternalId,
      selected: sortBy === SORT_BY_INTERNAL_ID,
    },
  ];

  return {
    label: 'Sort by: ',
    options: sortOptions,
  };
};
