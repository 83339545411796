import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Components
import { Banner, Cta, Hero } from 'components';

// Hooks.
import { useAuth, useLoadEvent } from 'hooks';

// Styles
import styles from './HomePage.module.scss';

export const HomePage = (): ReactElement => {
  const [, { isAuthenticated }] = useAuth();
  const fireLoadEvent = useLoadEvent({ PageType: 'Main Page' });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  return (
    <>
      <Helmet>
        <title>Homepage</title>
      </Helmet>
      <section className={styles.section}>
        {!isAuthenticated && <Banner />}
        <Hero />
        <Cta />
      </section>
    </>
  );
};
