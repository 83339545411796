/* eslint-disable @typescript-eslint/no-use-before-define */
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
// @ts-ignore
import { saveAs } from 'filesaver.js-npm';

// Core.
import {
  DEFAULT_RESULTS_PER_PAGE,
  IArrayBufferDownload,
  IBoard,
  IDictionary,
  ILearnerSearchRequest,
  ILearnerSearchResult,
  ILearnerSearchStateProps,
  IProfileCME,
  IProfileStateBoard,
  ISendTranscript,
  IUserProfile,
  PARSAction,
  SORT_BY_COMPLETION_DATE,
} from 'core';

// Services.
import { ProfileService } from 'services';

// Store.
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, infoToastOptions, successToastOptions } from 'store/toast/constants';

// Utils
import { handleServerError } from 'globals/utils/handleServerError';

// Types
import {
  DOWNLOAD_TRANSCRIPT,
  DOWNLOAD_TRANSCRIPT_SUCCESS,
  DOWNLOAD_TRANSCRIPT_FAILURE,
  GET_LEARNER_PROFILE_SEARCH_FAILURE,
  GET_LEARNER_PROFILE_SEARCH_SUCCESS,
  GET_LEARNER_PROFILE_FAILURE,
  GET_LEARNER_PROFILE_SUCCESS,
  GET_LEARNER_PROFILE,
  GET_LEARNER_PROFILE_SEARCH,
  GET_PROFILE_BOARDS_SUCCESS,
  GET_PROFILE_CME,
  GET_PROFILE_CME_FAILURE,
  GET_PROFILE_CME_SUCCESS,
  GET_PROFILE_RECENT_CME,
  GET_PROFILE_RECENT_CME_FAILURE,
  GET_PROFILE_RECENT_CME_SUCCESS,
  GET_PROFILE_SPECIALTIES_SUCCESS,
  RESET_LEARNER_PROFILE,
  SEND_TRANSCRIPT,
  SEND_TRANSCRIPT_FAILURE,
  SEND_TRANSCRIPT_SUCCESS,
  SET_HAS_COMPLETED_WIZARD,
  UPDATE_LEARNER_PROFILE_FAILURE,
  UPDATE_LEARNER_PROFILE_SEARCH,
  UPDATE_LEARNER_PROFILE_SEARCH_PROPS,
  UPDATE_LEARNER_PROFILE_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_SEARCH_PAGINATION_STATE,
} from './types';

import { createInstance } from 'contexts';
import { AnalyticsService } from 'services/AnalyticsService';
import { EndSessionRequest } from '@azure/msal-browser';

export const getProfile = (): PARSAction<void> => ({
  type: GET_LEARNER_PROFILE,
});

export const getProfileSuccess = (payload: IUserProfile): PARSAction<IUserProfile> => ({
  payload,
  type: GET_LEARNER_PROFILE_SUCCESS,
});

export const getProfileFailure = (error): PARSAction<Error> => ({
  payload: error,
  type: GET_LEARNER_PROFILE_FAILURE,
});

export const getProfileSearch = (payload: ILearnerSearchRequest): PARSAction<ILearnerSearchRequest> => ({
  payload,
  type: GET_LEARNER_PROFILE_SEARCH,
});

export const getProfileSearchSuccess = (payload: ILearnerSearchResult): PARSAction<ILearnerSearchResult> => ({
  payload,
  type: GET_LEARNER_PROFILE_SEARCH_SUCCESS,
});

export const getProfileSearchFailure = (error: Error): PARSAction<Error> => ({
  payload: error,
  type: GET_LEARNER_PROFILE_SEARCH_FAILURE,
});

export const updateProfileSearch = (payload: ILearnerSearchRequest): PARSAction<ILearnerSearchRequest> => ({
  payload,
  type: UPDATE_LEARNER_PROFILE_SEARCH,
});

export const updateProfileSearchPropsAction = (payload: ILearnerSearchStateProps): AnyAction => ({
  payload,
  type: UPDATE_LEARNER_PROFILE_SEARCH_PROPS,
});

export const updateProfileSearchPaginationState = (payload: ILearnerSearchStateProps): AnyAction => ({
  payload,
  type: UPDATE_PROFILE_SEARCH_PAGINATION_STATE,
});

export const resetProfile = (): PARSAction<void> => ({
  type: RESET_LEARNER_PROFILE,
});

export const updateLearnerProfileSuccess = (payload: IUserProfile): PARSAction<IUserProfile> => ({
  payload,
  type: UPDATE_LEARNER_PROFILE_SUCCESS,
});

export const updateProfileFailure = (): PARSAction<void> => ({
  type: UPDATE_LEARNER_PROFILE_FAILURE,
});

export const getCertifyingBoardsSuccessAction = (boards: IBoard[] = []): PARSAction => ({
  payload: boards,
  type: GET_PROFILE_BOARDS_SUCCESS,
});

export const getSpecialtiesSuccessAction = (specialties: IDictionary<string> = {}): PARSAction => ({
  payload: specialties,
  type: GET_PROFILE_SPECIALTIES_SUCCESS,
});

export const downloadTranscriptAction = (): PARSAction => ({
  type: DOWNLOAD_TRANSCRIPT,
});

export const downloadTranscriptSuccessAction = (): PARSAction => ({
  type: DOWNLOAD_TRANSCRIPT_SUCCESS,
});

export const downloadTranscriptFailureAction = (): PARSAction => ({
  type: DOWNLOAD_TRANSCRIPT_FAILURE,
});

export const sendTranscriptAction = (): PARSAction => ({
  type: SEND_TRANSCRIPT,
});

export const sendTranscriptSuccessAction = (): PARSAction => ({
  type: SEND_TRANSCRIPT_SUCCESS,
});

export const sendTranscriptFailureAction = (): PARSAction => ({
  type: SEND_TRANSCRIPT_FAILURE,
});

export const updatePasswordAction = (): PARSAction => ({
  type: UPDATE_PASSWORD,
});

export const updatePasswordSuccessAction = (): PARSAction => ({
  type: UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordFailureAction = (error: Error): PARSAction => ({
  payload: error,
  type: UPDATE_PASSWORD_FAILURE,
});

export const getProfileCMEAction = (): PARSAction => ({ type: GET_PROFILE_CME });
export const hasCompletedWizardAction = (): PARSAction => ({ type: SET_HAS_COMPLETED_WIZARD });

export const getProfileCMESuccessAction = (payload: IProfileCME): PARSAction => ({
  payload,
  type: GET_PROFILE_CME_SUCCESS,
});

export const getProfileCMEFailureAction = (): PARSAction => ({ type: GET_PROFILE_CME_FAILURE });

export const getProfileRecentCMEAction = (): PARSAction => ({ type: GET_PROFILE_RECENT_CME });

export const getProfileRecentCMESuccessAction = (payload: ILearnerSearchResult): PARSAction => ({
  payload,
  type: GET_PROFILE_RECENT_CME_SUCCESS,
});

export const getProfileRecentCMEFailureAction = (): PARSAction => ({ type: GET_PROFILE_RECENT_CME_FAILURE });

export const getUserProfile = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<void> => {
  dispatch(getProfile());
  try {
    const response = await ProfileService.getUserProfile();
    dispatch(getProfileSuccess(response));
    AnalyticsService.setUser(response?.accountId);
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'getUserProfile' });
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));

    // 5350 added a query string in case of profile load fails.
    const redirectUrl = '/?isloginfailed=1';
    const logoutRequest: EndSessionRequest = { postLogoutRedirectUri: redirectUrl };

    // Fallback on 500 when db has bad data - Force msal log out and have the user try again.
    await createInstance().logout(logoutRequest);
  }
};

export const updatePassword = (plainPassword: string): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<void> => {
  dispatch(updatePasswordAction());

  // Send as base64 encoded.
  const password = btoa(plainPassword);

  try {
    await ProfileService.updateUserPassword(password);
    dispatch(updatePasswordSuccessAction());
  } catch (error) {
    handleServerError({ error, thunkName: 'updatePassword' });
    dispatch(updatePasswordFailureAction(error));
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred.</> }));
  }
};

export const updateUserProfile = (
  profilePayload: IUserProfile,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState): Promise<void> => {
  const statesList = getState().states.statesAndProvinces;
  const userProfile = getState().profile.userProfile;
  const statesPayload = profilePayload.stateBoards?.map(
    ({ isEditable, learnerId, stateAbbreviation, stateName }: IProfileStateBoard): IProfileStateBoard => ({
      isEditable,
      learnerId,
      stateAbbreviation,
      stateName: stateName || statesList?.find(({ isoStateCode }) => isoStateCode === stateAbbreviation)?.stateName,
    }),
  );
  const updatedPayload: IUserProfile = { ...userProfile, ...profilePayload, stateBoards: statesPayload };
  try {
    await ProfileService.updateUserProfile(updatedPayload);
    dispatch(updateLearnerProfileSuccess(updatedPayload));
    dispatch(popToast({ ...successToastOptions, message: <>Profile updated successfully.</> }));
  } catch (error) {
    handleServerError({ error, thunkName: 'updateUserProfile' });
    dispatch(updateProfileFailure());
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred.</> }));
    return error;
  }
};

export const getCertifyingBoards = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<void> => {
  try {
    const boards = await ProfileService.getBoards();
    dispatch(getCertifyingBoardsSuccessAction(boards));
  } catch (error) {
    handleServerError({ error, thunkName: 'getCertifyingBoards' });
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }
};

export const getSpecialties = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<void> => {
  try {
    const specialties: IDictionary<string> = await ProfileService.getSpecialties();
    dispatch(getSpecialtiesSuccessAction(specialties));
  } catch (error) {
    handleServerError({ error, thunkName: 'getSpecialties' });
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }
};

export const getUserProfileSearch = (
  payload?: ILearnerSearchRequest,
  shouldResetPageReset = true,
  shouldSkipToastMessaging = false,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState): Promise<void> => {
  const { searchRequest } = getState().profile;
  const newSearchRequest: ILearnerSearchRequest = { ...searchRequest, ...payload };

  // Always reset to page 1 except from an explicit pagination call.
  if (shouldResetPageReset) {
    await dispatch(onUpdateProfileSearchPaginationState({ ...newSearchRequest, page: 1 }));
    return;
  }

  if (!shouldSkipToastMessaging) {
    dispatch(popToast({ ...infoToastOptions, message: <>Searching...</> }));
  }

  dispatch(updateProfileSearch(newSearchRequest));
  dispatch(getProfileSearch(newSearchRequest));

  try {
    const response = await ProfileService.getUserProfileSearch(newSearchRequest);

    dispatch(getProfileSearchSuccess(response));

    if (!shouldSkipToastMessaging) dispatch(popToast({ ...successToastOptions, message: <>Search updated</> }));
  } catch (error) {
    handleServerError({ error, thunkName: 'getUserProfileSearch' });
    dispatch(getProfileSearchFailure(error));
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred.</> }));
  }
};

export const onUpdateProfileSearchPaginationState = (
  props: ILearnerSearchStateProps,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch, getState) => {
  const { searchRequest } = getState().profile;

  dispatch(updateProfileSearchPaginationState({ ...searchRequest, ...props }));
  await dispatch(
    getUserProfileSearch(
      {
        ...props,
        skip: (searchRequest.top || DEFAULT_RESULTS_PER_PAGE) * (props.page - 1),
      },
      false,
    ),
  );
};

export const onDownloadTranscript = ({
  completionIds,
}: {
  completionIds: string[];
}): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(popToast({ ...infoToastOptions, message: <>Downloading Transcript...</> }));
  dispatch(downloadTranscriptAction());
  try {
    // Sending `completionIds: []` will download everything.
    const transcript: IArrayBufferDownload = await ProfileService.downloadTranscript(completionIds);

    if (transcript) {
      // Convert the result to a Blob.
      const blob: Blob = new Blob([new Uint8Array(transcript.file, 0, transcript.file.byteLength)], {
        type: transcript.type,
      });

      // Open this is in browser and trigger the download.
      saveAs(blob, 'Transcript.pdf');

      // Tell the user we have downloaded.
      dispatch(downloadTranscriptSuccessAction());
      dispatch(popToast({ ...successToastOptions, message: <>Transcript Downloaded</> }));
    } else {
      dispatch(popToast({ ...infoToastOptions, message: <>Your Transcript was not found.</> }));
    }
  } catch (error) {
    handleServerError({ error, thunkName: 'onDownloadTranscript' });
    dispatch(
      popToast({
        ...errorToastOptions,
        message: <>Your Transcript could not be downloaded. Please try again later.</>,
      }),
    );
    dispatch(downloadTranscriptFailureAction());
  }
};

export const onSendTranscript = (
  payload: ISendTranscript,
): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(sendTranscriptAction());
  dispatch(popToast({ ...infoToastOptions, message: <>Sending Transcript...</> }));
  try {
    // Sending `completionIds: []` will send everything.
    await ProfileService.sendTranscript(payload);
    dispatch(sendTranscriptSuccessAction());
    dispatch(
      popToast({
        ...successToastOptions,
        message: <>{`Your Transcript has been sent to ${payload.recipientName} at ${payload.recipientEmail}`}</>,
      }),
    );
  } catch (error) {
    handleServerError({ error, thunkName: 'onSendTranscript' });
    dispatch(
      popToast({ ...errorToastOptions, message: <>Your Transcript could not be sent. Please try again later.</> }),
    );
    dispatch(sendTranscriptFailureAction());
  }
};

export const getUserProfileCME = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  dispatch(getProfileCMEAction());
  try {
    const cme = await ProfileService.getUserProfileCME();
    dispatch(getProfileCMESuccessAction(cme));
  } catch (error) {
    dispatch(getProfileCMEFailureAction());
    handleServerError({ error, thunkName: 'getUserProfileCME' });
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }
};

export const getUserProfileRecentCME = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (
  dispatch,
) => {
  dispatch(getProfileRecentCMEAction());
  try {
    const results = await ProfileService.getUserProfileSearch({ sortBy: SORT_BY_COMPLETION_DATE, top: 5 });
    dispatch(getProfileRecentCMESuccessAction(results));
  } catch (error) {
    dispatch(getProfileRecentCMEFailureAction());
    handleServerError({ error, thunkName: 'getUserProfileCME' });
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }
};

export const onCompleteWizard = (): ThunkAction<Promise<void>, AppState, null, PARSAction> => async (dispatch) => {
  try {
    await ProfileService.completeWizard();
    dispatch(hasCompletedWizardAction());
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'onCompleteWizard' });
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};
