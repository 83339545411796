// Libs
import { ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';

// Core
import { IUserProfile } from 'core';

// Components
import { CertifyingBoards } from './CertifyingBoards';
import { Specialties } from './Specialties';
import { StateBoards } from './StateBoards';
import { NPIForm } from './NPIForm';

// Types
import { ProfileWizardEnum } from './types';

export const Wizard = (): ReactElement => {
  const [currentStep, setCurrentStep] = useState<ProfileWizardEnum>(ProfileWizardEnum.CertifyingBoards);
  const { submitForm } = useFormikContext<IUserProfile>();

  const goToStep = (step: ProfileWizardEnum): VoidFunction => () => setCurrentStep(step);
  const submitUserProfile = async (): Promise<void> => {
    await submitForm();
  };

  return (
    <>
      {currentStep === ProfileWizardEnum.CertifyingBoards && (
        <CertifyingBoards
          next={goToStep(ProfileWizardEnum.StateBoards)}
          skip={goToStep(ProfileWizardEnum.StateBoards)}
        />
      )}
      {currentStep === ProfileWizardEnum.StateBoards && (
        <StateBoards
          back={goToStep(ProfileWizardEnum.CertifyingBoards)}
          next={goToStep(ProfileWizardEnum.NPI)}
          skip={goToStep(ProfileWizardEnum.NPI)}
        />
      )}
      {currentStep === ProfileWizardEnum.NPI && (
        <NPIForm
          back={goToStep(ProfileWizardEnum.StateBoards)}
          next={goToStep(ProfileWizardEnum.Specialties)}
          skip={goToStep(ProfileWizardEnum.Specialties)}
        />
      )}
      {currentStep === ProfileWizardEnum.Specialties && (
        <Specialties back={goToStep(ProfileWizardEnum.NPI)} submit={submitUserProfile} />
      )}
    </>
  );
};
