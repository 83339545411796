import { ReactElement, ReactNode } from 'react';
import { Backdrop, createStyles, Fade, Modal as ModalComponent, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

// Styles
import styles from './Modal.module.scss';

// Types
interface IProps {
  children?: ReactNode;
  errorMessage?: string;
  isOpen: boolean;
  onClose?(): void;
  title?: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export const Modal = (props: IProps): ReactElement => {
  const { children, isOpen, errorMessage, onClose, title } = props;
  const { modal } = useStyles();

  const handleClose = (): void => {
    // Optional callback.
    onClose?.();
  };

  return (
    <ModalComponent
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={styles.wrapper}>
          {/* Error message */}
          {errorMessage && <div className={styles.error}>{errorMessage}</div>}

          {/* Header content */}
          <div className={styles.header}>
            {title && <h2 id="transition-modal-title">{title}</h2>}
            <IconButton className={styles.close} title="Close" onClick={handleClose}>
              <Close />
            </IconButton>
          </div>

          {/* Main content */}
          <div className={styles['content-wrapper']}>{children}</div>
        </div>
      </Fade>
    </ModalComponent>
  );
};
