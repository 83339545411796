import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import classNames from 'classnames';
import { useField } from 'formik';
import { ReactElement } from 'react';

// Styles
import styles from '../FormikFields.module.scss';

export type FormikInputFieldProps = {
  formikKey: string;
  label?: string;
  readOnly?: boolean;
  noOffset?: boolean;
  placeholder?: string;
  startIcon?: ReactElement;
} & TextFieldProps;

export const FormikInputField = (props: FormikInputFieldProps): ReactElement => {
  const { formikKey, label, onChange, placeholder, readOnly, required, startIcon, ...rest } = props;
  const [field, meta] = useField<string>(formikKey);
  const { name } = field;
  const { error, touched } = meta;
  const inputClass = classNames({
    [styles.offset]: !label,
    [styles.readOnly]: readOnly,
  });

  const handleChange = (e) => {
    field.onChange(e);
    onChange?.(e);
  };

  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label} htmlFor={field.name}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <TextField
        {...field}
        {...rest}
        className={inputClass}
        error={(touched && !!error) || props.error}
        helperText={(meta ? error : '') || props.helperText}
        id={field.name}
        InputProps={{
          startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
          readOnly,
          value: field.value || '',
        }}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        variant="outlined"
      />
    </div>
  );
};
