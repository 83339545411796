import classNames from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Hooks.
import { useClickEvent, useTaxonomyFooter } from 'hooks';

// Store + Core
import { PARSApiModelsTaxonomyTerm } from 'core/client';

// Styles.
import styles from './Footer.module.scss';

export const Footer = (): ReactElement => {
  const year = new Date().getFullYear();
  const footerTerms = useTaxonomyFooter();
  const fireEvent = useClickEvent({ Event: 'navigation', EventCategory: 'Navigation' });

  return (
    <footer className={classNames(styles.footer)}>
      <div className={styles['footer-bottom']}>
        <div className={styles['footer-left']}>© {year} Accreditation Council for Continuing Medical Education</div>
        <div className={styles['footer-right']}>
          {footerTerms?.map(
            (term: Pick<PARSApiModelsTaxonomyTerm, 'description' | 'name'>): ReactElement => {
              const { description, name } = term;

              // Is external if the route starts with '/' it should be handled by ReactRouter.
              const isExternal = !!description.indexOf('/', 0);

              return isExternal ? (
                <a
                  href={description}
                  key={description}
                  onClick={() => fireEvent({ EventAction: 'Footer', EventLabel: description })}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>{name}</span>
                </a>
              ) : (
                <Link
                  key={description}
                  onClick={() => fireEvent({ EventAction: 'Footer', EventLabel: description })}
                  to={description}
                >
                  <span>{name}</span>
                </Link>
              );
            },
          )}
        </div>
      </div>
    </footer>
  );
};
