import { ReactElement, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AddRounded, RemoveRounded } from '@material-ui/icons';

// Components
import { Checkboxes } from './Checkboxes';
import { BooleanCheckboxes } from './BooleanCheckboxes';
import { Date } from './Date';
import { RangeCheckboxes } from './RangeCheckboxes';
import { TextInput } from './TextInput';
import { TypeaheadFacet } from './Typeahead';

// Store + Core
import { Facet } from 'core/models';
import { IFacetAccordion } from 'core/constants';
import { FacetTypeEnum } from 'core/enums';

// Types
import { SearchRequest } from '../../types';

interface IProps {
  defaultFacets: IFacetAccordion[];
  facets: Facet;
  searchRequest: SearchRequest;
}

const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      '&:before': {
        display: 'none',
      },
      background: 'transparent',
      borderRadius: '0 !important',
      boxShadow: 'none',
      padding: 0,
    },
    details: {
      flexDirection: 'column',
    },
    summary: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      padding: 0,
    },
  }),
);

export const FacetsAccordion = (props: IProps): ReactElement => {
  const { defaultFacets, facets, searchRequest } = props;
  const [expanded, setExpanded] = useState<string[]>([]);

  // MUI Styles.
  const classes = useStyles();

  return (
    <>
      {defaultFacets.map(
        (facet: IFacetAccordion): ReactElement => {
          const { displayValue, responseValue, type } = facet;
          const isDisabled: boolean = facets?.[responseValue]?.length === 0 || !facets;
          const isExpanded = !!expanded.find((value: string): boolean => value === displayValue);

          const onToggleExpand = (): void =>
            !isExpanded
              ? setExpanded([...expanded, displayValue])
              : setExpanded(expanded.filter((_: string): boolean => _ !== displayValue));

          const expandIcon: ReactElement = isExpanded ? <RemoveRounded /> : <AddRounded />;

          const commonProps = {
            classes,
            expandIcon,
            facet,
            facets,
            isExpanded,
            onToggleExpand,
            searchRequest,
          };

          const componentMap = {
            [FacetTypeEnum.Array]: <Checkboxes {...commonProps} isDisabled={isDisabled} />,
            [FacetTypeEnum.Boolean]: <BooleanCheckboxes {...commonProps} isDisabled={isDisabled} />,
            [FacetTypeEnum.Range]: <RangeCheckboxes {...commonProps} isDisabled={isDisabled} />,
            [FacetTypeEnum.Date]: <Date {...commonProps} isDisabled={false} />,
            [FacetTypeEnum.Text]: <TextInput {...commonProps} isDisabled={false} />,
            [FacetTypeEnum.Typeahead]: (
              <TypeaheadFacet {...commonProps} isDisabled={isDisabled || !facets?.[responseValue]} />
            ),
          };

          return componentMap?.[type] || null;
        },
      )}
    </>
  );
};
