import { CACHE_TAXONOMY_TERM_PREFIX, CACHE_TAXONOMY_TERM_EXPIRY_MINS } from './../core/constants';
import { CacheService } from '.';
import { ITaxonomyTerm, TaxonomyApiService } from 'core';

export class TaxonomyService {
  private taxonomyApiService: TaxonomyApiService;

  constructor() {
    this.taxonomyApiService = new TaxonomyApiService();
  }

  public async getByIdAsync(termId: string): Promise<ITaxonomyTerm> {
    const cacheKey = `${CACHE_TAXONOMY_TERM_PREFIX}${termId}`;
    const cachedTerm = CacheService.get<ITaxonomyTerm>(cacheKey);
    if (cachedTerm) {
      return cachedTerm;
    }

    const term = await this.taxonomyApiService.getTermById(termId);
    TaxonomyService.sortChildren(term);
    CacheService.set(cacheKey, term, CACHE_TAXONOMY_TERM_EXPIRY_MINS);
    return term;
  }

  private static sortChildren(taxonomyTerm: ITaxonomyTerm): void {
    taxonomyTerm.terms = (taxonomyTerm.terms || []).sort((a, b) => ((a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : -1));
    taxonomyTerm.terms.forEach(TaxonomyService.sortChildren);
  }
}
