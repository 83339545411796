// Libs
import { createSelector } from 'reselect';
import keys from 'lodash/keys';

// Core.
import {
  IBoard,
  IDictionary,
  ILearnerResult,
  ILearnerSearchResult,
  ILearnerSearchStateProps,
  IProfileCME,
  IUserProfile,
} from 'core';

// Store
import { AppState } from 'store';

// Types
import { IFacetProps } from 'components/Facets/types';
import { ProfileState } from './types';

export const profileSelector = ({ profile }: AppState): ProfileState => profile;

export const userProfileSelector = createSelector(profileSelector, ({ userProfile }: ProfileState) => userProfile);
export const userProfileFullNameSelector = createSelector(userProfileSelector, (userProfile: IUserProfile) => {
  const { lastName } = userProfile || {};
  if (lastName) return `Dr. ${lastName}`;
  return 'Doctor';
});

export const getCertifyingBoardsSelector = ({ profile }: AppState): IBoard[] => profile.boards;

export const specialtiesSelector = ({ profile }: AppState): IDictionary<string> => profile.specialties;

export const userProfileLoadingSelector = createSelector(
  profileSelector,
  ({ isUserProfileLoading }) => isUserProfileLoading,
);
export const userProfileHasErrorSelector = createSelector(profileSelector, ({ error }) => !!error);
export const userProfileCMELoadingSelector = createSelector(
  profileSelector,
  ({ isUserProfileCMELoading }) => isUserProfileCMELoading,
);
export const userProfileRecentCMELoadingSelector = createSelector(
  profileSelector,
  ({ isUserProfileRecentCMELoading }) => isUserProfileRecentCMELoading,
);
export const userProfileSearchLoadingSelector = createSelector(
  profileSelector,
  ({ isUserProfileSearchLoading }) => isUserProfileSearchLoading,
);
export const userProfileSearchStatePropsSelector = createSelector(
  profileSelector,
  ({ searchStateProps }) => searchStateProps,
);
export const userProfileSearchLearnerCurrentPageSelector = createSelector(
  userProfileSearchStatePropsSelector,
  ({ page }) => page,
);
export const userProfileSearchRequestSelector = createSelector(profileSelector, ({ searchRequest }) => searchRequest);
export const userProfileSearchResponseSelector = createSelector(
  profileSelector,
  ({ searchResponse }): ILearnerSearchResult => searchResponse,
);

export const userProfileIsClaimedSelector = createSelector(
  userProfileSelector,
  ({ isClaimed }): boolean => !!isClaimed,
);
export const userProfileHasCompletedWizardSelector = createSelector(
  userProfileSelector,
  ({ hasCompletedWizard }) => hasCompletedWizard,
);

export const userProfileSpecialtyIdsSelector = createSelector(
  profileSelector,
  ({ userProfile }: ProfileState) => userProfile?.specialtyIds,
);

export const userProfileLearnerSelectedLearnerActivitiesSelector = createSelector(
  userProfileSearchStatePropsSelector,
  (activity: ILearnerSearchStateProps) => activity.selectedActivities || [],
);

export const selectedCompletionIdsSelector = createSelector(
  userProfileLearnerSelectedLearnerActivitiesSelector,
  (activities: ILearnerResult[]) => activities.map(({ key }: ILearnerResult): string => key),
);
export const userProfileLearnerSearchTotalCountSelector = createSelector(
  userProfileSearchResponseSelector,
  (searchResponse: ILearnerSearchResult) => searchResponse?.totalCount,
);
export const userProfileFacetsSelector = createSelector(
  userProfileSearchResponseSelector,
  (searchResponse: ILearnerSearchResult): IFacetProps[] => searchResponse?.facets,
);

export const userProfileCMESelector = createSelector(profileSelector, ({ cme }) => cme);
export const userProfileRecentCMESelector = createSelector(profileSelector, ({ recentCme }) => recentCme);

export const userProfileAnnualCreditsSelector = createSelector(
  userProfileCMESelector,
  (cme: IProfileCME) => cme?.annualCredits,
);
export const userProfileHasAnnualCreditsSelector = createSelector(
  userProfileAnnualCreditsSelector,
  (annualCredits) => !!keys(annualCredits).length,
);
