import { ReactElement } from 'react';
import { AddRounded, CheckCircleRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import styles from '../MultiTypeahead.module.scss';

// Types
import { ITypeaheadOption } from '../../Typeahead';

const useStyles = makeStyles({
  listIcon: {
    '.ja &': {
      color: '#00aea9',
    },
    border: 0,
    borderRadius: '50%',
    boxShadow: '1px 3px 8px 2px #00000019',
    color: '#82bc00',
    height: '1.5rem',
    marginRight: '0.875rem',
    minWidth: '1.5rem',
    width: '1.5rem',
  },
  listSelectedIcon: {
    '.ja &': {
      color: '#00aea9',
    },
    color: '#82bc00',
    marginRight: '0.875rem',
  },
});

interface IProps {
  option: ITypeaheadOption;
  selected: boolean;
}

export const InputOption = (props: IProps): ReactElement => {
  const { option, selected } = props;
  const { label } = option;
  const classes = useStyles();

  return (
    <>
      {selected ? (
        <CheckCircleRounded classes={{ root: classes.listSelectedIcon }} />
      ) : (
        <AddRounded classes={{ root: classes.listIcon }} />
      )}
      <div className={styles['option-content']}>
        <div className={styles['option-content-label']}>{label}</div>
      </div>
    </>
  );
};
