/* eslint-disable @typescript-eslint/no-explicit-any */
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseButton, { ButtonProps } from '@material-ui/core/Button';
import classnames from 'classnames';
import { ElementType, ReactElement, ReactNode } from 'react';

// Core.
import { ButtonVariant } from 'core';

// Styles.
import styles from './Button.module.scss';

// This ensures the Button component should allow all props required by the provided `component` prop.
// https://material-ui.com/guides/typescript/#usage-of-component-prop
export type IButtonProps<C extends ElementType = any> = Omit<ButtonProps<C, { component?: C }>, 'variant'> & {
  isSubmitting?: boolean;
  startIcon?: ReactElement;
  variant?: ButtonVariant;
};

export const Button = (props: IButtonProps): ReactElement => {
  const { children, className, disabled, disableRipple, isSubmitting, startIcon, variant, ...rest } = props;
  const buttonClass: string = classnames(styles.button, styles[variant], { [styles.disabled]: disabled }, className);

  return (
    <BaseButton
      {...rest}
      className={buttonClass}
      disabled={disabled}
      disableRipple={disableRipple || [ButtonVariant.Link, ButtonVariant.Hyperlink].includes(variant)}
      startIcon={(isSubmitting && <CircularProgress color="inherit" size="1rem" />) || startIcon}
    >
      {children}
    </BaseButton>
  );
};

export interface IButtonRow {
  children: ReactNode;
  className?: string;
}

export const ButtonRow = ({ children, className }: IButtonRow): ReactElement => {
  const rowClass = classnames(styles.row, className);
  return <div className={rowClass}>{children}</div>;
};
