// Libs
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { Grid, RadioGroup } from '@material-ui/core';

// Components
import { FormikBooleanRadioField } from 'components/forms';
import { ButtonRow } from 'components/ButtonRow';

// Core
import { IUserProfile } from 'core';

// Store
import { updateUserProfile } from 'store/profile/actions';
import { userProfileSelector } from 'store/profile/selectors';

// Styles
import styles from '../../AccountSettings.module.scss';

interface IProps {
  onClose: VoidFunction;
}

export const EditNotifications = ({ onClose }: IProps): ReactElement => {
  const dispatch = useDispatch();

  // Selectors.
  const userProfile: IUserProfile = useSelector(userProfileSelector);

  const formik = useFormik<IUserProfile>({
    initialValues: userProfile,
    onSubmit: async (values: IUserProfile): Promise<void> => {
      await dispatch(updateUserProfile(values));
      onClose();
    },
  });

  const { handleSubmit, isSubmitting, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <RadioGroup aria-labelledby="notification-label" row>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.caption} id="notification-label">
                Would you like to receive weekly emails reporting new CME credits?
              </div>
            </Grid>
            <Grid item xs={2}>
              <FormikBooleanRadioField formikKey="isOptedOutOfNotifications" value={false} name="Yes" />
            </Grid>
            <Grid item xs={2}>
              <FormikBooleanRadioField formikKey="isOptedOutOfNotifications" value name="No" />
            </Grid>
          </Grid>
        </RadioGroup>
        <ButtonRow
          firstButtonText="Save"
          isPrimaryDisabled={isSubmitting || !isValid || !dirty}
          isSecondaryDisabled={isSubmitting}
          isShowProgress={isSubmitting}
          onCancel={onClose}
          secondButtonText="Cancel"
          type="submit"
        />
      </form>
    </FormikProvider>
  );
};
