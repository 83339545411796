import { MutableRefObject, useCallback, useEffect } from 'react';

export const useOnClickOutside = (elementRef: MutableRefObject<Element>, callback: VoidFunction): void => {
  const handleClickOutside = useCallback(
    (e) => {
      if (!elementRef?.current?.contains(e.target)) {
        callback();
      }
    },
    [elementRef, callback],
  );

  useEffect((): VoidFunction => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};
