import { FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Button } from 'components';
import { FacetsAccordion, FiltersSelected } from '../components';
import { FormikInputFieldWithSearch } from 'components/forms/FormikInputFieldWithSearch';

// Constants.
import { INITIAL_SEARCH_FACETS } from './constants';

// Hooks
import { useClickEvent } from 'hooks';

// Core + Store
import { ACCORDION_TITLES, ButtonVariant, Facet, IActivityLearnerSummarySearch, IActivitySearchRequest } from 'core';
import { getActivities, updateActivitySearchRequest } from 'store/activity/actions';
import { isLoadingSelector, searchFacetsSelector } from 'store/activity/selectors';

// Types
import { IInitialSearchFacet } from '../types';

// Styles.
import styles from '../FacetSearch.module.scss';

interface IProps {
  handleClose(): void;
  forcedFilter?: IActivityLearnerSummarySearch;
  initialValues: IInitialSearchFacet;
  searchRequest?: IActivitySearchRequest;
}

export const Facets = (props: IProps): ReactElement => {
  const { handleClose, forcedFilter, initialValues, searchRequest } = props;
  const dispatch = useDispatch();

  // Selectors.
  const facets: Facet = useSelector(searchFacetsSelector);
  const isLoading: boolean = useSelector(isLoadingSelector);
  const fireEvent = useClickEvent({ Event: 'ActivitySearchFilter', EventCategory: 'Search' });

  const formik = useFormik<IActivitySearchRequest>({
    initialValues,
    onSubmit: (values: IActivitySearchRequest): void => {
      window.scroll(0, 0);
      dispatch(updateActivitySearchRequest(values));
      dispatch(getActivities(forcedFilter));
      fireEvent({ Event: 'Facet Search', EventAction: 'Activity Filter', SearchPayload: values });
      handleClose();
    },
  });

  const { handleReset, handleSubmit, setValues, values } = formik;

  const onClearAll = async (e): Promise<void> => {
    window.scroll(0, 0);
    handleReset(e);
    await setValues(INITIAL_SEARCH_FACETS); // Reset searchText in formik.
    fireEvent({ Event: 'Clear Facets', EventAction: 'Clear Activity Filter', SearchPayload: INITIAL_SEARCH_FACETS });
    dispatch(updateActivitySearchRequest(INITIAL_SEARCH_FACETS)); // Reset searchText in redux.
    await dispatch(getActivities(forcedFilter));
  };

  const isDisabled = !facets && isLoading;

  return (
    <FormikProvider value={formik}>
      <form className={styles['summary-refiners']} onSubmit={handleSubmit}>
        <FormikInputFieldWithSearch
          disabled={isDisabled}
          formikKey="searchText"
          placeholder="Search Activity"
          title="Search activity"
        />
        <Button
          disabled={isDisabled}
          fullWidth
          variant={ButtonVariant.Primary}
          type="submit"
          className={styles['margin-top']}
        >
          Apply selected filter(s)
        </Button>
        {/* Filter pills */}
        <div className={styles.filters}>
          <div className={styles['filters-header']}>
            Filter By:
            <Button disabled={isDisabled} onClick={onClearAll} variant={ButtonVariant.Hyperlink}>
              Clear All
            </Button>
          </div>

          {/* Facets */}
          <div className={styles['filters-selected']}>
            {values && (
              <div className={styles['filters-selected']}>
                <FiltersSelected defaultFacets={ACCORDION_TITLES} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.refiners}>
          <FacetsAccordion defaultFacets={ACCORDION_TITLES} facets={facets} searchRequest={searchRequest} />
        </div>
        <Button disabled={isDisabled} fullWidth variant={ButtonVariant.Primary} type="submit">
          Apply selected filter(s)
        </Button>
      </form>
    </FormikProvider>
  );
};
