/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as baseCreateStore,
  PreloadedState,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

// Reducers.
import { activityReducer } from './activity/reducer';
import { onboardingReducer } from './onboarding/reducer';
import { preferencesReducer } from './preferences/reducer';
import { profileReducer } from './profile/reducer';
import { railReducer } from './rail/reducer';
import { statesAndProvincesReducer } from './statesAndProvinces/reducer';
import { taxonomyReducer } from './taxonomy/reducer';
import { toastReducer } from './toast/reducer';

const reducer = combineReducers({
  activity: activityReducer,
  onboarding: onboardingReducer,
  preferences: preferencesReducer,
  profile: profileReducer,
  rail: railReducer,
  states: statesAndProvincesReducer,
  taxonomy: taxonomyReducer,
  toast: toastReducer,
});

const { location, push } = createBrowserHistory();

export type AppState = ReturnType<typeof reducer>;

const middlewares = [thunk.withExtraArgument({ appRoute: location.pathname, push })];

// Hook up react developer tools if installed
const enhancedWindow: any = window;
const enhancer = enhancedWindow.devToolsExtension
  ? compose(applyMiddleware(...middlewares), enhancedWindow.__REDUX_DEVTOOLS_EXTENSION__())
  : compose(applyMiddleware(...middlewares));

export const createStore = (preloadedState?: PreloadedState<AppState>): Store<AppState> =>
  baseCreateStore(reducer, preloadedState, enhancer);
