import React, { ReactElement, useEffect } from 'react';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';

// Hooks.
import { useAuthLogin, useAuth, useClickEvent } from 'hooks';

// Components.
import { Button } from 'components';
import { ButtonVariant } from 'core';

// Styles.
import styles from './LoginPage.module.scss';

export const LoginPage = (): ReactElement => {
  const [, { isAuthenticated, isLoaded }] = useAuth();
  const login = useAuthLogin();
  const [showButton, setShowButton] = React.useState(false);
  const fireEvent = useClickEvent({ Event: 'navigation', EventCategory: 'Navigation' });

  // if isLoaded never gets set to true, show log in button for manual redirect
  useEffect(() => {
    setTimeout(function () {
      setShowButton(true);
    }, 10000);
  }, []);

  useEffect(() => {
    if (!isAuthenticated && isLoaded) {
      login();
    }
  }, [isAuthenticated, isLoaded]);

  if (isAuthenticated && isLoaded) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className={styles.content}>
      {showButton ? (
        <>
          <section className={styles.container}>
            <h2>If you haven't been redirected to the login page, please click the button below to login.</h2>
            <Button
              className={styles.button}
              variant={ButtonVariant.Primary}
              onClick={() => {
                fireEvent({ EventAction: 'Header', EventLabel: 'Log In' });
                login();
              }}
              title="Log In"
            >
              Log In
            </Button>
          </section>
        </>
      ) : (
        <CircularProgress className={styles.loading} />
      )}
    </div>
  );
};
