import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import moment, { Moment } from 'moment';
import { ReactElement, useMemo } from 'react';

// Styles.
import styles from '../FormikFields.module.scss';

// Types.
import { FormikDatePickerProps, TimesOfDay } from '../types';
import { DEFAULT_TIMEZONE } from 'core';

export const FormikDatePicker = (props: FormikDatePickerProps): ReactElement => {
  const { field, format, formikKeyDate, label, onChange, required, timeOfDay, views, ...datePickerProps } = props;
  const { errors, setFieldValue, touched } = useFormikContext();
  const helperText = useMemo(() => get(errors, formikKeyDate), [formikKeyDate, errors]);
  const isTouched = useMemo(() => get(touched, formikKeyDate, false), [formikKeyDate, touched]);

  // Set field values to set based on formikKeys.
  const handleChange = (date: Moment, rawValue: string): void => {
    let dateValue: string | number;
    let finalDate: Moment = date;

    // Some date pickers are month/day. We need to account for a user typing a leap-year date.
    // This code below will get skipped if a user enters a full date because the value + format won't match.
    if (rawValue === '02/29' && format === 'MM/DD') {
      finalDate = moment.tz('2020-02-29', DEFAULT_TIMEZONE);
    }

    if (views?.includes('year')) {
      dateValue = Number(finalDate?.year());
    } else {
      if (finalDate) {
        // We send/save ISO string to the server, adjusting the time of day as needed
        const dateToSave = moment(finalDate).tz(DEFAULT_TIMEZONE).startOf('day');
        switch (timeOfDay) {
          case TimesOfDay.Noon:
            dateToSave.set('hour', 12);
            break;
          case TimesOfDay.EndOfDay:
            dateToSave.set('hour', 23).set('minute', 59);
            break;
          case TimesOfDay.Midnight:
          default:
            dateToSave.startOf('day');
            break;
        }
        dateValue = dateToSave?.toISOString() || dateToSave?.toString();
      } else {
        dateValue = finalDate?.toString();
      }
    }
    setFieldValue(field?.name, dateValue, true);
    onChange?.(field?.name, dateValue);
  };

  // Convert the date from ISO UTC to `DEFAULT_TIMEZONE` on display.
  const displayValue = (date: Date | undefined): string | number => {
    let dateValue: string | number;

    if (views?.includes('year')) {
      dateValue = date?.toString();
    } else {
      dateValue = moment(date).tz(DEFAULT_TIMEZONE).toString();
    }

    return dateValue;
  };

  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...datePickerProps}
          autoOk
          disableToolbar
          error={isTouched && !!helperText}
          format={format}
          helperText={helperText}
          InputAdornmentProps={{ position: 'start' }}
          inputVariant="outlined"
          onChange={handleChange}
          value={field?.value ? displayValue(field?.value) : null}
          variant="inline"
          views={views}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
