import { useMediaQuery } from '@material-ui/core';
import { FilterListRounded } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import noop from 'lodash/noop';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components.
import { BulkActions, Button, Facets, LearnerResultCard, NoResultsCard, Rail, Summary } from 'components';
import { INITIAL_SEARCH_FACETS } from 'components/Facets/ActivitySearchFacets/constants';
import { SearchRequest } from 'components/Facets/types';
import { LoadingCards } from 'components/LoadingCards';

// Core.
import {
  BreakpointsEnum,
  ButtonVariant,
  IActivitySearchRequest,
  IActivitySearchResponse,
  ILearnerActivity,
  SORT_BY_ACTIVITY_ID,
  SORT_BY_A_Z_TITLE,
  SORT_BY_CLAIM_DATE,
  SORT_BY_END_DATE,
  SORT_BY_INTERNAL_ID,
  SORT_BY_RECENTLY_ADDED,
  SORT_BY_START_DATE,
} from 'core';

// Helpers.
import { exportMenuItems } from './exportMenuItems';
import { perPageMenuItems } from './perPageMenuItems';
import { sortLearnerMenu } from './sortMenuItems';

// Hooks.
import { useLoadEvent, useSearchPayloadFromQueryString } from 'hooks';

// Store.
import {
  activitySearchCurrentPageSelector,
  isLoadingSelector,
  searchRequestSelector,
  searchResponseSelector,
  searchResultsSelector,
  searchTotalCountSelector,
} from 'store/activity/selectors';
import { closeRail, openRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';

// Mock Data.
import { getActivities, onUpdateActivitiesPaginationState } from 'store/activity/actions';

interface IActivitySearchParams {
  curatedQuery?: string;
}

export const ActivitySearchPage = (): ReactElement => {
  const { curatedQuery = '' } = useParams<IActivitySearchParams>();
  const dispatch = useDispatch();
  const isMdBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);
  const isLoading: boolean = useSelector(isLoadingSelector);
  const isFacetsOpen: boolean = useSelector(isRailOpenSelector);
  const currentPage: number = useSelector(activitySearchCurrentPageSelector);
  const searchActivityTotalCount: number = useSelector(searchTotalCountSelector);
  const searchRequest: IActivitySearchRequest = useSelector(searchRequestSelector);
  const searchResponse: IActivitySearchResponse = useSelector(searchResponseSelector);
  const activities: ILearnerActivity[] = useSelector(searchResultsSelector);
  const urlSearchPayload: SearchRequest = useSearchPayloadFromQueryString();
  const fullSearchPayload: IActivitySearchRequest = useMemo(() => ({ ...urlSearchPayload, curatedQuery }), [
    urlSearchPayload,
    curatedQuery,
  ]);

  const fireLoadEvent = useLoadEvent({ PageType: 'Search Results Page' });

  // Execute initial search request.
  useEffect((): void => {
    dispatch(getActivities(fullSearchPayload));
  }, [dispatch, fullSearchPayload]);

  // Sorting functions.
  const perPage = useCallback((top: number) => dispatch(getActivities({ top })), [dispatch]);
  const sortByActivityId = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_ACTIVITY_ID })), [dispatch]);
  const sortByClaimDate = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_CLAIM_DATE })), [dispatch]);
  const sortByEndDate = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_END_DATE })), [dispatch]);
  const sortByInternalId = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_INTERNAL_ID })), [dispatch]);
  const sortByStartDate = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_START_DATE })), [dispatch]);
  const sortByTimestamp = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_RECENTLY_ADDED })), [dispatch]);
  const sortByTitle = useCallback(() => dispatch(getActivities({ sortBy: SORT_BY_A_Z_TITLE })), [dispatch]);

  // Pagination functions.
  const goToPage = useCallback(
    async (_, newPage: number) => {
      await dispatch(onUpdateActivitiesPaginationState({ page: newPage }));
      window.scroll(0, 0);
    },
    [dispatch],
  );

  const toggleSortAscending = useCallback(
    () => dispatch(getActivities({ sortAscending: !searchRequest.sortAscending })),
    [dispatch, searchRequest],
  );

  const handleCloseFacets = useCallback(() => {
    dispatch(closeRail());
  }, [dispatch]);

  // Export functions.
  const onExportAllLearners = noop;
  const onExportFilteredLearners = noop;

  // Show this when we're waiting for results.
  const isPending = useMemo(() => isLoading && <LoadingCards />, [isLoading]);

  // Show this when we have results.
  const hasResults =
    !!activities?.length &&
    activities.map(
      (learnerActivity: ILearnerActivity): ReactElement => (
        <LearnerResultCard key={learnerActivity.id} learnerActivity={learnerActivity} />
      ),
    );

  // Show this when we don't have any results.
  const noResults: ReactElement = !activities?.length && <NoResultsCard />;

  // Pagination count selected.
  const paginationCount: number =
    searchResponse && searchRequest?.top ? Math.ceil(searchActivityTotalCount / searchRequest.top) : 1;

  useEffect(() => {
    if (!isPending) {
      fireLoadEvent({ SearchResultsNum: searchActivityTotalCount });
    }
  }, [fireLoadEvent, isPending, searchActivityTotalCount]);

  return (
    <>
      <Rail handleClose={handleCloseFacets} isOpen={isFacetsOpen}>
        <Facets handleClose={handleCloseFacets} initialValues={{ ...INITIAL_SEARCH_FACETS, ...fullSearchPayload }} />
      </Rail>
      <Summary>
        <div className="form-title-container">
          <h1 className="title">Activity Search Results</h1>
        </div>

        {/* Render the facets for mobile. */}
        {!isMdBreakpoint && (
          <div className="summary-refiners">
            <Button onClick={() => dispatch(openRail())} variant={ButtonVariant.Filter}>
              <FilterListRounded /> Add Filters
            </Button>
          </div>
        )}

        {/* Filters the main content. */}
        <BulkActions
          count={searchActivityTotalCount}
          countLabel="Activities"
          downloadMenuProps={exportMenuItems({
            onExportAllLearners,
            onExportFilteredLearners,
          })}
          isSingleOrNoResult={searchActivityTotalCount < 2}
          perPageMenuProps={perPageMenuItems({ perPage, searchRequest })}
          sortAscending={searchRequest?.sortAscending}
          sortAscendingAction={toggleSortAscending}
          sortMenuProps={sortLearnerMenu({
            sortBy: searchRequest?.sortBy,
            sortByActivityId,
            sortByClaimDate,
            sortByEndDate,
            sortByInternalId,
            sortByStartDate,
            sortByTimestamp,
            sortByTitle,
          })}
        />

        {/* Render the main content. */}
        {isPending || hasResults || noResults}

        {/* Render pagination. */}
        {!isPending && !isLoading && <Pagination count={paginationCount} onChange={goToPage} page={currentPage} />}
      </Summary>
    </>
  );
};
