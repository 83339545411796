import { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useFormikContext } from 'formik';
import omit from 'lodash/omit';
import moment from 'moment';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';

// Components
import { Button } from 'components';

// Store + Core
import { IFacetAccordion } from 'core/constants';
import { ButtonVariant, FacetTypeEnum } from 'core/enums';

// Types
import { SearchRequest } from '../../types';

interface IProps {
  callback?(): void;
  defaultFacets: IFacetAccordion[];
}

const DATE_FORMAT = 'MMM Do, YYYY';

export const FiltersSelected = (props: IProps): ReactElement => {
  const { callback, defaultFacets } = props;
  const { values, setFieldValue, setValues } = useFormikContext<SearchRequest>();

  const onRemoveArrayValue = ({
    payloadValue,
    value,
  }: Pick<IFacetAccordion, 'payloadValue'> & { value: string }): void => {
    const payloadValues = values[payloadValue];

    // If the length in formik values is 1, remove the object from `values`, otherwise just remove the one from the array.
    payloadValues.length === 1
      ? setValues(omit(values, payloadValue))
      : setFieldValue(
          payloadValue,
          payloadValues.filter((val: string): boolean => val !== value),
        );

    // API call.
    callback?.();
  };

  const onRemoveBooleanValue = ({ payloadValue }: Pick<IFacetAccordion, 'payloadValue'>): void => {
    setValues(omit(values, payloadValue));

    // API call.
    callback?.();
  };

  const onRemoveTextValue = ({ payloadValue }: Pick<IFacetAccordion, 'payloadValue'>): void => {
    setValues(omit(values, payloadValue));

    // API call.
    callback?.();
  };

  const onRemoveStartDate = ({ payloadStartValue }: Pick<IFacetAccordion, 'payloadStartValue'>): void => {
    setValues(omit(values, payloadStartValue));

    // API call.
    callback?.();
  };

  const onRemoveEndDate = ({ payloadEndValue }: Pick<IFacetAccordion, 'payloadEndValue'>): void => {
    setValues(omit(values, payloadEndValue));

    // API call.
    callback?.();
  };

  const onRemoveRangeValue = ({
    payloadValue,
    value,
  }: Pick<IFacetAccordion, 'payloadValue'> & { value: number[] }): void => {
    const rangeValues = values[payloadValue];
    setFieldValue(
      payloadValue,
      rangeValues.filter((range) => range[0] !== value[0]),
    );

    // API call.
    callback?.();
  };

  return (
    <>
      {defaultFacets.map(
        (facet: IFacetAccordion): ReactElement => {
          const { displayValue, type, payloadValue, payloadEndValue, payloadStartValue } = facet;

          switch (type) {
            case FacetTypeEnum.Array:
              // Check to make sure the value is an array for queryStrings.
              if (!isArray(values?.[payloadValue])) return null;

              return values?.[payloadValue]?.map(
                (value: string, idx: number): ReactElement => (
                  <Button
                    key={`${payloadValue}-${idx}`}
                    onClick={(): void => onRemoveArrayValue({ payloadValue, value })}
                    variant={ButtonVariant.Pill}
                  >
                    {value} <CloseIcon />
                  </Button>
                ),
              );
            case FacetTypeEnum.Boolean:
              // Check to make sure the value is a boolean for queryStrings.
              if (!isBoolean(values?.[payloadValue])) return null;

              return (
                values?.[payloadValue] !== undefined && (
                  <Button
                    key={payloadValue}
                    onClick={(): void => onRemoveBooleanValue({ payloadValue })}
                    variant={ButtonVariant.Pill}
                  >
                    {displayValue}: {values[payloadValue] ? 'Yes' : 'No'} <CloseIcon />
                  </Button>
                )
              );
            case FacetTypeEnum.Date: {
              return (
                <>
                  {values?.[payloadStartValue] && (
                    <Button
                      key={values[payloadStartValue]}
                      onClick={(): void => onRemoveStartDate({ payloadStartValue })}
                      variant={ButtonVariant.Pill}
                    >
                      Start {displayValue}: {moment(values[payloadStartValue]).format(DATE_FORMAT)} <CloseIcon />
                    </Button>
                  )}
                  {values?.[payloadEndValue] && (
                    <Button
                      key={values[payloadEndValue]}
                      onClick={(): void => onRemoveEndDate({ payloadEndValue })}
                      variant={ButtonVariant.Pill}
                    >
                      End {displayValue}: {moment(values[payloadEndValue]).format(DATE_FORMAT)} <CloseIcon />
                    </Button>
                  )}
                </>
              );
            }
            case FacetTypeEnum.Range: {
              return values?.[payloadValue]?.map((value: number[]) => {
                const [lowerBound, upperBound] = value;
                const label = upperBound ? `${lowerBound}-${upperBound}` : `${lowerBound}+`;

                return (
                  <Button
                    key={`${lowerBound}-${upperBound}`}
                    onClick={(): void => onRemoveRangeValue({ payloadValue, value })}
                    variant={ButtonVariant.Pill}
                  >
                    {label} {displayValue}
                    <CloseIcon />
                  </Button>
                );
              });
            }
            case FacetTypeEnum.Text: {
              return (
                <>
                  {values?.[payloadValue] && (
                    <Button
                      key={values[payloadStartValue]}
                      onClick={(): void => onRemoveTextValue({ payloadValue })}
                      variant={ButtonVariant.Pill}
                    >
                      {`${displayValue}: ${values[payloadValue]}`}
                      <CloseIcon />
                    </Button>
                  )}
                </>
              );
            }
            case FacetTypeEnum.Typeahead: {
              // If it's a string, remove the value.
              if (!isArray(values?.[payloadValue])) {
                return (
                  <>
                    {values?.[payloadValue] && (
                      <Button
                        key={values[payloadStartValue]}
                        onClick={(): void => onRemoveTextValue({ payloadValue })}
                        variant={ButtonVariant.Pill}
                      >
                        {`${displayValue}: ${values[payloadValue]}`}
                        <CloseIcon />
                      </Button>
                    )}
                  </>
                );
              }
              // Else, it's an array. Go through and remove the correct one.
              return (
                <>
                  {values?.[payloadValue]?.map(
                    (value: string, idx: number): ReactElement => (
                      <Button
                        key={`${payloadValue}-${idx}`}
                        onClick={(): void => onRemoveArrayValue({ payloadValue, value })}
                        variant={ButtonVariant.Pill}
                      >
                        {value} <CloseIcon />
                      </Button>
                    ),
                  )}
                </>
              );
            }
            default:
              return null;
          }
        },
      )}
    </>
  );
};
