import { Box, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement } from 'react';

const useStyles = makeStyles({
  root: {
    height: '50vh',
  },
});

export const NoResultsCard = (): ReactElement => {
  const classes = useStyles();

  return (
    <Container>
      <Box m={3} mt={5}>
        <Paper classes={classes}>
          <Box display="flex" alignItems="center" justifyContent="center" css={{ height: '100%' }}>
            <Typography>No Results</Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
