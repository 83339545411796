import { ReactElement } from 'react';

// Components
import { Welcome } from './Welcome';
import { Search } from './Search';
import { Featured } from './Featured';

// Globals.
import background from 'globals/images/cmeHero.jpg';

// Styles
import styles from './index.module.scss';

export const Hero = (): ReactElement => {
  return (
    // Must have cmeHero image
    <div className={styles.hero} style={{ backgroundImage: `url(${background})` }}>
      <Welcome />
      <Search />
      <Featured />
    </div>
  );
};
