import { Reducer } from 'redux';

// Types.
import {
  IS_LICENSED_IN_UNITED_STATES,
  ONBOARDING_CODE_FAILURE,
  ONBOARDING_CODE_REQUEST,
  ONBOARDING_CODE_SUCCESS,
  ONBOARDING_CODE_VALIDATE_FAILURE,
  ONBOARDING_CODE_VALIDATE_REQUEST,
  ONBOARDING_CODE_VALIDATE_SUCCESS,
  ONBOARDING_LEARNER_MATCHED_FAILURE,
  ONBOARDING_LEARNER_MATCHED_REQUEST,
  ONBOARDING_LEARNER_MATCHED_SUCCESS,
  ONBOARDING_REGISTER_FAILURE,
  ONBOARDING_REGISTER_REQUEST,
  ONBOARDING_REGISTER_SUCCESS,
  ONBOARDING_REQUEST_RESET,
  ONBOARDING_REQUEST_UPDATED,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UserState,
} from './types';
import { IOnboardingMatchLearner } from 'core';

export const initialState: UserState = {
  isAbleToMatch: false,
  isCodeSent: false,
  isCodeVerified: false,
  isLicensedInUnitedStates: undefined, // Keep as `undefined` for the onboarding flow.
  isLoading: false,
  isRegistered: false,
  matchFailureCount: 0,
  onboardingRequest: {} as IOnboardingMatchLearner,
};

export const onboardingReducer: Reducer<UserState> = (state = initialState, action): UserState => {
  switch (action.type) {
    /******************/
    case ONBOARDING_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ONBOARDING_CODE_SUCCESS:
      return {
        ...state,
        isCodeSent: true,
        isLoading: false,
      };
    case ONBOARDING_CODE_FAILURE:
      return {
        ...state,
        isCodeSent: false,
        isLoading: false,
      };

    /******************/
    case ONBOARDING_CODE_VALIDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCodeVerified: false,
      };
    case ONBOARDING_CODE_VALIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCodeVerified: true,
      };
    case ONBOARDING_CODE_VALIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCodeVerified: false,
      };

    /******************/
    case ONBOARDING_LEARNER_MATCHED_REQUEST:
      return {
        ...state,
        isAbleToMatch: false,
        isLoading: true,
        onboardingRequest: {
          ...state.onboardingRequest,
          ...action.payload,
        },
      };
    case ONBOARDING_LEARNER_MATCHED_SUCCESS:
      return {
        ...state,
        isAbleToMatch: true,
        isLoading: false,
        onboardingRequest: {
          ...state.onboardingRequest,
          ...action.payload,
        },
      };
    case ONBOARDING_LEARNER_MATCHED_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAbleToMatch: false,
        matchFailureCount: state.matchFailureCount + 1,
      };

    /******************/
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        onboardingRequest: {
          ...state.onboardingRequest,
          ...action.payload,
        },
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLicensedInUnitedStates: false,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    /******************/
    case ONBOARDING_REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isRegistered: false,
      };
    case ONBOARDING_REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRegistered: true,
        onboardingRequest: {
          ...state.onboardingRequest,
          ...action.payload,
        },
      };
    case ONBOARDING_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isRegistered: false,
      };

    /******************/
    case IS_LICENSED_IN_UNITED_STATES:
      return {
        ...state,
        isLicensedInUnitedStates: action.payload,
      };
    case ONBOARDING_REQUEST_RESET:
      return initialState;

    case ONBOARDING_REQUEST_UPDATED:
      return {
        ...state,
        isLoading: true,
        onboardingRequest: {
          ...state.onboardingRequest,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
