import { ReactElement } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// Components
import { Button, FacetsAccordion, FiltersSelected } from 'components';

// Core + Store
import { ButtonVariant, Facet, ILearnerSearchRequest, TRANSCRIPT_ACCORDION_TITLES } from 'core';
import { getUserProfileSearch, updateProfileSearch } from 'store/profile/actions';
import { userProfileFacetsSelector } from 'store/profile/selectors';

// Styles
import styles from 'components/Facets/FacetSearch.module.scss';

// Hooks
import { useClickEvent } from 'hooks';

// Misc.
import { INITIAL_TRANSCRIPTS_FACETS } from './constants';

// Types
import { IFacetProps, IInitialSearchFacet, SearchRequest } from '../types';

interface IProps {
  handleClose(): void;
  forcedFilter?: ILearnerSearchRequest;
  initialValues: IInitialSearchFacet;
  searchRequest?: SearchRequest;
}

export const TranscriptFacets = (props: IProps): ReactElement => {
  const { handleClose, initialValues, forcedFilter, searchRequest } = props;
  const dispatch = useDispatch();
  const fireEvent = useClickEvent({ Event: 'TranscriptSearchFilter', EventCategory: 'Search' });

  // Selectors.
  const facets: IFacetProps[] = useSelector(userProfileFacetsSelector);

  const formik = useFormik<ILearnerSearchRequest>({
    initialValues,
    // API call.
    onSubmit: (values: ILearnerSearchRequest): void => {
      window.scroll(0, 0);
      dispatch(getUserProfileSearch(values));
      fireEvent({ Event: 'Facet Search', EventAction: 'Transcripts Filter', SearchPayload: values });
      handleClose();
    },
  });

  const { handleReset, handleSubmit, setValues, values } = formik;

  const onClearAll = async (e: HTMLButtonElement): Promise<void> => {
    window.scroll(0, 0);
    handleReset(e);
    await setValues(INITIAL_TRANSCRIPTS_FACETS);
    fireEvent({
      Event: 'Clear Facets',
      EventAction: 'Clear Transcripts Filter',
      SearchPayload: INITIAL_TRANSCRIPTS_FACETS,
    });
    dispatch(updateProfileSearch(initialValues));
    dispatch(getUserProfileSearch(forcedFilter));
  };

  const isDisabled = !facets;

  return (
    <FormikProvider value={formik}>
      <form className={classNames(styles['summary-refiners'], styles.narrow)} onSubmit={handleSubmit}>
        {/* Filter pills */}
        <div className={styles.filters}>
          <div className={styles['filters-header']}>
            Filter By:
            <Button className={styles['link link--hyperlink']} disabled={isDisabled} onClick={onClearAll}>
              Clear All
            </Button>
          </div>

          {/* Facets */}
          <div className={styles['filters-selected']}>
            {values && (
              <div className={styles['filters-selected']}>
                <FiltersSelected defaultFacets={TRANSCRIPT_ACCORDION_TITLES} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.refiners}>
          <FacetsAccordion
            defaultFacets={TRANSCRIPT_ACCORDION_TITLES}
            facets={facets as Facet}
            searchRequest={searchRequest}
          />
        </div>
        <Button disabled={isDisabled} fullWidth variant={ButtonVariant.Primary} type="submit">
          Search
        </Button>
      </form>
    </FormikProvider>
  );
};
