import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { ReactElement, useState } from 'react';

export interface IFacetDatePickerProps {
  label?: string;
  required?: boolean;
  value?: ParsableDate;
  onChange: (date: MaterialUiPickersDate, value?: string | null) => void;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
}

// TODO add formik Field props
export const FacetDatePicker = (props: IFacetDatePickerProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { label, required, value, onChange, minDate, ...rest } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        className="date-picker"
        disableToolbar
        format="MM/DD/yyyy"
        InputAdornmentProps={{ position: 'start' }}
        label={label}
        onChange={onChange}
        onClick={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        required={required}
        value={value}
        variant="inline"
        inputVariant="outlined"
        minDate={minDate}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
