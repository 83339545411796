import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components.
import { Button, FormikInputField } from 'components';

// Core.
import { ButtonVariant } from 'core';

// Store.
import { validateCode, resendCode } from 'store/onboarding/actions';
import { onboardingIsLoadingSelector } from 'store/onboarding/selectors';

// Styles.
import styles from './OnboardingVerifyCodeForm.module.scss';

// Validation schema.
import { validationSchema } from './validationSchema';

interface IOnboardingVerifyCodeForm {
  onSuccess?: VoidFunction;
}

export const OnboardingVerifyCodeForm = ({ onSuccess }: IOnboardingVerifyCodeForm): ReactElement => {
  const dispatch = useDispatch();
  const resendConfirmation = useCallback(() => dispatch(resendCode()), [dispatch]);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      try {
        await dispatch(validateCode(values.code));
        await onSuccess?.();
      } catch (error) {
        setFieldError('code', error.message);
      }
    },
    validationSchema,
  });

  const { handleSubmit, isSubmitting } = formik;

  // Selectors
  const isLoading: boolean = useSelector(onboardingIsLoadingSelector);

  const isPending: boolean = isSubmitting || isLoading;

  return (
    <FormikProvider value={formik}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <span>Please enter your confirmation code below.</span>
          <FormikInputField formikKey="code" label="Confirm Code" required />
          <span>
            Didn't receive a code?{' '}
            <Button onClick={resendConfirmation} to="/" variant={ButtonVariant.Link}>
              Email another code
            </Button>
          </span>
        </fieldset>
        <footer className={styles.footer}>
          <Button disabled={isPending} isSubmitting={isPending} type="submit" variant={ButtonVariant.Primary}>
            Next
          </Button>
        </footer>
      </form>
    </FormikProvider>
  );
};
