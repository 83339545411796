import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ReactElement, ReactNode } from 'react';

// Core.
import { Configuration } from 'core';

interface IAuthProvider {
  children: ReactNode;
}

interface IStubAuthProvider {
  children: ReactNode;
  instance: IPublicClientApplication;
}

export const createInstance = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: {
      authority: Configuration.azureMsalAuthority,
      clientId: Configuration.azureMsalAppId,
      postLogoutRedirectUri: Configuration.azureMsalRedirectUri,
      redirectUri: Configuration.azureMsalRedirectUri,
    },
    cache: {
      cacheLocation: Configuration.storage,
      storeAuthStateInCookie: false,
    },
  });

export const AuthProvider = ({ children }: IAuthProvider): ReactElement => {
  return <MsalProvider instance={createInstance()}>{children}</MsalProvider>;
};

export const StubAuthProvider = ({ children, instance }: IStubAuthProvider): ReactElement => {
  return <MsalProvider instance={instance}>{children}</MsalProvider>;
};
