// Libs
import { ChangeEvent, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import keys from 'lodash/keys';
import { Box, Grid } from '@material-ui/core';

// Components
import { Button, LoadingCards } from 'components';
import { ILabelType, MultiTypeahead } from 'components/MultiTypeahead';

// Styles
import styles from '../Wizard.module.scss';

// Core
import { ButtonVariant, IDictionary, IUserProfile } from 'core';

// Store
import { specialtiesSelector, userProfileSpecialtyIdsSelector } from 'store/profile/selectors';
import { getSpecialties } from 'store/profile/actions';
import { IWizardProps } from '../types';

type IProps = Pick<IWizardProps, 'back' | 'submit'>;

export const Specialties = ({ back, submit }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { isSubmitting, isValid, setFieldValue } = useFormikContext<IUserProfile>();

  // Selectors.
  const specialties: IDictionary<string> = useSelector(specialtiesSelector);
  const specialtyIds: string[] = useSelector(userProfileSpecialtyIdsSelector);

  const formatSpecialtiesForTypeahead = (id: string): ILabelType => ({ label: specialties?.[id], id });

  // Format the specialty options for the dropdown.
  const specialtyOptions: ILabelType[] = useMemo(() => {
    const idKeys: string[] = keys(specialties);
    const options: ILabelType[] = idKeys?.map(formatSpecialtiesForTypeahead);

    return options || [];
  }, [specialties]);

  // Format the initialValues for the typeahead.
  const formattedInitialSpecialties = specialtyIds?.map(formatSpecialtiesForTypeahead);

  useEffect(() => {
    dispatch(getSpecialties());
  }, [dispatch]);

  const onChangeSpecialty = (option: string): void => {
    setFieldValue('specialtyIds', option);
  };

  return (
    <div className={styles['boards-repeater']}>
      <h5>What is your specialty or area of practice? Select all that apply.</h5>
      <br />
      {!specialtyOptions?.length ? (
        <LoadingCards count={1} />
      ) : (
        <MultiTypeahead
          id="specialtyIds"
          initialOptions={formattedInitialSpecialties}
          onInputChange={(_: ChangeEvent<HTMLInputElement>, option: string): void => onChangeSpecialty(option)}
          options={specialtyOptions}
          placeholder="Select Specialty/Area of Practice"
          setFieldValue={setFieldValue}
        />
      )}
      <Box display="flex" my={3}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button className={styles['wizard-button']} variant={ButtonVariant.Tertiary} onClick={back}>
              <KeyboardBackspaceRounded /> Back
            </Button>
          </Grid>
          <Grid>
            <Button
              className={styles['wizard-button']}
              disabled={isSubmitting || !isValid}
              isSubmitting={isSubmitting}
              onClick={submit}
              variant={ButtonVariant.Primary}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
