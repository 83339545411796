// Libs
import { AxiosRequestConfig as RequestConfig } from 'axios';

// Core
import {
  IArrayBufferDownload,
  IBoard,
  IDictionary,
  ILearnerSearchRequest,
  ILearnerSearchResult,
  IProfileCME,
  ISendTranscript,
  IUserProfile,
  ServerRoute,
  get,
  post,
} from 'core';
import { getOrAcquireAccessToken } from 'hooks';

export class ProfileService {
  public static async getBoards(): Promise<IBoard[]> {
    const route: ServerRoute = ServerRoute.forAction('profile', 'boards');
    return await get(route);
  }

  public static async getSpecialties(): Promise<IDictionary<string>> {
    const route: ServerRoute = ServerRoute.forAction('profile', 'specialties');
    return await get(route);
  }

  public static async getUserProfile(): Promise<IUserProfile> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile');
    return await get<IUserProfile>(route, null, config);
  }

  public static async updateUserProfile(data: IUserProfile): Promise<void> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile');
    return await post<void>(route, data, config);
  }

  public static async updateUserPassword(password: string): Promise<void> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile', 'updatepassword');

    return await post<void>(route, { value: password }, config);
  }

  public static async getUserProfileSearch(payload: ILearnerSearchRequest): Promise<ILearnerSearchResult> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile', 'search');
    return await post<ILearnerSearchResult>(route, payload, config);
  }

  public static async getUserProfileCME(): Promise<IProfileCME> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile', 'cme');
    return await get<IProfileCME>(route, null, config);
  }

  public static async downloadTranscript(completionIds: string[]): Promise<IArrayBufferDownload> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile', 'transcript/download');
    const result: ArrayBuffer = await post<ArrayBuffer>(route, completionIds, {
      ...config,
      responseType: 'arraybuffer',
    });

    return {
      file: result,
      type: 'application/pdf',
    };
  }

  public static async completeWizard(): Promise<void> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile', 'completedwizard');
    return await post<void>(route, null, config);
  }

  public static async sendTranscript(payload: ISendTranscript): Promise<void> {
    const config: RequestConfig = await this.getConfiguration();
    const route: ServerRoute = ServerRoute.forAction('Profile', 'transcript/send');
    return await post<void>(route, payload, config);
  }

  private static async getConfiguration(): Promise<RequestConfig> {
    const accessTokenWithType = await getOrAcquireAccessToken();
    if (accessTokenWithType) {
      return { headers: { Authorization: accessTokenWithType } };
    }
  }
}
