import { IActivityKeywords, IActivitySearchRequest, IActivitySearchResponse, IActivitySearchStateProps } from 'core';

export interface ActivityState {
  isLoading: boolean;
  keywords: IActivityKeywords;
  recentSearches: string[];
  searchRequest: IActivitySearchRequest;
  searchResponse: IActivitySearchResponse;
  searchStateProps: IActivitySearchStateProps;
}

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITY_KEYWORDS_SUCCESS = 'GET_ACTIVITY_KEYWORDS_SUCCESS';
export const CLEAR_RECENT_ACTIVITY_SEARCHES = 'CLEAR_RECENT_ACTIVITY_SEARCHES';
export const SET_RECENT_ACTIVITY_SEARCHES = 'SET_RECENT_ACTIVITY_SEARCHES';
export const RESET_ACTIVITY_SEARCH = 'RESET_ACTIVITY_SEARCH';
export const UPDATE_ACTIVITY_SEARCH_REQUEST = 'UPDATE_ACTIVITY_SEARCH_REQUEST';
export const UPDATE_ACTIVITY_SEARCH_STATE_PROPS = 'UPDATE_ACTIVITY_SEARCH_STATE_PROPS';
