import { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

// Hooks.
import { useAuth } from 'hooks';

interface IAuthenticatedRouteProps extends RouteProps {
  requiresAuth?: boolean;
  requiresGuest?: boolean;
}

export function AuthenticatedRoute(props: IAuthenticatedRouteProps): ReactElement {
  const { requiresAuth, requiresGuest, ...routeProps } = props;
  const [, { isAuthenticated, isLoaded }] = useAuth();

  // Wait for auth to be loaded.
  if (!isLoaded) return null;

  // Redirect if conditions met.
  if (requiresAuth && !isAuthenticated) return <Redirect to="/401" />;
  if (requiresGuest && isAuthenticated) return <Redirect to="/" />;

  return <Route {...routeProps} />;
}
