// General error toast options.
import { ToastLocationEnum, ToastType } from 'core/enums';

// General error toast options.
export const generalToastOptions = {
  autoHideDuration: 3000,
  centered: true,
  location: ToastLocationEnum.Top,
};

export const errorToastOptions = {
  ...generalToastOptions,
  toastType: ToastType.TOAST_ALERT,
};

export const warningToastOptions = {
  ...generalToastOptions,
  toastType: ToastType.TOAST_WARNING,
};

export const successToastOptions = {
  ...generalToastOptions,
  autoHideDuration: 1000,
  toastType: ToastType.TOAST_SUCCESS,
};

export const infoToastOptions = {
  ...generalToastOptions,
  toastType: ToastType.TOAST_INFO,
};
