import { KeyboardBackspaceRounded, DescriptionRounded, SportsKabaddiRounded, HotTubRounded } from '@material-ui/icons';
import { ReactElement } from 'react';

// Components.
import { Link } from 'components';

// Core.
import { LinkVariant } from 'core';

// Hooks.
import { useClickEvent } from 'hooks';

// Styles
import styles from './Featured.module.scss';

export const Featured = (): ReactElement => {
  const fireEvent = useClickEvent();

  return (
    <div className={styles.container}>
      <div className={styles.eyebrow}>Popular CME Searches</div>
      <ul>
        <li>
          <Link
            className={styles.link}
            icon={KeyboardBackspaceRounded}
            onClick={() => fireEvent({ EventAction: 'Links', EventLabel: 'Preoperative Medication' })}
            to="/activity/search?searchText=Pain%20Management"
            variant={LinkVariant.Tertiary}
          >
            MATE Act
          </Link>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SportsKabaddiRounded />
            </div>
            <p>
              Education related to substance use disorders, effective treatment planning, pain management, and substance
              misuse.
            </p>
          </div>
        </li>
        <li>
          <Link
            className={styles.link}
            icon={KeyboardBackspaceRounded}
            onClick={() => fireEvent({ EventAction: 'Links', EventLabel: 'Clinical Care Transfusion' })}
            to="/activity/search?participationFee[]=No%2C%20it%27s%20free&locationTypes[]=Live-Streamed,Online&type[]=Conference,In-House%20Seminar,Online%20Learning&registration[]=Open%20to%20all"
            variant={LinkVariant.Tertiary}
          >
            Free and Online
          </Link>
          <div className={styles.content}>
            <div className={styles.icon}>
              <HotTubRounded />
            </div>
            <p>Free accredited continuing education you can complete online</p>
          </div>
        </li>
        <li>
          <Link
            className={styles.link}
            icon={KeyboardBackspaceRounded}
            onClick={() => fireEvent({ EventAction: 'Links', EventLabel: 'Hospital Diagnostic Cases' })}
            to="/activity/search?isMoc=true"
            variant={LinkVariant.Tertiary}
          >
            CME that Counts for MOC/CC
          </Link>
          <div className={styles.content}>
            <div className={styles.icon}>
              <DescriptionRounded />
            </div>
            <p>Education that offers Maintenance for Certification or Continuous/Continuing Certification Credit</p>
          </div>
        </li>
      </ul>
    </div>
  );
};
