import { ReactElement } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { useFormikContext } from 'formik';

// Styles
import styles from 'components/forms/FormikFields.module.scss';

// Types
import { IFacetProps, SearchRequest } from 'components/Facets/types';
import { FormikInputField } from 'components/forms';

export const TextInput = (props: IFacetProps): ReactElement => {
  const { callback, classes, facet, isExpanded, expandIcon, onToggleExpand } = props;
  const { setFieldValue } = useFormikContext<SearchRequest>();
  const { displayValue, payloadValue } = facet;

  const onKeyPress = (e): void => {
    const value = e.target.value;
    setFieldValue(payloadValue, value);

    // Clicking the enter button should call the callback.
    if (e.which === 13) {
      callback?.();
    }
  };

  return (
    <Accordion key={displayValue} className={classes.accordion} expanded={isExpanded} onChange={onToggleExpand}>
      <AccordionSummary
        aria-controls={`${displayValue}-content`}
        className={classes.summary}
        expandIcon={expandIcon}
        id={`${displayValue}-header`}
      >
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <span className="search-box--small">
          <FormikInputField
            className={styles.input}
            formikKey={payloadValue}
            name={payloadValue}
            onKeyPress={onKeyPress}
            placeholder={displayValue}
            title={displayValue}
            type="text"
          />
        </span>
      </AccordionDetails>
    </Accordion>
  );
};
