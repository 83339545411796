import { Snackbar, SnackbarContent, Slide, makeStyles } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { CloseRounded, DeleteRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components.
import { Button } from 'components';

// Core.
import { ToastLocationEnum, ToastType } from 'core/enums';

// Styles.
import styles from './Toast.module.scss';

// Store.
import { eatToast } from 'store/toast/actions';
import { isToastOpenSelector, toastOptionsSelector } from 'store/toast/selectors';

let isToastTop = false;

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction={isToastTop ? 'down' : 'up'} />;
}

const useStyles = makeStyles(() => ({
  alert: {
    backgroundColor: '#bd2e2a',
  },
  info: {
    backgroundColor: '#00838f',
  },
  success: {
    backgroundColor: '#2a835a',
  },
  toast: {
    width: '100%',
  },
  toastmessage: {
    '& > div': {
      width: '100%',
    },
    borderRadius: 0,
    width: '100%',
  },
  warning: {
    backgroundColor: '#e2a641',
  },
}));

export const Toast = (): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isToastOpen = useSelector(isToastOpenSelector);
  const toastOptions = useSelector(toastOptionsSelector);

  if (!toastOptions) return null;

  const { autoHideDuration, centered, dismissable, icon, location, message, toastType } = toastOptions;

  isToastTop = location === ToastLocationEnum.Top || false;

  const handleClose = (_: SyntheticEvent | MouseEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      dispatch(eatToast());
    }

    dispatch(eatToast());
  };

  const toastMessageClass = classNames({
    [classes.alert]: toastType === ToastType.TOAST_ALERT,
    [classes.warning]: toastType === ToastType.TOAST_WARNING,
    [classes.success]: toastType === ToastType.TOAST_SUCCESS,
    [classes.info]: toastType === ToastType.TOAST_INFO,
    [classes.toastmessage]: true,
  });

  return (
    <>
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: isToastTop ? 'top' : 'bottom',
        }}
        className={classes.toast}
        onClose={handleClose}
        open={isToastOpen}
        TransitionComponent={SlideTransition}
        autoHideDuration={autoHideDuration}
        transitionDuration={{ exit: 300 }}
      >
        <SnackbarContent
          className={toastMessageClass}
          message={
            <div className={classNames(styles['toast-content'], { [styles['toast-content-centered']]: centered })}>
              {icon && <DeleteRounded className={styles.icon} />}
              {message}
              {dismissable && (
                <Button className={styles.close} onClick={handleClose}>
                  <CloseRounded />
                </Button>
              )}
            </div>
          }
        />
      </Snackbar>
    </>
  );
};
