import { Reducer } from 'redux';
import {
  DOWNLOAD_TRANSCRIPT,
  DOWNLOAD_TRANSCRIPT_FAILURE,
  DOWNLOAD_TRANSCRIPT_SUCCESS,
  GET_LEARNER_PROFILE,
  GET_LEARNER_PROFILE_FAILURE,
  GET_LEARNER_PROFILE_SEARCH,
  GET_LEARNER_PROFILE_SEARCH_FAILURE,
  GET_LEARNER_PROFILE_SEARCH_SUCCESS,
  GET_LEARNER_PROFILE_SUCCESS,
  GET_PROFILE_BOARDS_SUCCESS,
  GET_PROFILE_CME,
  GET_PROFILE_CME_FAILURE,
  GET_PROFILE_CME_SUCCESS,
  GET_PROFILE_RECENT_CME,
  GET_PROFILE_RECENT_CME_FAILURE,
  GET_PROFILE_RECENT_CME_SUCCESS,
  GET_PROFILE_SPECIALTIES_SUCCESS,
  ProfileActionTypes,
  ProfileState,
  RESET_LEARNER_PROFILE,
  SEND_TRANSCRIPT,
  SEND_TRANSCRIPT_FAILURE,
  SEND_TRANSCRIPT_SUCCESS,
  SET_HAS_COMPLETED_WIZARD,
  UPDATE_LEARNER_PROFILE_SEARCH,
  UPDATE_LEARNER_PROFILE_SEARCH_PROPS,
  UPDATE_LEARNER_PROFILE_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_SEARCH_PAGINATION_STATE,
} from './types';
import { INITIAL_TRANSCRIPTS_FACETS } from '../../components/Facets/TranscriptFacets/constants';

const initialState: ProfileState = {
  accountId: undefined,
  boards: undefined,
  cme: undefined,
  error: undefined,
  isUserProfileCMELoading: false,
  isUserProfileLoading: false,
  isUserProfileRecentCMELoading: false,
  isUserProfileSearchLoading: true,
  recentCme: undefined,
  searchRequest: INITIAL_TRANSCRIPTS_FACETS,
  searchResponse: undefined,
  searchStateProps: {
    page: 1,
    selectedActivities: [],
  },
  specialties: undefined,
  ulid: undefined,
  userProfile: undefined,
};

export const profileReducer: Reducer<ProfileState> = (
  state = initialState,
  action: ProfileActionTypes,
): ProfileState => {
  switch (action.type) {
    case GET_LEARNER_PROFILE:
      return {
        ...state,
        error: undefined,
        isUserProfileLoading: true,
      };
    case GET_LEARNER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload as Error,
        isUserProfileLoading: false,
      };
    case GET_LEARNER_PROFILE_SUCCESS:
      return {
        ...state,
        error: undefined,
        isUserProfileLoading: false,
        userProfile: action.payload,
      };
    case RESET_LEARNER_PROFILE:
      return {
        ...state,
        userProfile: undefined,
      };
    case UPDATE_LEARNER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      };
    case GET_PROFILE_BOARDS_SUCCESS:
      return {
        ...state,
        boards: action.payload,
      };
    case GET_PROFILE_SPECIALTIES_SUCCESS:
      return {
        ...state,
        specialties: action.payload,
      };
    case UPDATE_LEARNER_PROFILE_SEARCH_PROPS:
      return {
        ...state,
        searchStateProps: action.payload,
      };
    case UPDATE_PROFILE_SEARCH_PAGINATION_STATE:
      return {
        ...state,
        searchStateProps: action.payload,
      };
    case GET_LEARNER_PROFILE_SEARCH:
      return {
        ...state,
        isUserProfileSearchLoading: true,
        searchRequest: action.payload,
      };
    case GET_LEARNER_PROFILE_SEARCH_SUCCESS:
      return {
        ...state,
        isUserProfileSearchLoading: false,
        searchResponse: action.payload,
      };
    case GET_LEARNER_PROFILE_SEARCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        isUserProfileSearchLoading: false,
      };

    case UPDATE_LEARNER_PROFILE_SEARCH:
      return {
        ...state,
        searchRequest: action.payload,
      };

    case DOWNLOAD_TRANSCRIPT:
    case DOWNLOAD_TRANSCRIPT_SUCCESS:
    case DOWNLOAD_TRANSCRIPT_FAILURE: {
      return {
        ...state,
      };
    }
    case SEND_TRANSCRIPT:
    case SEND_TRANSCRIPT_SUCCESS:
    case SEND_TRANSCRIPT_FAILURE: {
      return {
        ...state,
      };
    }
    case GET_PROFILE_CME: {
      return {
        ...state,
        isUserProfileCMELoading: true,
      };
    }
    case GET_PROFILE_CME_SUCCESS: {
      return {
        ...state,
        cme: action.payload,
        isUserProfileCMELoading: false,
      };
    }
    case GET_PROFILE_CME_FAILURE: {
      return {
        ...state,
        isUserProfileCMELoading: false,
      };
    }
    case GET_PROFILE_RECENT_CME: {
      return {
        ...state,
        isUserProfileRecentCMELoading: true,
      };
    }
    case GET_PROFILE_RECENT_CME_SUCCESS: {
      return {
        ...state,
        isUserProfileRecentCMELoading: false,
        recentCme: action.payload,
      };
    }
    case GET_PROFILE_RECENT_CME_FAILURE: {
      return {
        ...state,
        isUserProfileRecentCMELoading: false,
      };
    }
    case UPDATE_PASSWORD: {
      return {
        ...state,
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_PASSWORD_FAILURE: {
      return {
        error: action.payload,
        ...state,
      };
    }
    case SET_HAS_COMPLETED_WIZARD: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          hasCompletedWizard: true,
        },
      };
    }
    default:
      return state;
  }
};
