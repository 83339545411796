/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseApiService } from './baseApiService';
import { ITaxonomyTerm } from './models';

export class TaxonomyApiService extends BaseApiService {
  public async getTermById(id: string): Promise<ITaxonomyTerm> {
    const taxonomyTerm: ITaxonomyTerm = await super.getAsync(`/api/Taxonomy/Terms/${id}`);

    TaxonomyApiService.formatTaxonomyTermFromServer(taxonomyTerm);

    return taxonomyTerm;
  }

  private static formatTaxonomyTermFromServer(taxonomyTerm: ITaxonomyTerm): void {
    const currentStartDate: Date | string | undefined = taxonomyTerm.startDate;
    const currentEndDate: Date | string | undefined = taxonomyTerm.endDate;

    if (currentStartDate === null || currentStartDate === undefined) {
      taxonomyTerm.startDate = null as any;
    } else if (typeof currentStartDate === 'string') {
      taxonomyTerm.startDate = new Date(currentStartDate);
    }

    if (currentEndDate === null || currentEndDate === undefined) {
      taxonomyTerm.endDate = null as any;
    } else if (typeof currentEndDate === 'string') {
      taxonomyTerm.endDate = new Date(currentEndDate);
    }

    if (taxonomyTerm.terms !== null && taxonomyTerm.terms !== undefined) {
      taxonomyTerm.terms.forEach(TaxonomyApiService.formatTaxonomyTermFromServer);
    }
  }
}
