import { PARSAction } from 'core';

export interface RailState {
  railOpen: boolean;
}

export const RAIL_OPEN = 'RAIL_OPEN';
export const RAIL_CLOSE = 'RAIL_CLOSE';

interface RailOpen extends PARSAction<undefined, typeof RAIL_OPEN> {
  type: typeof RAIL_OPEN;
}

interface RailClose extends PARSAction<undefined, typeof RAIL_CLOSE> {
  type: typeof RAIL_CLOSE;
}

export type RailActionTypes = RailOpen | RailClose;
