export const Configuration = Object.freeze({
  api: process.env.REACT_APP_API || '',
  applicationInsightsKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY || '',
  armatureUrl: process.env.REACT_APP_ARMATURE_URL || '',
  azureMsalAppId: process.env.REACT_APP_AZURE_MSAL_APP_ID || '',
  azureMsalAuthority: process.env.REACT_APP_AZURE_MSAL_AUTHORITY || '',
  azureMsalRedirectUri: process.env.REACT_APP_AZURE_MSAL_REDIRECT_URI || '',
  azureMsalScope: process.env.REACT_APP_AZURE_MSAL_SCOPE || '',
  cmeFinderUiVersion: process.env.REACT_APP_CME_FINDER_UI_VERSION || 'local',
  gtmId: process.env.REACT_APP_GTM_ID || '',
  sendToAppInsightsThreshold: process.env.REACT_APP_INSIGHT_THRESHOLD || 100,
  sendToConsoleThreshold: process.env.REACT_APP_CONSOLE_THRESHOLD || 100,
  sendToToastThreshold: process.env.REACT_APP_TOAST_THRESHOLD || 100,
  storage: process.env.REACT_APP_STORAGE || 'localStorage',
});
