// Libs
import { Fragment, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { Fab, Grid, Box, CircularProgress } from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import { CloseRounded } from '@material-ui/icons';

// Components
import { Button, FormikDropdown, FormikInputField } from 'components';
import { WizardButtonRow } from '../WizardButtonRow';

// Styles
import styles from '../Wizard.module.scss';

// Core
import { IBoard, IProfileCertifyingBoard, IUserProfile } from 'core';

// Store
import { getCertifyingBoardsSelector } from 'store/profile/selectors';
import { getCertifyingBoards } from 'store/profile/actions';

// Types
import { IWizardProps } from '../types';
type IProps = Pick<IWizardProps, 'next' | 'skip'>;

export const CertifyingBoards = ({ skip, next }: IProps): ReactElement => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<IUserProfile>();
  const certifyingBoards: IBoard[] = useSelector(getCertifyingBoardsSelector);

  useEffect(() => {
    if (!certifyingBoards) {
      dispatch(getCertifyingBoards());
    }
  }, [certifyingBoards, dispatch]);

  const { certifyingBoards: selectedCertifyingBoards } = values;

  // Get all of the ids that have been selected so that we can disable them from the picker.
  const filteredBoardsIds = (selectedCertifyingBoards ?? [])
    .map(({ boardId }: IProfileCertifyingBoard): string => boardId)
    .filter(Boolean);

  return (
    <div className={styles['boards-repeater']}>
      <h5>By which boards are you certified?</h5>
      <br />
      {!certifyingBoards?.length ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <FieldArray name="certifyingBoards">
            {({ push, remove }) => (
              <>
                <Grid container spacing={3}>
                  {selectedCertifyingBoards?.map(
                    (board: IProfileCertifyingBoard, index: number): ReactElement => {
                      const { boardId, isEditable } = board;

                      return (
                        <Fragment key={`${index}-${boardId}`}>
                          <Grid item xs={12} sm={6} md={7}>
                            <FormikDropdown
                              disabled={isEditable === false}
                              disabledItemKeys={filteredBoardsIds}
                              formikKey={`certifyingBoards.${index}.boardId`}
                              idProp="id"
                              items={certifyingBoards}
                              labelProp="name"
                            />
                          </Grid>
                          <Grid item xs={9} sm={5} md={4}>
                            <FormikInputField
                              disabled={isEditable === false}
                              formikKey={`certifyingBoards.${index}.learnerId`}
                              placeholder="ID:"
                            />
                          </Grid>
                          {isEditable !== false && (
                            <Grid item xs={1} className={styles.remove}>
                              <Button className="remove" onClick={() => remove(index)}>
                                <CloseRounded />
                              </Button>
                            </Grid>
                          )}
                        </Fragment>
                      );
                    },
                  )}
                </Grid>
                <div className={styles.add}>
                  <Fab
                    aria-label="add another instance"
                    color="inherit"
                    disabled={certifyingBoards.length === selectedCertifyingBoards.length}
                    onClick={(): void => push({ isEditable: true })}
                    size="small"
                  >
                    <AddIcon color="inherit" />
                  </Fab>
                  <span className={styles.text}>Add Another Certifying Board</span>
                </div>
              </>
            )}
          </FieldArray>
          <div className={styles.caption}>
            ACCME has a direct reporting relationship with some{' '}
            <a href=" https://accme.org/cme-counts-for-moc" target="_blank" rel="noopener noreferrer">
              certifying boards
            </a>
            . CME providers can report your participation on your behalf to those boards.
          </div>
        </>
      )}
      <Box display="flex" my={3}>
        <WizardButtonRow skip={skip} next={next} />
      </Box>
    </div>
  );
};
