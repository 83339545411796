import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const useWarnBeforeLeaving = (when = true, message = 'Are you sure you want to leave this page?'): void => {
  const history = useHistory();
  const self = useRef(null);

  const handleWindowOrTabClose = useCallback(
    (e): string => {
      if (!when) return;
      e.returnValue = message;
      return message;
    },
    [when, message],
  );

  useEffect(() => {
    if (when) {
      self.current = history.block(message);
    } else {
      self.current = null;
    }

    window.addEventListener('beforeunload', handleWindowOrTabClose);

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }
      window.removeEventListener('beforeunload', handleWindowOrTabClose);
    };
  }, [when, message, history, self, handleWindowOrTabClose]);
};
