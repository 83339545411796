import { createSelector } from 'reselect';

// Core.
import {
  ITaxonomyTerm,
  TAXONOMY_FAQ_ROOT_ID,
  TAXONOMY_FOOTER_ID,
  TAXONOMY_HELPDESK_TOPICS_ROOT_ID,
  TAXONOMY_MEDICAL_SCHOOLS_ID,
  TAXONOMY_NAVIGATION_ROOT_ID,
} from 'core';

// Store.
import { AppState } from 'store';

// Types.
import { TaxonomyState } from './types';

/*************
 * Raw Taxonomy State
 *************/
/**
 * @function taxonomyStateSelector
 * @param taxonomyState
 * @description - raw redux taxonomy state
 * @returns TaxonomyState
 */
export const taxonomyStateSelector = ({ taxonomy }: AppState): TaxonomyState => taxonomy;

/**
 * @function getTaxonomyStateById
 * @param id - Taxonomy root ID constant
 * @description - return the taxonomy for a specific term; useful for detecting missing taxonomy data
 */
export const getTaxonomyStateById = (id = ''): ((AppState) => ITaxonomyTerm) => ({
  taxonomy,
}: AppState): ITaxonomyTerm => {
  if (id) {
    return taxonomy?.terms[id];
  }
};

export const taxonomyRootTermsSelector = createSelector(taxonomyStateSelector, (taxonomy) => taxonomy?.terms);

export const navigationSelector = ({ taxonomy }: AppState): ITaxonomyTerm =>
  taxonomy.terms[TAXONOMY_NAVIGATION_ROOT_ID];

export const navigationTermsSelector = createSelector(navigationSelector, (nav: ITaxonomyTerm) => nav?.terms || []);

export const faqTermsSelector = ({ taxonomy }: AppState): ITaxonomyTerm[] =>
  taxonomy.terms[TAXONOMY_FAQ_ROOT_ID]?.terms;

export const footerTermsSelector = ({ taxonomy }: AppState): ITaxonomyTerm[] =>
  taxonomy?.terms[TAXONOMY_FOOTER_ID]?.terms;

export const helpdeskTopicsTermSelector = ({ taxonomy }: AppState): ITaxonomyTerm[] =>
  taxonomy?.terms[TAXONOMY_HELPDESK_TOPICS_ROOT_ID]?.terms;

export const medicalSchoolsSelector = ({ taxonomy }: AppState): ITaxonomyTerm[] =>
  taxonomy?.terms[TAXONOMY_MEDICAL_SCHOOLS_ID]?.terms.slice().sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }
  });
