// Libs
import groupBy from 'lodash/groupBy';

// Core
import { CompletionSources, IDictionary, ILearnerCredit } from 'core';

export const groupCreditsByCompletionSourceAndAbbreviation = (
  credits: ILearnerCredit[] = [],
): IDictionary<IDictionary<ILearnerCredit[]>> => {
  const creditsByCompletionSource = groupBy(credits, ({ completionSource }) => completionSource);
  const result = {};
  for (const [completionSource, sourceCredits] of Object.entries(creditsByCompletionSource)) {
    if (completionSource === CompletionSources.Board) {
      result[completionSource] = groupBy(sourceCredits, ({ boardAbbreviation }) => boardAbbreviation);
    } else {
      result[completionSource] = groupBy(sourceCredits, ({ abbreviation }) => abbreviation);
    }
  }

  return result;
};
