/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosResponse } from 'axios';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

// Core + Store
import { LogService } from 'services/LogService';
import { TraceLevel } from 'core/enums';

// Types
interface IArgs {
  error: AxiosError;
  fallbackMessage?: string;
  name?: string;
  thunkName?: string;
}

/**
 * @function handleServerError
 * @param error
 * @param fallbackMessage
 * @param thunkName
 * @description Handles server errors and does the following:
 *              - Sends data to App Insights.
 *              - Logs a console.error for extra context when not in production.
 *              - Returns a human readable string for the user.
 * @return string
 */
export interface IServerError {
  errorMessage: string;
}
export const handleServerError = ({ error, fallbackMessage, name = '', thunkName = '' }: IArgs): IServerError => {
  const message: string = error?.message;
  const response: AxiosResponse<any> = error?.response;

  LogService.sendToAppInsights(
    {
      exception: error,
      name,
      properties: {
        environmentErrorLocation: `Error occurs in ${process.env.NODE_ENV} environment.`,
        occurredOn: Date.now(),
        thunkName,
      },
      severityLevel: SeverityLevel.Error,
    },
    TraceLevel.Error,
  );

  if (process.env.NODE_ENV !== `production`) {
    if (!response) console.log(message);
    // Example: Error Code: 500 - Internal Server Error {@type Error}
    console.log(`Error Code: ${response?.status} - ${response?.statusText} `, { ...error });
  }

  return {
    errorMessage:
      response?.data?.error || response?.data?.message || response?.statusText || message || fallbackMessage,
  };
};
