import { ChangeEvent, ReactElement } from 'react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

// Styles
import styles from './checkboxes.module.scss';

// Core + Store
import { StatusEnum } from 'core/enums';

// Types
import { IFacetProps, SearchRequest } from '../../../types';

export const Checkboxes = (props: IFacetProps): ReactElement => {
  const { callback, classes, facet, facets, isDisabled, isExpanded, expandIcon, onToggleExpand } = props;
  const { values } = useFormikContext<SearchRequest>();

  const { displayValue, payloadValue, responseValue } = facet;

  return (
    <Accordion
      key={payloadValue}
      className={classes.accordion}
      disabled={isDisabled}
      expanded={isExpanded}
      onChange={onToggleExpand}
    >
      <AccordionSummary
        aria-controls={`${payloadValue}-content`}
        className={classes.summary}
        expandIcon={expandIcon}
        id={`${payloadValue}-header`}
      >
        {displayValue}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FieldArray
          name={payloadValue}
          render={(arrayHelpers: FieldArrayRenderProps) => {
            const facetValues: string[] = facets?.[responseValue];

            return (
              <>
                {facetValues?.sort()?.map(
                  (currentFacet: string): ReactElement => {
                    const isPill =
                      responseValue === 'Status' ||
                      responseValue === 'ActivityStatus' ||
                      responseValue === 'LearnerStatus' ||
                      responseValue === 'CompletionStatuses';
                    const labelTextClass = classNames(
                      {
                        [styles['--accepted']]: currentFacet === StatusEnum.ACCEPTED && isPill,
                        [styles['--active']]: currentFacet === StatusEnum.ACTIVE && isPill,
                        [styles['--closed']]: currentFacet === StatusEnum.CLOSED && isPill,
                        [styles['--draft']]: currentFacet === StatusEnum.DRAFT && isPill,
                        [styles['--ready-to-close']]: currentFacet === StatusEnum.READY_TO_CLOSE && isPill,
                        [styles['--rejected']]: currentFacet === StatusEnum.REJECTED && isPill,
                        [styles['--submitted']]: currentFacet === StatusEnum.SUBMITTED && isPill,
                        [styles['--not-processed']]: currentFacet === StatusEnum.NOT_PROCESSED && isPill,
                      },
                      styles['label-text'],
                    );
                    return (
                      <label className={styles.checkbox} key={uniqueId(currentFacet)}>
                        <input
                          type="checkbox"
                          checked={values?.[facet?.payloadValue]?.includes(currentFacet)}
                          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                            if (e.target.checked) {
                              arrayHelpers.push(currentFacet);
                            } else {
                              const foundIdx = values?.[facets?.[payloadValue]]?.indexOf(currentFacet);
                              arrayHelpers.remove(foundIdx);
                            }

                            // API call.
                            callback?.();
                          }}
                        />
                        <span className="checkmark">
                          <div className="label-text-container">
                            <div className={labelTextClass}>{currentFacet}</div>
                          </div>
                        </span>
                      </label>
                    );
                  },
                )}
              </>
            );
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
