import {
  ActivityApiService,
  IActivityKeywords,
  IActivitySearchRequest,
  IActivitySearchResponse,
  post,
  ServerRoute,
} from 'core';

export class ActivityService {
  private activityApiService: ActivityApiService;

  constructor() {
    this.activityApiService = new ActivityApiService();
  }

  public static async get(payload: IActivitySearchRequest): Promise<IActivitySearchResponse> {
    const route: ServerRoute = ServerRoute.forAction('activities', 'search');
    return await post<IActivitySearchResponse>(route, payload);
  }

  public async getKeywords(): Promise<IActivityKeywords> {
    return this.activityApiService.getActivityKeywords();
  }
}
