import { RadioGroup } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import classNames from 'classnames';
import { Field, FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Components.
import { Button, FormikDatePicker, FormikDropdown, FormikInputField, FormikRadioButton } from 'components';

// Core.
import { ButtonVariant, IBoard, IOnboardingMatchLearnerRequest, IStateOrProvince, ITaxonomyTerm } from 'core';

// Hooks.
import { useTaxonomyMedicalSchools } from 'hooks';

// Store.
import { isLicensedInUnitedStatesAction, matchLearner, updateProfile } from 'store/onboarding/actions';
import { getCertifyingBoards } from 'store/profile/actions';
import { getStatesAndProvinces } from 'store/statesAndProvinces/actions';
import { getCertifyingBoardsSelector } from 'store/profile/selectors';
import {
  onboardingIsAbleToMatchSelector,
  onboardingIsLoadingSelector,
  onboardingMatchFailureCountSelector,
  onboardingRequestSelector,
} from 'store/onboarding/selectors';
import { stateAndProvinceSelector } from 'store/statesAndProvinces/selectors';

// Styles.
import styles from './OnboardingMatchLearnerForm.module.scss';

// Validations.
import { validationSchema } from './validationSchema';

interface IOnboardingMatchLearnerForm {
  isTryingAgain: boolean;
  onFailure?: VoidFunction;
}

const MAX_MATCH_FAILURE_COUNT = 2;

const Divider = (): ReactElement => (
  <div className={styles.divider}>
    <span>and/or</span>
  </div>
);

export const OnboardingMatchLearnerForm = ({ isTryingAgain, onFailure }: IOnboardingMatchLearnerForm): ReactElement => {
  const dispatch = useDispatch();
  const medicalSchools: ITaxonomyTerm[] = useTaxonomyMedicalSchools();

  // Selectors
  const certifyingBoards: IBoard[] = useSelector(getCertifyingBoardsSelector);
  const states: IStateOrProvince[] = useSelector(stateAndProvinceSelector);
  const { email } = useSelector(onboardingRequestSelector) || {};
  const isAbleToMatch = useSelector(onboardingIsAbleToMatchSelector);
  const matchFailureCount = useSelector(onboardingMatchFailureCountSelector);

  useEffect(() => {
    dispatch(getCertifyingBoards());
    dispatch(getStatesAndProvinces());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      boardId: '',
      dob: '',
      email,
      firstName: '',
      isLicensedInUnitedStates: true,
      lastName: '',
      learnerId: '',
      licenseId: '',
      medicalSchoolId: '',
      medicalSchoolName: '',
      middleName: '',
      npi: '',
      phoneNumber: '',
      stateAbbreviation: '',
      stateName: '',
      zipCode: '',
    },
    onSubmit: async (values) => {
      const { dob, isLicensedInUnitedStates, ...rest } = values;
      const dobMoment = moment(dob);
      const dobDate = dobMoment.date();
      const dobMonth = dobMoment.month() + 1;
      const submissionValues: IOnboardingMatchLearnerRequest = { dobDate, dobMonth, ...rest };

      dispatch(isLicensedInUnitedStatesAction(isLicensedInUnitedStates));

      // We call either of 2 endpoint based on the user's answer to `Are you a physician currently licensed in the United States?`
      // If the user selects `no`:
      if (!isLicensedInUnitedStates) {
        // Call /api/Onboarding/updateprofile and update their profile.
        dispatch(updateProfile(submissionValues));
      } else {
        // Call /api/Onboarding/match to try and match the entered data to a learner.
        const hasMatch = await dispatch(matchLearner(submissionValues));

        // Call the callback to update the number in local state for analytics.
        if (!hasMatch && !isAbleToMatch) {
          onFailure?.();
        }
      }
    },
    validationSchema,
  });

  const { handleSubmit, isSubmitting, isValid, values } = formik;

  // Selectors
  const isLoading: boolean = useSelector(onboardingIsLoadingSelector);
  const isPending: boolean = isSubmitting || isLoading;

  return (
    <FormikProvider value={formik}>
      <p className={styles.subheading}>
        Please provide some details about yourself to help us locate your information.
      </p>

      <form aria-label="Details" className={styles.form} onSubmit={handleSubmit} role="group">
        <fieldset className={styles.fieldset}>
          <div className={classNames(styles.row, styles.name)}>
            <div className={styles.grid}>
              <FormikInputField formikKey="firstName" label="First Name" required />
              <FormikInputField formikKey="lastName" label="Last Name" required />
              <Field name="dob">
                {({ field }) => (
                  <FormikDatePicker
                    field={field}
                    format="MM/DD"
                    formikKeyDate="dob"
                    label="Date of Birth"
                    openTo="date"
                    placeholder="MM/DD"
                    required
                    timeOfDay="midnight"
                  />
                )}
              </Field>
            </div>
          </div>
          <div className={classNames(styles.row, styles.physician)}>
            <div className={styles.grid}>
              <span id="physician-label">Are you a physician currently licensed in the United States?</span>
              <small>At this point, creation of an account is limited to physicians.</small>
              <RadioGroup aria-labelledby="physician-label" row>
                <FormikRadioButton className={styles.radio} formikKey="isLicensedInUnitedStates" label="Yes" value />
                <FormikRadioButton
                  className={styles.radio}
                  formikKey="isLicensedInUnitedStates"
                  label="No"
                  value={false}
                />
              </RadioGroup>
            </div>
          </div>
          {values.isLicensedInUnitedStates && (
            <div aria-live="polite" className={classNames(styles.row, styles.license)}>
              <div className={styles.grid}>
                <FormikDropdown
                  formikKey="stateAbbreviation"
                  formikKeyLabel="stateName"
                  idProp="isoStateCode"
                  label="Licensing State"
                  labelProp="stateName"
                  items={states}
                  required
                />
              </div>
            </div>
          )}
        </fieldset>

        {values.isLicensedInUnitedStates && isTryingAgain && (
          <>
            <span aria-live="assertive" className={styles.message}>
              <Info />{' '}
              <strong>
                We're having trouble location your information. Please provide additional details and submit again.
              </strong>
            </span>

            <fieldset
              aria-label="Additional Details"
              className={classNames(styles.fieldset, styles.additional)}
              role="group"
            >
              <div className={classNames(styles.row, styles.license)}>
                <div className={styles.grid}>
                  <FormikDropdown
                    formikKey="medicalSchoolId"
                    formikKeyLabel="medicalSchoolName"
                    idProp="id"
                    label="Medical School"
                    labelProp="name"
                    items={medicalSchools}
                  />
                </div>
              </div>
              <Divider />
              <div className={classNames(styles.row, styles.npi)}>
                <div className={styles.grid}>
                  <FormikInputField formikKey="npi" label="NPI" />
                  <div className={styles.findNpi}>
                    <a href="https://npiregistry.cms.hhs.gov/" target="_blank" rel="noopener noreferrer">
                      Find My NPI
                    </a>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={classNames(styles.row, styles.state)}>
                <div className={styles.grid}>
                  <FormikInputField formikKey="licenseId" label="State Licensing ID" />
                  <FormikDropdown
                    formikKey="stateAbbreviation"
                    formikKeyLabel="stateName"
                    idProp="isoStateCode"
                    items={states}
                    label="Licensing State"
                    labelProp="stateName"
                  />
                </div>
              </div>
              <Divider />
              <div className={classNames(styles.row, styles.board)}>
                <span>
                  Select a certifying board you are affiliated with (if applicable) and enter your associated board ID.
                </span>
                <div className={styles.grid}>
                  <FormikDropdown
                    formikKey="boardId"
                    idProp="id"
                    items={certifyingBoards}
                    label="Select Certifying Board"
                    labelProp="name"
                  />
                  <FormikInputField formikKey="learnerId" label="ID:" />
                </div>
              </div>
              <Divider />
              <div className={classNames(styles.row, styles.alternate)}>
                <div className={styles.grid}>
                  <FormikInputField formikKey="alternateFirstName" label="Alternate First Name" />
                  <FormikInputField formikKey="alternateLastName" label="Alternate Last Name" />
                </div>
              </div>
            </fieldset>
          </>
        )}

        {/* Show this to the user when they have tried a few times. */}
        {matchFailureCount >= MAX_MATCH_FAILURE_COUNT && (
          <p className={styles['contact-message']}>
            We were unable to locate your information. Please check for errors and re-submit or contact support at
            info@accme.org.
          </p>
        )}

        <footer className={classNames(styles.row, styles.footer)}>
          <Button
            disabled={isPending || !isValid}
            isSubmitting={isPending}
            type="submit"
            variant={ButtonVariant.Primary}
          >
            Submit
          </Button>
        </footer>
      </form>
    </FormikProvider>
  );
};
