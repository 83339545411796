import { useMediaQuery } from '@material-ui/core';
import { ReactElement } from 'react';

// Components.
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

// Core.
import { BreakpointsEnum } from 'core';

export const Header = (): ReactElement => {
  const isMdBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);
  return <>{isMdBreakpoint ? <DesktopHeader /> : <MobileHeader />}</>;
};
