import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

// Core
import { CACHE_ACCESS_TOKEN, CACHE_IMPERSONATION_ACCESS_TOKEN } from 'core';

// Hooks.
import { useAuth } from 'hooks';

// Services
import { CacheService } from 'services';

export const useAuthLogout = (): VoidFunction => {
  const { instance } = useMsal();
  const [account] = useAuth();

  const logout = useCallback(() => {
    CacheService.remove(CACHE_ACCESS_TOKEN);
    CacheService.remove(CACHE_IMPERSONATION_ACCESS_TOKEN);
    instance.logoutRedirect({ account }).catch((error) => {
      console.error('Auth Error:', error);
    });
  }, [account, instance]);

  return logout;
};
