/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement } from 'react';

// Components
import { Button, ContextualMenu, IContextualMenuProps } from 'components';

// Styles
import styles from './BulkActions.module.scss';
import { ButtonVariant } from 'core';
import { Box } from '@material-ui/core';
export interface IActionButton {
  action(): any;
  buttonIcon?: ReactElement;
  buttonText: string;
}
export interface IBulkActionsProps {
  actionControlBtns?: IActionButton[];
  count?: number;
  countLabel?: string;
  downloadMenuProps?: IContextualMenuProps;
  exportMenuProps?: IContextualMenuProps;
  hasDownloadMenu?: boolean;
  hasExportOptions?: boolean;
  hasSelectAll?: boolean;
  isSingleOrNoResult?: boolean;
  onOpenModal?(): void;
  perPageMenuProps?: IContextualMenuProps;
  selectAllAction?(): void;
  selectAllLabel?: string;
  selectedAll?: boolean;
  shouldUpdateLearners?: boolean;
  sortAscending?: boolean;
  sortAscendingAction?: () => void;
  sortMenuProps?: IContextualMenuProps;
}

export const BulkActions = (props: IBulkActionsProps): ReactElement => {
  const {
    actionControlBtns,
    count,
    countLabel,
    isSingleOrNoResult,
    perPageMenuProps,
    selectAllAction,
    selectAllLabel = 'Select All',
    selectedAll,
    sortAscending,
    sortAscendingAction,
    sortMenuProps,
  } = props;
  const ascendingClass = classNames(
    {
      [styles.ascending]: sortAscending,
      [styles.descending]: !sortAscending,
    },
    'icon',
  );

  return (
    <div className={styles['summary-actions']}>
      <div className={styles['summary-actions-options']}>
        <div className={styles['summary-actions-info']}>
          <div className={styles.count}>
            {count?.toLocaleString()} {countLabel}
          </div>
          {!isSingleOrNoResult && (
            <div className={styles['show-perpage']}>
              <ContextualMenu {...perPageMenuProps} />
            </div>
          )}
        </div>
        <div className={styles['sort-controls']}>
          {!isSingleOrNoResult && (
            <>
              <ContextualMenu {...sortMenuProps} />
              <Button
                className={ascendingClass}
                disableRipple
                title="sort"
                onClick={sortAscendingAction}
                variant={ButtonVariant.Icon}
              >
                <KeyboardBackspaceRounded />
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles['summary-actions-controls']}>
        {selectAllAction && !isSingleOrNoResult && (
          <div className={styles['select-control']}>
            <label className={styles['summary-actions--checkbox']}>
              <input type="checkbox" checked={selectedAll} onChange={selectAllAction} />
              <span className={styles.checkmark} />
            </label>
            <div className={styles['label-text-container']}>
              <div className={styles['label-text']}>{selectAllLabel}</div>
            </div>
          </div>
        )}
        {!!actionControlBtns?.length && !isSingleOrNoResult && (
          <Box>
            {actionControlBtns.map(
              (button: IActionButton, index: number): ReactElement => {
                const { action, buttonText, buttonIcon } = button;
                return (
                  <Button key={index} startIcon={buttonIcon} onClick={action}>
                    {buttonText}
                  </Button>
                );
              },
            )}
          </Box>
        )}
      </div>
    </div>
  );
};
