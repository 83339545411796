import { Share, VisibilityRounded } from '@material-ui/icons';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import moment from 'moment';
import { ReactElement, useMemo, useState } from 'react';

// Components.
import { Button, Link } from 'components';
import { LearnerResultShare } from './LearnerResultShare';

// Core + Store
import { ButtonVariant, IDictionary, ILearnerActivity, ILearnerCredit, ILearnerJointProvider } from 'core';

// Hooks.
import { useClickEvent } from 'hooks';

// Styles
import styles from './LearnerResultCard.module.scss';

interface ILearnerResultCard {
  learnerActivity: ILearnerActivity;
}

const formatDate = (date) => moment(date).format('M/D/YYYY');

export const LearnerResultCard = ({ learnerActivity }: ILearnerResultCard): ReactElement => {
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const {
    city,
    cmeFinderType,
    creditTypes,
    endDate,
    jointProviders,
    moCCreditTypes,
    providerName,
    startDate,
    state,
    title,
  } = learnerActivity;

  const activityDates = useMemo(
    () => (startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : null),
    [endDate, startDate],
  );
  const fireEvent = useClickEvent();
  const moCCreditTypesByBoardAbbreviation: IDictionary<ILearnerCredit[]> = useMemo(
    () => groupBy(moCCreditTypes, ({ boardAbbreviation }) => boardAbbreviation),
    [moCCreditTypes],
  );

  return (
    <div className={styles['learner-results-card']}>
      <div className={styles['learner-results-card-details']}>
        <div className={styles['learner-results-card-title']}>
          <Link to={`/activity/details/${learnerActivity.id}`}>{title}</Link>
        </div>
        <div className={styles['learner-results-card-info-container']}>
          <div className={styles.info}>
            <div className={styles['org-info']}>
              {providerName && <span>Provider: {providerName}</span>}
              {!!jointProviders.length && (
                <span>
                  Joint Provider:{' '}
                  {jointProviders.map((provider: ILearnerJointProvider): string => provider.name).join(', ')}
                </span>
              )}
            </div>
            <div className={styles['activity-info']}>
              <span className={styles['meta-type']}>
                <strong>{cmeFinderType}</strong>
              </span>
              <span className={styles['meta-activityDateRange']}>{activityDates}</span>
              {city && state && (
                <span>
                  {city}, {state.toUpperCase()}
                </span>
              )}
            </div>
            <div className={styles['credits-info']}>
              {creditTypes?.map((credit, index) => (
                <span key={index}>
                  {credit.name}: <strong>{credit.credits}</strong>
                </span>
              ))}
              {keys(moCCreditTypesByBoardAbbreviation).map((boardAbbreviation, boardIndex) => {
                const mocCredits = moCCreditTypesByBoardAbbreviation[boardAbbreviation];
                const { boardName, credits, units } = mocCredits[0] || {};
                return (
                  <span className={styles['moc-credit']} key={boardIndex}>
                    <span>
                      <abbr title={boardName}>{boardAbbreviation}</abbr> {units}:<strong>{credits}</strong>;{' '}
                      {boardAbbreviation} Credit Type(s):
                    </span>
                    <ul>
                      {mocCredits?.map((creditType, creditTypeIndex) => (
                        <li key={creditTypeIndex}>
                          <strong>
                            <abbr title={creditType.name}>{creditType.abbreviation}</abbr>
                          </strong>
                        </li>
                      ))}
                    </ul>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['learner-results-card-utility']}>
        <div className={styles['learner-results-card-icons']} />
        <div className={styles['learner-results-card-cta']}>
          {isSharing && <LearnerResultShare activity={learnerActivity} handleClose={() => setIsSharing(false)} />}
          <Button
            onClick={() => {
              fireEvent({ EventAction: 'Share' });
              setIsSharing(!isSharing);
            }}
            variant={ButtonVariant.Label}
          >
            <Share />
            Share
          </Button>
          <Button
            component={Link}
            onClick={() => fireEvent({ EventAction: 'View', EventLabel: `/activity/details/${learnerActivity.key}` })}
            to={`/activity/details/${learnerActivity.id}`}
            variant={ButtonVariant.Label}
          >
            <VisibilityRounded />
            View
          </Button>
        </div>
      </div>
    </div>
  );
};
