import { ICreateUpdateAccountResponse, IOnboardingMatchLearner, IOnboardingRequest } from './../core/models';
import {
  IOnboardingResponse,
  IOnboardingSendCodeRequest,
  IOnboardingValidateCodeRequest,
  ServerRoute,
  post,
} from 'core';

export class OnboardingService {
  public static async sendCode(data: IOnboardingSendCodeRequest): Promise<IOnboardingResponse> {
    const route: ServerRoute = ServerRoute.forAction('onboarding', 'sendcode');
    return await post<IOnboardingResponse>(route, data);
  }

  public static async validateCode(data: IOnboardingValidateCodeRequest): Promise<IOnboardingResponse> {
    const route: ServerRoute = ServerRoute.forAction('onboarding', 'validatecode');
    return await post<IOnboardingResponse>(route, data);
  }

  public static async match(data: IOnboardingMatchLearner): Promise<IOnboardingResponse> {
    const route: ServerRoute = ServerRoute.forAction('onboarding', 'match');
    return await post<IOnboardingResponse>(route, data);
  }

  public static async createAccount(data: IOnboardingRequest): Promise<IOnboardingResponse> {
    const route: ServerRoute = ServerRoute.forAction('onboarding', 'createaccount');
    return await post<IOnboardingResponse>(route, data);
  }

  public static async updateProfile(data: IOnboardingMatchLearner): Promise<ICreateUpdateAccountResponse> {
    const route: ServerRoute = ServerRoute.forAction('onboarding', 'updateprofile');
    return await post<ICreateUpdateAccountResponse>(route, data);
  }
}
