// Libs
import { ReactChild, ReactElement } from 'react';

// Styles
import styles from './Card.module.scss';

interface IProps {
  children: ReactChild | ReactChild[];
}
export const Card = ({ children }: IProps): ReactElement => <div className={styles.card}>{children}</div>;
