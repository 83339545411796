import { Check, FileCopyOutlined } from '@material-ui/icons';
import { ReactElement, useMemo, useRef } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import useClipboard from 'react-use-clipboard';

// Core.
import { ILearnerActivity } from 'core';

// Hooks.
import { useOnClickOutside } from 'hooks';

// Styles.
import styles from './LearnerResultShare.module.scss';

interface ILearnerResultShare {
  activity: ILearnerActivity;
  handleClose: VoidFunction;
}

export const LearnerResultShare = ({ activity, handleClose }: ILearnerResultShare): ReactElement => {
  const activityUrl = useMemo(() => `${window.location.origin}/activity/details/${activity.id}`, [activity]);
  const [isCopied, copyToClipboard] = useClipboard(activityUrl);
  const ref = useRef();
  useOnClickOutside(ref, handleClose);

  return (
    <div className={styles.share} ref={ref}>
      <ul className={styles.social}>
        <li>
          <FacebookShareButton url={activityUrl}>
            <FacebookIcon bgStyle={{ fill: '#007bbd' }} round size={28} />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={activityUrl}>
            <TwitterIcon bgStyle={{ fill: '#007bbd' }} round size={28} />
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton url={activityUrl}>
            <LinkedinIcon bgStyle={{ fill: '#007bbd' }} round size={28} />
          </LinkedinShareButton>
        </li>
      </ul>
      {isCopied ? (
        <span className={styles.copy}>
          <Check className={styles.check} />
          Link Copied
        </span>
      ) : (
        <button className={styles.copy} onClick={copyToClipboard} type="button">
          <FileCopyOutlined /> Copy Link
        </button>
      )}
    </div>
  );
};
