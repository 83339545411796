import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

// Core.
import { Configuration } from 'core';

export const useAuthLogin = (): VoidFunction => {
  const { instance } = useMsal();

  const login = useCallback(() => {
    instance.loginRedirect({ scopes: ['openid', Configuration.azureMsalScope] }).catch((error) => {
      console.error('Auth Error:', error);
    });
  }, [instance]);

  return login;
};
