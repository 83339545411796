import keys from 'lodash/keys';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { LoadingCards } from 'components/LoadingCards';

// Styles
import styles from './Transcript.module.scss';

// Store + Core
import { getUserProfileCME } from 'store/profile/actions';
import {
  userProfileCMELoadingSelector,
  userProfileCMESelector,
  userProfileHasAnnualCreditsSelector,
} from 'store/profile/selectors';

// Types
import { IProfileCME } from 'core';

export const Transcript = (): ReactElement => {
  const dispatch = useDispatch();
  const cme: IProfileCME = useSelector(userProfileCMESelector);
  const isLoadingCME: boolean = useSelector(userProfileCMELoadingSelector);
  const hasCMETranscripts: boolean = useSelector(userProfileHasAnnualCreditsSelector);

  useEffect(() => {
    dispatch(getUserProfileCME());
  }, [dispatch]);

  // Show nothing when there are no transcripts.
  if (!hasCMETranscripts) return null;

  return (
    <div className={styles.transcript}>
      <h1 className={styles.heading}>CME Transcript</h1>
      {isLoadingCME ? (
        <LoadingCards count={3} />
      ) : (
        <div className={styles.grid}>
          <ul className={styles.list}>
            {keys(cme?.annualCredits).map(
              (key): ReactElement => (
                <li className={styles.item} key={key}>
                  <span className={styles.number}>{cme.annualCredits[key].toFixed(2)}</span>
                  <span className={styles.label}>
                    {key} points in {new Date().getFullYear()}
                  </span>
                </li>
              ),
            )}
          </ul>
          <ul className={styles.list}>
            {keys(cme?.totalCredits).map(
              (key): ReactElement => (
                <li className={styles.item} key={key}>
                  <span className={styles.number}>{cme.totalCredits[key].toFixed(2)}</span>
                  <span className={styles.label}>{key} points total</span>
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
