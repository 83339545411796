import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

// Types
import { IActivitySearchRequest } from 'core';

export enum GtmEvents {
  PageInteraction = 'pageInteraction',
  PageLoad = 'pageLoad',
}

export interface IGtmEvent {
  ActivityTitle?: string;
  ActivityType?: string;
  event: GtmEvents;
  Event?: string;
  EventAction?: number | string;
  EventCategory?: string;
  EventLabel?: string;
  LoginStatus?: boolean | string;
  Page?: string;
  PageName?: string;
  PageType?: string;
  Provider?: string;
  RegistrationStep?: number;
  SearchPayload?: IActivitySearchRequest;
  SearchResultsNum?: number;
}

export type EventArgs = Omit<IGtmEvent, 'event'>;
export type EventFunction = (dataLayer: IGtmEvent) => void;

export const useEvent = (): EventFunction => {
  return useCallback((dataLayer: IGtmEvent) => {
    TagManager.dataLayer({ dataLayer });
  }, []);
};
