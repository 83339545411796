import { Reducer } from 'redux';
import { GET_STATES_AND_PROVINCES_SUCCESS, StatesAndProvincesActionTypes, StatesAndProvincesState } from './types';

const initialState: StatesAndProvincesState = {
  statesAndProvinces: [],
};

export const statesAndProvincesReducer: Reducer<StatesAndProvincesState> = (
  state = initialState,
  action: StatesAndProvincesActionTypes,
): StatesAndProvincesState => {
  switch (action.type) {
    case GET_STATES_AND_PROVINCES_SUCCESS:
      return { ...state, statesAndProvinces: action.payload };
    default:
      return { ...state };
  }
};
