import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterListRounded, Share, VerticalAlignBottom } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useMediaQuery } from '@material-ui/core';

// Components
import {
  BulkActions,
  Button,
  LoadingCards,
  Modal,
  NoResultsCard,
  ShareTranscriptForm,
  TranscriptCard,
} from 'components';

// Core + Store
import {
  selectedCompletionIdsSelector,
  userProfileLearnerSearchTotalCountSelector,
  userProfileLearnerSelectedLearnerActivitiesSelector,
  userProfileSearchLearnerCurrentPageSelector,
  userProfileSearchLoadingSelector,
  userProfileSearchRequestSelector,
  userProfileSearchResponseSelector,
} from 'store/profile/selectors';
import {
  getUserProfileSearch,
  onDownloadTranscript,
  onUpdateProfileSearchPaginationState,
  updateProfileSearchPropsAction,
} from 'store/profile/actions';
import {
  BreakpointsEnum,
  ButtonVariant,
  ILearnerResult,
  ILearnerSearch,
  ILearnerSearchRequest,
  ILearnerSearchResult,
  SORT_BY_ACTIVITY_TITLE,
  SORT_BY_COMPLETION_DATE,
  SORT_BY_PROVIDER_NAME,
  SORT_BY_RECENTLY_ADDED,
} from 'core';
import { openRail } from 'store/rail/actions';
import { perPageMenuItems } from './perPageMenuItems';
import { sortLearnerMenu } from './sortMenuItems';

export const ViewTranscripts = (): ReactElement => {
  const [isTranscriptsModalOpen, setIsTranscriptsModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isMdBreakpoint = useMediaQuery(`(min-width:${BreakpointsEnum.md})`);

  // Selectors
  const completionIds: string[] = useSelector(selectedCompletionIdsSelector);
  const currentPage: number = useSelector(userProfileSearchLearnerCurrentPageSelector);
  const isLoading: boolean = useSelector(userProfileSearchLoadingSelector);
  const learnerActivities: ILearnerResult[] = useSelector(userProfileSearchResponseSelector)?.results?.map(
    ({ document }: ILearnerSearch): ILearnerResult => document,
  );
  const learnerSearchTotalCount: number = useSelector(userProfileLearnerSearchTotalCountSelector);
  const searchRequest: ILearnerSearchRequest = useSelector(userProfileSearchRequestSelector);
  const searchResponse: ILearnerSearchResult = useSelector(userProfileSearchResponseSelector);
  const selectedLearnerActivities: ILearnerResult[] = useSelector(userProfileLearnerSelectedLearnerActivitiesSelector);

  // Sorting Functions
  const perPage = (top: number) => dispatch(getUserProfileSearch({ top }));
  const sortByTimestamp = () => dispatch(getUserProfileSearch({ sortBy: SORT_BY_RECENTLY_ADDED }));
  const sortByCompletionDate = () => dispatch(getUserProfileSearch({ sortBy: SORT_BY_COMPLETION_DATE }));
  const sortByProviderName = () => dispatch(getUserProfileSearch({ sortBy: SORT_BY_PROVIDER_NAME }));
  const sortByTitle = () => dispatch(getUserProfileSearch({ sortBy: SORT_BY_ACTIVITY_TITLE }));
  const toggleSortAscending = useCallback(
    () => dispatch(getUserProfileSearch({ sortAscending: !searchRequest.sortAscending })),
    [dispatch, searchRequest],
  );

  // Pagination functions.
  const goToPage = (_, newPage: number) => dispatch(onUpdateProfileSearchPaginationState({ page: newPage }));

  useEffect(() => {
    dispatch(getUserProfileSearch());
  }, [dispatch]);

  const onToggleSelect = ({
    e,
    learnerActivity,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    learnerActivity: ILearnerResult;
  }): void => {
    const isChecked: boolean = e.target.checked;
    if (isChecked) {
      dispatch(
        updateProfileSearchPropsAction({
          page: currentPage,
          selectedActivities: [...selectedLearnerActivities, learnerActivity],
        }),
      );
    } else {
      dispatch(
        updateProfileSearchPropsAction({
          page: currentPage,
          selectedActivities: selectedLearnerActivities.filter(
            (omitted: ILearnerResult): boolean => omitted.key !== learnerActivity.key,
          ),
        }),
      );
    }
  };

  const isAllSelected: boolean = selectedLearnerActivities?.length !== searchResponse?.results?.length;

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      dispatch(updateProfileSearchPropsAction({ page: currentPage, selectedActivities: learnerActivities }));
    } else {
      dispatch(updateProfileSearchPropsAction({ page: currentPage, selectedActivities: [] }));
    }
  };

  // Show this when we are searching.
  const isPending = isLoading && <LoadingCards />;

  // Show this when we don't have any results.
  const noResults: ReactElement = !learnerActivities?.length && <NoResultsCard />;

  // Show this when we have data.
  const hasResults =
    !!learnerActivities?.length &&
    learnerActivities?.map(
      (learnerActivity: ILearnerResult): ReactElement => {
        const isSelected = !!selectedLearnerActivities?.find(
          ({ key }: ILearnerResult): boolean => key === learnerActivity.key,
        );
        return (
          <TranscriptCard
            isSelected={isSelected}
            key={learnerActivity.completionId}
            learnerActivity={learnerActivity}
            onSelect={onToggleSelect}
          />
        );
      },
    );

  // Pagination count selected.
  const paginationCount: number =
    searchResponse && searchRequest?.top ? Math.ceil(learnerSearchTotalCount / searchRequest.top) : 1;

  return (
    <main>
      <h1>Transcript</h1>
      <p>
        ACCME has a direct reporting relationship with some{' '}
        <a href="https://accme.org/cme-counts-for-moc" target="_blank" rel="noopener noreferrer">
          certifying
        </a>{' '}
        and{' '}
        <a href="https://accme.org/state-medical-boards-collaboration" target="_blank" rel="noopener noreferrer">
          licensing
        </a>{' '}
        boards. If you see a check mark next to the credit, this means your completion of the accredited education has
        been reported to that board. For questions about how the credit earned applies toward your licensing, certifying
        or credentialing requirements, contact your board or employer directly.
      </p>

      {/* Render the facets for mobile. */}
      {!isMdBreakpoint && (
        <div className="summary-refiners">
          <Button variant={ButtonVariant.Filter} onClick={() => dispatch(openRail())}>
            <FilterListRounded /> Add Filters
          </Button>
        </div>
      )}
      {/* Bulk actions */}
      <BulkActions
        count={learnerSearchTotalCount}
        countLabel="Records"
        actionControlBtns={[
          {
            action: () => setIsTranscriptsModalOpen(true),
            buttonIcon: <Share />,
            buttonText: 'Send Transcript',
          },
          {
            action: () =>
              dispatch(
                onDownloadTranscript({
                  completionIds,
                }),
              ),
            buttonIcon: <VerticalAlignBottom />,
            buttonText: 'Download Copy of Transcript',
          },
        ]}
        isSingleOrNoResult={learnerSearchTotalCount < 1}
        perPageMenuProps={perPageMenuItems({ perPage, searchRequest })}
        selectAllAction={toggleSelectAll}
        selectAllLabel="Select All to Send or Download"
        selectedAll={!isAllSelected}
        sortAscending={searchRequest.sortAscending}
        sortAscendingAction={toggleSortAscending}
        sortMenuProps={sortLearnerMenu({
          sortBy: searchRequest.sortBy,
          sortByProviderName,
          sortByCompletionDate,
          sortByTimestamp,
          sortByTitle,
        })}
      />
      {/* Render the main content. */}
      {isPending || hasResults || noResults}

      {/* Pagination */}
      {paginationCount > 1 && (
        <Pagination siblingCount={0} count={paginationCount} page={currentPage} onChange={goToPage} />
      )}
      <Modal
        isOpen={isTranscriptsModalOpen}
        onClose={() => setIsTranscriptsModalOpen(false)}
        title="You will be copied on any transcript that you send."
      >
        <ShareTranscriptForm callback={() => setIsTranscriptsModalOpen(false)} />
      </Modal>
    </main>
  );
};
