import { PersonRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Assets.
import { ReactComponent as AccmeLogo } from 'globals/images/accme-logo.svg';

// Components.
import { Button, Link } from 'components';

// Store + Core.
import { ButtonVariant } from 'core';
import { userProfileFullNameSelector } from 'store/profile/selectors';

// Hooks.
import { useAuth, useAuthLogin, useAuthLogout, useClickEvent } from 'hooks';

// Styles.
import styles from './DesktopHeader.module.scss';

export const DesktopHeader = (): ReactElement => {
  const { pathname } = useLocation();
  const matchProfilePage: boolean = pathname.indexOf('profile') > -1;
  const [account, { isAuthenticated }] = useAuth();
  const login = useAuthLogin();
  const logout = useAuthLogout();
  const fireEvent = useClickEvent({ Event: 'navigation', EventCategory: 'Navigation' });

  // Selectors
  const fullName: string = useSelector(userProfileFullNameSelector);

  return (
    <header>
      <div className={styles.masthead}>
        <div className={styles.logo}>
          <Link to="/">
            <AccmeLogo className={styles.logoImage} />
          </Link>
        </div>
        <nav>
          <ul>
            <li>
              <Link
                className={styles.link}
                onClick={() => fireEvent({ EventAction: 'Header', EventLabel: '/activity/search' })}
                to="/activity/search"
              >
                Find Accredited CME
              </Link>
            </li>
            {isAuthenticated ? (
              <>
                {!matchProfilePage && (
                  <li>
                    <Link
                      className={styles.link}
                      onClick={() => fireEvent({ EventAction: 'Header', EventLabel: '/profile/transcripts' })}
                      to="/profile/transcripts"
                    >
                      View My Transcripts
                    </Link>
                  </li>
                )}
                <li>
                  <Button
                    className={styles.link}
                    onClick={() => {
                      fireEvent({ EventAction: 'Header', EventLabel: 'Log Out' });
                      logout();
                    }}
                    title="Log Out"
                    variant={ButtonVariant.Link}
                  >
                    Log Out
                  </Button>
                </li>
                <li>
                  <Link
                    className={classNames(styles.button, styles.profile)}
                    onClick={() => {
                      fireEvent({ EventAction: 'Header', EventLabel: '/profile' });
                    }}
                    title={account?.name}
                    to="/profile"
                  >
                    <PersonRounded />
                    {fullName}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Button
                    className={styles.link}
                    onClick={() => {
                      fireEvent({ EventAction: 'Header', EventLabel: 'Log In' });
                      login();
                    }}
                    title="Log In"
                  >
                    Log In
                  </Button>
                </li>
                <li>
                  <Link
                    className={classNames(styles.button, styles.profile)}
                    onClick={() => fireEvent({ EventAction: 'Header', EventLabel: '/register' })}
                    title="Create an Account"
                    to="/register"
                  >
                    <PersonRounded />
                    Create an Account
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};
