import { ThunkAction } from 'redux-thunk';

// Core.
import {
  IOnboardingSendCodeRequest,
  IOnboardingResponse,
  PARSAction,
  IOnboardingRequest,
  ICreateUpdateAccountResponse,
  IOnboardingMatchLearner,
} from 'core';

// Store.
import { AppState } from 'store';
import { popToast } from 'store/toast/actions';
import { errorToastOptions, successToastOptions } from 'store/toast/constants';

// Utils
import { handleServerError } from 'globals/utils/handleServerError';

// Types.
import {
  IS_LICENSED_IN_UNITED_STATES,
  ONBOARDING_CODE_FAILURE,
  ONBOARDING_CODE_REQUEST,
  ONBOARDING_CODE_SUCCESS,
  ONBOARDING_CODE_VALIDATE_FAILURE,
  ONBOARDING_CODE_VALIDATE_REQUEST,
  ONBOARDING_CODE_VALIDATE_SUCCESS,
  ONBOARDING_LEARNER_MATCHED_FAILURE,
  ONBOARDING_LEARNER_MATCHED_REQUEST,
  ONBOARDING_LEARNER_MATCHED_SUCCESS,
  ONBOARDING_REGISTER_FAILURE,
  ONBOARDING_REGISTER_REQUEST,
  ONBOARDING_REGISTER_SUCCESS,
  ONBOARDING_REQUEST_RESET,
  ONBOARDING_REQUEST_UPDATED,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from './types';
import { OnboardingService } from 'services/OnboardingService';

/**
 * Send Code Actions
 */
export const sendCodeRequestAction = (): PARSAction<void> => ({
  type: ONBOARDING_CODE_REQUEST,
});

export const sendCodeSuccessAction = (): PARSAction<void> => ({
  type: ONBOARDING_CODE_SUCCESS,
});

export const sendCodeFailureAction = (): PARSAction<void> => ({
  type: ONBOARDING_CODE_FAILURE,
});

/**
 * Validate Code Actions
 */
export const validateCodeRequestAction = (): PARSAction<void> => ({
  type: ONBOARDING_CODE_VALIDATE_REQUEST,
});

export const validateCodeSuccessAction = (): PARSAction<void> => ({
  type: ONBOARDING_CODE_VALIDATE_SUCCESS,
});

export const validateCodeFailureAction = (): PARSAction<void> => ({
  type: ONBOARDING_CODE_VALIDATE_FAILURE,
});

/**
 * Match Learner Actions
 */
export const matchLearnerRequestAction = (payload: IOnboardingMatchLearner): PARSAction<IOnboardingMatchLearner> => ({
  payload,
  type: ONBOARDING_LEARNER_MATCHED_REQUEST,
});
export const matchLearnerSuccessAction = (payload: IOnboardingResponse): PARSAction<IOnboardingResponse> => ({
  payload,
  type: ONBOARDING_LEARNER_MATCHED_SUCCESS,
});

export const matchLearnerFailureAction = (): PARSAction<string> => ({
  type: ONBOARDING_LEARNER_MATCHED_FAILURE,
});

/**
 * Register Actions
 */
export const registerRequestAction = (): PARSAction<void> => ({
  type: ONBOARDING_REGISTER_REQUEST,
});
export const registerSuccessAction = (
  payload: ICreateUpdateAccountResponse,
): PARSAction<ICreateUpdateAccountResponse> => ({
  payload,
  type: ONBOARDING_REGISTER_SUCCESS,
});
export const registerFailureAction = (): PARSAction<void> => ({
  type: ONBOARDING_REGISTER_FAILURE,
});

/**
 * Update Profile Actions
 */
export const updateProfileRequestAction = (
  payload: IOnboardingMatchLearner,
): PARSAction<ICreateUpdateAccountResponse> => ({
  payload,
  type: UPDATE_PROFILE_REQUEST,
});

export const updateProfileSuccessAction = (
  payload: ICreateUpdateAccountResponse,
): PARSAction<ICreateUpdateAccountResponse> => ({
  payload,
  type: UPDATE_PROFILE_SUCCESS,
});

export const updateProfileFailureAction = (): PARSAction<void> => ({
  type: UPDATE_PROFILE_FAILURE,
});

/**
 * Non API Actions
 */
export const isLicensedInUnitedStatesAction = (value: boolean): PARSAction<boolean> => ({
  payload: value,
  type: IS_LICENSED_IN_UNITED_STATES,
});
export const onboardingRequestUpdated = (
  values: Partial<IOnboardingRequest>,
): PARSAction<Partial<IOnboardingRequest>> => ({
  payload: values,
  type: ONBOARDING_REQUEST_UPDATED,
});

export const onboardingRequestReset = (): PARSAction<void> => ({
  type: ONBOARDING_REQUEST_RESET,
});

/**
 * Thunk Actions
 */

export const sendCode = (
  values: IOnboardingSendCodeRequest,
): ThunkAction<Promise<IOnboardingResponse>, AppState, null, PARSAction> => async (
  dispatch,
): Promise<IOnboardingResponse> => {
  const { email, password: plainPassword } = values;
  const password = btoa(plainPassword);
  dispatch(onboardingRequestUpdated({ email, password }));

  let response;
  try {
    response = await OnboardingService.sendCode({ email, password });
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'sendCode' });

    dispatch(sendCodeFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }

  // If error is defined, there was some sort of error.
  if (response.error) {
    dispatch(popToast({ ...errorToastOptions, message: <>{response.error}</> }));
    dispatch(sendCodeFailureAction());
    throw new Error(response.error);
  }

  // If response is empty, code was successfully sent.
  dispatch(sendCodeSuccessAction());
  dispatch(popToast({ ...successToastOptions, message: <>We've sent a confirmation code to {email}</> }));
  return response;
};

export const resendCode = (): ThunkAction<Promise<IOnboardingResponse>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
): Promise<IOnboardingResponse> => {
  const {
    onboardingRequest: { email, password },
  } = getState().onboarding;
  let response;

  try {
    response = await OnboardingService.sendCode({ email, password });
  } catch (error) {
    handleServerError({ error, thunkName: 'resendCode' });
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }

  // If error is defined, there was some sort of error.
  if (response.error) {
    dispatch(popToast({ ...errorToastOptions, message: <>{response.error}</> }));
    throw new Error(response.error);
  }

  // If response is empty, code was successfully resent.
  dispatch(popToast({ ...successToastOptions, message: <>We've sent a confirmation code to {email}</> }));
  return response;
};

export const validateCode = (
  code: string,
): ThunkAction<Promise<IOnboardingResponse>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
): Promise<IOnboardingResponse> => {
  const { onboardingRequest } = getState().onboarding;
  let response;

  dispatch(validateCodeRequestAction());

  try {
    response = await OnboardingService.validateCode({ code, email: onboardingRequest.email });
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'validateCode' });
    dispatch(validateCodeFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
    return;
  }

  // If error is defined, there was some sort of error.
  if (response.error) {
    dispatch(validateCodeFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{response.error}</> }));
    throw new Error(response.error);
  }

  // If response is empty, code was successfully validated.
  dispatch(validateCodeSuccessAction());

  // Create an account for the user.
  await dispatch(createAccount());
  return response;
};

export const matchLearner = (
  values,
): ThunkAction<Promise<IOnboardingResponse | boolean>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
): Promise<IOnboardingResponse | boolean> => {
  const { accountId, ulid }: ICreateUpdateAccountResponse = getState().onboarding.onboardingRequest;
  const combinedParams: IOnboardingMatchLearner = { ...values, accountId, ulid };
  let response;

  // Set the combined into Redux state.
  dispatch(matchLearnerRequestAction(combinedParams));

  try {
    response = await OnboardingService.match(combinedParams);
  } catch (error) {
    handleServerError({ error, thunkName: 'matchLearner' });
    dispatch(matchLearnerFailureAction());
  }

  if (response) {
    if (response.isClaimed) {
      dispatch(matchLearnerSuccessAction(response));
      return response;
    }

    // If error is defined, there was some sort of error.
    if (response.error) {
      dispatch(matchLearnerFailureAction());
      dispatch(popToast({ ...errorToastOptions, message: <>{response.error}</> }));
      throw new Error(response.error);
    }

    // If response is empty, there was no single match.
    if (response.isClaimed === false || response === {}) {
      dispatch(matchLearnerFailureAction());
      return false;
    }
  }
};

export const createAccount = (): ThunkAction<Promise<IOnboardingResponse>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
): Promise<IOnboardingResponse> => {
  const { email, password } = getState().onboarding.onboardingRequest;
  let response;

  dispatch(registerRequestAction());

  try {
    response = await OnboardingService.createAccount({ email, password });
    await dispatch(registerSuccessAction(response));
  } catch (error) {
    handleServerError({ error, thunkName: 'createAccount' });
    dispatch(registerFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>An unexpected error occurred. Please try again later.</> }));
  }

  // If error is defined, there was some sort of error.
  if (response.error) {
    dispatch(registerFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{response.error}</> }));
    return;
  }

  // If response is empty, account was successfully created.
  dispatch(registerSuccessAction(response));
  return response;
};

export const updateProfile = (values): ThunkAction<Promise<IOnboardingResponse>, AppState, null, PARSAction> => async (
  dispatch,
  getState,
): Promise<any> => {
  const { accountId, ulid } = getState().onboarding?.onboardingRequest;
  const updatedValues: IOnboardingMatchLearner = { ...values, accountId, ulid };

  dispatch(updateProfileRequestAction(updatedValues));

  try {
    const response = await OnboardingService.updateProfile(updatedValues);
    dispatch(updateProfileSuccessAction(response));
  } catch (error) {
    const { errorMessage } = handleServerError({ error, thunkName: 'updateProfile' });
    dispatch(updateProfileFailureAction());
    dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
  }
};
